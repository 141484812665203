/*
Author         : G-axon
Template Name  : Jumbo React - Admin Template
Version        : 1.0
*/
/*=============================================================
    Table of Contents:
    =============================
    A. Globals
        01. Custom Animation
    B. Base
        01. Base Styles
        02. Typography Styles
    C. Layouts
        01. App Layout Styles
        02. Sidebar Styles
        03. Header Styles
        04. Main Content Styles
        05. Footer Styles
        06. Right Sidebar Styles
    D. UI Components
        01. Alert Styles
        02. Avatar Styles
        03. Badges Styles
        04. Blockquotes Styles
        05. Bottom Navigation Styles
        06. Breadcrumb Styles
        07. Button Styles
        08. Button Group Styles
        09. Callout Styles
        10. Cards Styles
        11. Charts Styles
        12. Chips Styles
        13. Carousel Styles
        14. Color Picker Styles
        15. Drawer Styles
        16. E-commerce Styles
        17. Grids List Styles
        18. Icons Styles
        19. Label Styles
        20. List Styles
        21. Nav Style
        22. Pricing Styles
        23. Progressbar Styles
        24. Popover Styles
        25. React Joyride Styles
        26. Tables Styles
        27. Tabs Styles
        28. Testimonial Styles
        29. Time Lines Style
        30. Joyride Tour Styles
        31. Form Styles
    E. Pages
        01. Login Styles
        02. Error Styles
        03. Editor Styles
        04. Contact Page Styles
        05. Portfolio Styles
        06. Faqs Styles
        07. Dashboard Styles
    F. Slider
    G. Modules
        01. App Module Style
        02. Chat Module Styles
        03. Mails Module Styles
        04. Contact Module Styles
        05. Calendar Module Style
    H. Color Theme
        01. Theme Indigo Styles
        02. Theme Cyan Styles
        03. Theme Amber Styles
        04. Theme Deep Orange Styles
        05. Theme Pink Styles
        06. Theme Blue Styles
        07. Theme Deep Purple Styles
        08. Theme Green Styles
        09. Theme Dark Indigo Styles
        10. Theme Dark Cyan Styles
        11. Theme Dark Amber Styles
        12. Theme Dark Deep Orange Styles
        13. Theme Dark Pink Styles
        14. Theme Dark Blue Styles
        15. Theme Dark Deep Purple Styles
        16. Theme Dark Green Styles
  =============================================================*/
/* Globals
   ========================================================================== */
@import url("https://fonts.googleapis.com/css?family=Libre+Franklin:300,400,500");
@import url("https://fonts.googleapis.com/css?family=Rubik:300,400,500&display=swap");
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans&display=swap");
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url("https://fonts.googleapis.com/css?family=Libre+Franklin:300,400,500");
@import url("https://fonts.googleapis.com/css?family=Rubik:300,400,500&display=swap");
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans&display=swap");
@import url("https://fonts.googleapis.com/css?family=Libre+Franklin:300,400,500");
@import url("https://fonts.googleapis.com/css?family=Rubik:300,400,500&display=swap");
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans&display=swap");
.jr-menu-icon .menu-icon {
  position: relative;
  width: 70%;
  height: 1px;
  min-height: 1px;
  color: inherit;
  background-color: #fff;
  display: block;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease; }
  .jr-menu-icon .menu-icon:after, .jr-menu-icon .menu-icon:before {
    position: absolute;
    display: block;
    color: inherit;
    background-color: inherit;
    left: 0;
    height: 1px;
    min-height: 1px;
    content: "";
    -webkit-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease; }
  .jr-menu-icon .menu-icon:after {
    width: 120%;
    bottom: -6px; }
  .jr-menu-icon .menu-icon:before {
    width: 130%;
    top: -6px; }

.jr-menu-icon:hover .menu-icon {
  width: 100%; }
  .jr-menu-icon:hover .menu-icon:after, .jr-menu-icon:hover .menu-icon:before {
    width: 100%; }

.size-50, .user-avatar, .jr-fab-btn {
  height: 50px !important;
  width: 50px !important;
  line-height: 50px; }

.size-60, .jr-btn-fab-lg {
  height: 60px !important;
  width: 60px !important;
  line-height: 60px; }

.size-80 {
  height: 80px !important;
  width: 80px !important;
  line-height: 80px; }

.size-100 {
  height: 100px !important;
  width: 100px !important;
  line-height: 100px; }

.size-30, .jr-menu-icon, .jr-btn-fab-xs, .icon {
  height: 30px !important;
  width: 30px !important;
  line-height: 30px; }

.size-40, .jr-btn-fab-sm {
  height: 40px !important;
  width: 40px !important;
  line-height: 40px; }

.size-120, .profile-intro img.avatar-circle {
  height: 120px !important;
  width: 120px !important;
  line-height: 120px; }

.size-150 {
  height: 150px !important;
  width: 150px !important;
  line-height: 150px; }

.size-180 {
  height: 180px !important;
  width: 180px !important;
  line-height: 180px; }

.icon-addon {
  display: inline-block;
  text-align: center;
  background-color: inherit; }

.page-heading, .jr-card {
  padding: 18px 24px;
  -webkit-box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.2);
  margin-bottom: 24px;
  background-color: #fff;
  border-radius: 2px;
  position: relative; }

/* Ripple magic */
.ripple-effect {
  position: relative;
  overflow: hidden; }
  .ripple-effect:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 5px;
    background: rgba(255, 255, 255, 0.5);
    opacity: 0;
    border-radius: 100%;
    transform: scale(1, 1) translate(-50%);
    transform-origin: 50% 50%; }
  .ripple-effect:hover:after {
    animation: ripple 1s ease-out; }

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 0.5; }
  20% {
    transform: scale(60, 60);
    opacity: 0.3; }
  100% {
    opacity: 0;
    transform: scale(100, 100); } }

@-webkit-keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 0.5; }
  20% {
    transform: scale(60, 60);
    opacity: 0.3; }
  100% {
    opacity: 0;
    transform: scale(100, 100); } }

.quick-menu .icon-alert:after {
  display: block;
  -webkit-box-shadow: 0 0 0 rgba(245, 88, 89, 0.4);
  -moz-box-shadow: 0 0 0 rgba(245, 88, 89, 0.4);
  -o-box-shadow: 0 0 0 rgba(245, 88, 89, 0.4);
  box-shadow: 0 0 0 rgba(245, 88, 89, 0.4);
  animation: pulse 2s infinite; }

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(245, 88, 89, 0.4); }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(245, 88, 89, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(245, 88, 89, 0); } }

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(245, 88, 89, 0.4);
    box-shadow: 0 0 0 0 rgba(245, 88, 89, 0.4); }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(245, 88, 89, 0);
    box-shadow: 0 0 0 10px rgba(245, 88, 89, 0); }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(245, 88, 89, 0);
    box-shadow: 0 0 0 0 rgba(245, 88, 89, 0); } }

@-webkit-keyframes online {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.4); }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(40, 167, 69, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(40, 167, 69, 0); } }

@keyframes online {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.4);
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.4); }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(40, 167, 69, 0);
    box-shadow: 0 0 0 10px rgba(40, 167, 69, 0); }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(40, 167, 69, 0);
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0); } }

@-webkit-keyframes away {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.4); }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 193, 7, 0); }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 193, 7, 0); } }

@keyframes away {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.4);
    box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.4); }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(255, 193, 7, 0);
    box-shadow: 0 0 0 10px rgba(255, 193, 7, 0); }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 193, 7, 0);
    box-shadow: 0 0 0 0 rgba(255, 193, 7, 0); } }

/*Different size images set in Equal block height and width*/
a.btn-info, a.btn-danger, a.btn-warning, a.btn-success, a.btn-dark, a.badge-success, a.badge-danger, a.badge-warning, a.badge-info, a.badge-dark {
  color: #fff !important; }
  a.btn-info:hover, a.btn-danger:hover, a.btn-warning:hover, a.btn-success:hover, a.btn-dark:hover, a.badge-success:hover, a.badge-danger:hover, a.badge-warning:hover, a.badge-info:hover, a.badge-dark:hover, a.btn-info:focus, a.btn-danger:focus, a.btn-warning:focus, a.btn-success:focus, a.btn-dark:focus, a.badge-success:focus, a.badge-danger:focus, a.badge-warning:focus, a.badge-info:focus, a.badge-dark:focus {
    color: #fff !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-25 {
  max-width: 25% !important; }

.mw-50 {
  max-width: 50% !important; }

.mw-75 {
  max-width: 75% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-25 {
  max-height: 25% !important; }

.mh-50 {
  max-height: 50% !important; }

.mh-75 {
  max-height: 75% !important; }

.mh-100 {
  max-height: 100% !important; }

.bg-primary {
  background-color: #00b96c !important; }

.text-primary {
  color: #00b96c !important; }
  .text-primary.icon-outline {
    border-color: #00b96c !important; }

.bg-primary.lighten-4 {
  background-color: #e2f5ea !important; }

.text-primary.text-lighten-4 {
  color: #e2f5ea !important; }
  .text-primary.text-lighten-4.icon-outline {
    border-color: #e2f5ea !important; }

.bg-primary.lighten-3 {
  background-color: #b9e6ca !important; }

.text-primary.text-lighten-3 {
  color: #b9e6ca !important; }
  .text-primary.text-lighten-3.icon-outline {
    border-color: #b9e6ca !important; }

.bg-primary.lighten-2 {
  background-color: #8ad5a8 !important; }

.text-primary.text-lighten-2 {
  color: #8ad5a8 !important; }
  .text-primary.text-lighten-2.icon-outline {
    border-color: #8ad5a8 !important; }

.bg-primary.lighten-1 {
  background-color: #51c586 !important; }

.text-primary.text-lighten-1 {
  color: #51c586 !important; }
  .text-primary.text-lighten-1.icon-outline {
    border-color: #51c586 !important; }

.bg-primary.darken-1 {
  background-color: #00ac52 !important; }

.text-primary.text-darken-1 {
  color: #00ac52 !important; }
  .text-primary.text-darken-1.icon-outline {
    border-color: #00ac52 !important; }

.bg-primary.darken-2 {
  background-color: #009d48 !important; }

.text-primary.text-darken-2 {
  color: #009d48 !important; }
  .text-primary.text-darken-2.icon-outline {
    border-color: #009d48 !important; }

.bg-primary.darken-3 {
  background-color: #008b3c !important; }

.text-primary.text-darken-3 {
  color: #008b3c !important; }
  .text-primary.text-darken-3.icon-outline {
    border-color: #008b3c !important; }

.bg-primary.darken-4 {
  background-color: #007a30 !important; }

.text-primary.text-darken-4 {
  color: #007a30 !important; }
  .text-primary.text-darken-4.icon-outline {
    border-color: #007a30 !important; }

.bg-primary.darken-5 {
  background-color: #005b1c !important; }

.text-primary.text-darken-5 {
  color: #005b1c !important; }
  .text-primary.text-darken-5.icon-outline {
    border-color: #005b1c !important; }

.bg-primary.accent-1 {
  background-color: #00aa00 !important; }

.text-primary.text-accent-1 {
  color: #00aa00 !important; }
  .text-primary.text-accent-1.icon-outline {
    border-color: #00aa00 !important; }

.bg-primary.accent-2 {
  background-color: #009700 !important; }

.text-primary.text-accent-2 {
  color: #009700 !important; }
  .text-primary.text-accent-2.icon-outline {
    border-color: #009700 !important; }

.bg-primary.accent-3 {
  background-color: #008600 !important; }

.text-primary.text-accent-3 {
  color: #008600 !important; }
  .text-primary.text-accent-3.icon-outline {
    border-color: #008600 !important; }

.bg-primary.accent-4 {
  background-color: #006800 !important; }

.text-primary.text-accent-4 {
  color: #006800 !important; }
  .text-primary.text-accent-4.icon-outline {
    border-color: #006800 !important; }

.bg-red {
  background-color: #F44336 !important; }

.text-red {
  color: #F44336 !important; }
  .text-red.icon-outline {
    border-color: #F44336 !important; }

.bg-red.lighten-5 {
  background-color: #FFEBEE !important; }

.text-red.text-lighten-5 {
  color: #FFEBEE !important; }
  .text-red.text-lighten-5.icon-outline {
    border-color: #FFEBEE !important; }

.bg-red.lighten-4 {
  background-color: #FFCDD2 !important; }

.text-red.text-lighten-4 {
  color: #FFCDD2 !important; }
  .text-red.text-lighten-4.icon-outline {
    border-color: #FFCDD2 !important; }

.bg-red.lighten-3 {
  background-color: #EF9A9A !important; }

.text-red.text-lighten-3 {
  color: #EF9A9A !important; }
  .text-red.text-lighten-3.icon-outline {
    border-color: #EF9A9A !important; }

.bg-red.lighten-2 {
  background-color: #E57373 !important; }

.text-red.text-lighten-2 {
  color: #E57373 !important; }
  .text-red.text-lighten-2.icon-outline {
    border-color: #E57373 !important; }

.bg-red.lighten-1 {
  background-color: #EF5350 !important; }

.text-red.text-lighten-1 {
  color: #EF5350 !important; }
  .text-red.text-lighten-1.icon-outline {
    border-color: #EF5350 !important; }

.bg-red.darken-1 {
  background-color: #E53935 !important; }

.text-red.text-darken-1 {
  color: #E53935 !important; }
  .text-red.text-darken-1.icon-outline {
    border-color: #E53935 !important; }

.bg-red.darken-2 {
  background-color: #D32F2F !important; }

.text-red.text-darken-2 {
  color: #D32F2F !important; }
  .text-red.text-darken-2.icon-outline {
    border-color: #D32F2F !important; }

.bg-red.darken-3 {
  background-color: #C62828 !important; }

.text-red.text-darken-3 {
  color: #C62828 !important; }
  .text-red.text-darken-3.icon-outline {
    border-color: #C62828 !important; }

.bg-red.darken-4 {
  background-color: #B71C1C !important; }

.text-red.text-darken-4 {
  color: #B71C1C !important; }
  .text-red.text-darken-4.icon-outline {
    border-color: #B71C1C !important; }

.bg-red.accent-1 {
  background-color: #FF8A80 !important; }

.text-red.text-accent-1 {
  color: #FF8A80 !important; }
  .text-red.text-accent-1.icon-outline {
    border-color: #FF8A80 !important; }

.bg-red.accent-2 {
  background-color: #FF5252 !important; }

.text-red.text-accent-2 {
  color: #FF5252 !important; }
  .text-red.text-accent-2.icon-outline {
    border-color: #FF5252 !important; }

.bg-red.accent-3 {
  background-color: #FF1744 !important; }

.text-red.text-accent-3 {
  color: #FF1744 !important; }
  .text-red.text-accent-3.icon-outline {
    border-color: #FF1744 !important; }

.bg-red.accent-4 {
  background-color: #D50000 !important; }

.text-red.text-accent-4 {
  color: #D50000 !important; }
  .text-red.text-accent-4.icon-outline {
    border-color: #D50000 !important; }

.bg-pink {
  background-color: #e91e63 !important; }

.text-pink {
  color: #e91e63 !important; }
  .text-pink.icon-outline {
    border-color: #e91e63 !important; }

.bg-pink.lighten-5 {
  background-color: #fce4ec !important; }

.text-pink.text-lighten-5 {
  color: #fce4ec !important; }
  .text-pink.text-lighten-5.icon-outline {
    border-color: #fce4ec !important; }

.bg-pink.lighten-4 {
  background-color: #f8bbd0 !important; }

.text-pink.text-lighten-4 {
  color: #f8bbd0 !important; }
  .text-pink.text-lighten-4.icon-outline {
    border-color: #f8bbd0 !important; }

.bg-pink.lighten-3 {
  background-color: #f48fb1 !important; }

.text-pink.text-lighten-3 {
  color: #f48fb1 !important; }
  .text-pink.text-lighten-3.icon-outline {
    border-color: #f48fb1 !important; }

.bg-pink.lighten-2 {
  background-color: #f06292 !important; }

.text-pink.text-lighten-2 {
  color: #f06292 !important; }
  .text-pink.text-lighten-2.icon-outline {
    border-color: #f06292 !important; }

.bg-pink.lighten-1 {
  background-color: #ec407a !important; }

.text-pink.text-lighten-1 {
  color: #ec407a !important; }
  .text-pink.text-lighten-1.icon-outline {
    border-color: #ec407a !important; }

.bg-pink.darken-1 {
  background-color: #d81b60 !important; }

.text-pink.text-darken-1 {
  color: #d81b60 !important; }
  .text-pink.text-darken-1.icon-outline {
    border-color: #d81b60 !important; }

.bg-pink.darken-2 {
  background-color: #c2185b !important; }

.text-pink.text-darken-2 {
  color: #c2185b !important; }
  .text-pink.text-darken-2.icon-outline {
    border-color: #c2185b !important; }

.bg-pink.darken-3 {
  background-color: #ad1457 !important; }

.text-pink.text-darken-3 {
  color: #ad1457 !important; }
  .text-pink.text-darken-3.icon-outline {
    border-color: #ad1457 !important; }

.bg-pink.darken-4 {
  background-color: #880e4f !important; }

.text-pink.text-darken-4 {
  color: #880e4f !important; }
  .text-pink.text-darken-4.icon-outline {
    border-color: #880e4f !important; }

.bg-pink.accent-1 {
  background-color: #ff80ab !important; }

.text-pink.text-accent-1 {
  color: #ff80ab !important; }
  .text-pink.text-accent-1.icon-outline {
    border-color: #ff80ab !important; }

.bg-pink.accent-2 {
  background-color: #ff4081 !important; }

.text-pink.text-accent-2 {
  color: #ff4081 !important; }
  .text-pink.text-accent-2.icon-outline {
    border-color: #ff4081 !important; }

.bg-pink.accent-3 {
  background-color: #f50057 !important; }

.text-pink.text-accent-3 {
  color: #f50057 !important; }
  .text-pink.text-accent-3.icon-outline {
    border-color: #f50057 !important; }

.bg-pink.accent-4 {
  background-color: #c51162 !important; }

.text-pink.text-accent-4 {
  color: #c51162 !important; }
  .text-pink.text-accent-4.icon-outline {
    border-color: #c51162 !important; }

.bg-purple {
  background-color: #9C27B0 !important; }

.text-purple {
  color: #9C27B0 !important; }
  .text-purple.icon-outline {
    border-color: #9C27B0 !important; }

.bg-purple.lighten-5 {
  background-color: #f3e5f5 !important; }

.text-purple.text-lighten-5 {
  color: #f3e5f5 !important; }
  .text-purple.text-lighten-5.icon-outline {
    border-color: #f3e5f5 !important; }

.bg-purple.lighten-4 {
  background-color: #e1bee7 !important; }

.text-purple.text-lighten-4 {
  color: #e1bee7 !important; }
  .text-purple.text-lighten-4.icon-outline {
    border-color: #e1bee7 !important; }

.bg-purple.lighten-3 {
  background-color: #ce93d8 !important; }

.text-purple.text-lighten-3 {
  color: #ce93d8 !important; }
  .text-purple.text-lighten-3.icon-outline {
    border-color: #ce93d8 !important; }

.bg-purple.lighten-2 {
  background-color: #ba68c8 !important; }

.text-purple.text-lighten-2 {
  color: #ba68c8 !important; }
  .text-purple.text-lighten-2.icon-outline {
    border-color: #ba68c8 !important; }

.bg-purple.lighten-1 {
  background-color: #ab47bc !important; }

.text-purple.text-lighten-1 {
  color: #ab47bc !important; }
  .text-purple.text-lighten-1.icon-outline {
    border-color: #ab47bc !important; }

.bg-purple.darken-1 {
  background-color: #8e24aa !important; }

.text-purple.text-darken-1 {
  color: #8e24aa !important; }
  .text-purple.text-darken-1.icon-outline {
    border-color: #8e24aa !important; }

.bg-purple.darken-2 {
  background-color: #7b1fa2 !important; }

.text-purple.text-darken-2 {
  color: #7b1fa2 !important; }
  .text-purple.text-darken-2.icon-outline {
    border-color: #7b1fa2 !important; }

.bg-purple.darken-3 {
  background-color: #6a1b9a !important; }

.text-purple.text-darken-3 {
  color: #6a1b9a !important; }
  .text-purple.text-darken-3.icon-outline {
    border-color: #6a1b9a !important; }

.bg-purple.darken-4 {
  background-color: #4a148c !important; }

.text-purple.text-darken-4 {
  color: #4a148c !important; }
  .text-purple.text-darken-4.icon-outline {
    border-color: #4a148c !important; }

.bg-purple.accent-1 {
  background-color: #ea80fc !important; }

.text-purple.text-accent-1 {
  color: #ea80fc !important; }
  .text-purple.text-accent-1.icon-outline {
    border-color: #ea80fc !important; }

.bg-purple.accent-2 {
  background-color: #e040fb !important; }

.text-purple.text-accent-2 {
  color: #e040fb !important; }
  .text-purple.text-accent-2.icon-outline {
    border-color: #e040fb !important; }

.bg-purple.accent-3 {
  background-color: #d500f9 !important; }

.text-purple.text-accent-3 {
  color: #d500f9 !important; }
  .text-purple.text-accent-3.icon-outline {
    border-color: #d500f9 !important; }

.bg-purple.accent-4 {
  background-color: #aa00ff !important; }

.text-purple.text-accent-4 {
  color: #aa00ff !important; }
  .text-purple.text-accent-4.icon-outline {
    border-color: #aa00ff !important; }

.bg-deep-purple {
  background-color: #673ab7 !important; }

.text-deep-purple {
  color: #673ab7 !important; }
  .text-deep-purple.icon-outline {
    border-color: #673ab7 !important; }

.bg-deep-purple.lighten-5 {
  background-color: #ede7f6 !important; }

.text-deep-purple.text-lighten-5 {
  color: #ede7f6 !important; }
  .text-deep-purple.text-lighten-5.icon-outline {
    border-color: #ede7f6 !important; }

.bg-deep-purple.lighten-4 {
  background-color: #d1c4e9 !important; }

.text-deep-purple.text-lighten-4 {
  color: #d1c4e9 !important; }
  .text-deep-purple.text-lighten-4.icon-outline {
    border-color: #d1c4e9 !important; }

.bg-deep-purple.lighten-3 {
  background-color: #b39ddb !important; }

.text-deep-purple.text-lighten-3 {
  color: #b39ddb !important; }
  .text-deep-purple.text-lighten-3.icon-outline {
    border-color: #b39ddb !important; }

.bg-deep-purple.lighten-2 {
  background-color: #9575cd !important; }

.text-deep-purple.text-lighten-2 {
  color: #9575cd !important; }
  .text-deep-purple.text-lighten-2.icon-outline {
    border-color: #9575cd !important; }

.bg-deep-purple.lighten-1 {
  background-color: #7e57c2 !important; }

.text-deep-purple.text-lighten-1 {
  color: #7e57c2 !important; }
  .text-deep-purple.text-lighten-1.icon-outline {
    border-color: #7e57c2 !important; }

.bg-deep-purple.darken-1 {
  background-color: #5e35b1 !important; }

.text-deep-purple.text-darken-1 {
  color: #5e35b1 !important; }
  .text-deep-purple.text-darken-1.icon-outline {
    border-color: #5e35b1 !important; }

.bg-deep-purple.darken-2 {
  background-color: #512da8 !important; }

.text-deep-purple.text-darken-2 {
  color: #512da8 !important; }
  .text-deep-purple.text-darken-2.icon-outline {
    border-color: #512da8 !important; }

.bg-deep-purple.darken-3 {
  background-color: #4527a0 !important; }

.text-deep-purple.text-darken-3 {
  color: #4527a0 !important; }
  .text-deep-purple.text-darken-3.icon-outline {
    border-color: #4527a0 !important; }

.bg-deep-purple.darken-4 {
  background-color: #311b92 !important; }

.text-deep-purple.text-darken-4 {
  color: #311b92 !important; }
  .text-deep-purple.text-darken-4.icon-outline {
    border-color: #311b92 !important; }

.bg-deep-purple.accent-1 {
  background-color: #b388ff !important; }

.text-deep-purple.text-accent-1 {
  color: #b388ff !important; }
  .text-deep-purple.text-accent-1.icon-outline {
    border-color: #b388ff !important; }

.bg-deep-purple.accent-2 {
  background-color: #7c4dff !important; }

.text-deep-purple.text-accent-2 {
  color: #7c4dff !important; }
  .text-deep-purple.text-accent-2.icon-outline {
    border-color: #7c4dff !important; }

.bg-deep-purple.accent-3 {
  background-color: #651fff !important; }

.text-deep-purple.text-accent-3 {
  color: #651fff !important; }
  .text-deep-purple.text-accent-3.icon-outline {
    border-color: #651fff !important; }

.bg-deep-purple.accent-4 {
  background-color: #6200ea !important; }

.text-deep-purple.text-accent-4 {
  color: #6200ea !important; }
  .text-deep-purple.text-accent-4.icon-outline {
    border-color: #6200ea !important; }

.bg-indigo {
  background-color: #3f51b5 !important; }

.text-indigo {
  color: #3f51b5 !important; }
  .text-indigo.icon-outline {
    border-color: #3f51b5 !important; }

.bg-indigo.lighten-5 {
  background-color: #e8eaf6 !important; }

.text-indigo.text-lighten-5 {
  color: #e8eaf6 !important; }
  .text-indigo.text-lighten-5.icon-outline {
    border-color: #e8eaf6 !important; }

.bg-indigo.lighten-4 {
  background-color: #c5cae9 !important; }

.text-indigo.text-lighten-4 {
  color: #c5cae9 !important; }
  .text-indigo.text-lighten-4.icon-outline {
    border-color: #c5cae9 !important; }

.bg-indigo.lighten-3 {
  background-color: #9fa8da !important; }

.text-indigo.text-lighten-3 {
  color: #9fa8da !important; }
  .text-indigo.text-lighten-3.icon-outline {
    border-color: #9fa8da !important; }

.bg-indigo.lighten-2 {
  background-color: #7986cb !important; }

.text-indigo.text-lighten-2 {
  color: #7986cb !important; }
  .text-indigo.text-lighten-2.icon-outline {
    border-color: #7986cb !important; }

.bg-indigo.lighten-1 {
  background-color: #5c6bc0 !important; }

.text-indigo.text-lighten-1 {
  color: #5c6bc0 !important; }
  .text-indigo.text-lighten-1.icon-outline {
    border-color: #5c6bc0 !important; }

.bg-indigo.darken-1 {
  background-color: #3949ab !important; }

.text-indigo.text-darken-1 {
  color: #3949ab !important; }
  .text-indigo.text-darken-1.icon-outline {
    border-color: #3949ab !important; }

.bg-indigo.darken-2 {
  background-color: #303f9f !important; }

.text-indigo.text-darken-2 {
  color: #303f9f !important; }
  .text-indigo.text-darken-2.icon-outline {
    border-color: #303f9f !important; }

.bg-indigo.darken-3 {
  background-color: #283593 !important; }

.text-indigo.text-darken-3 {
  color: #283593 !important; }
  .text-indigo.text-darken-3.icon-outline {
    border-color: #283593 !important; }

.bg-indigo.darken-4 {
  background-color: #1a237e !important; }

.text-indigo.text-darken-4 {
  color: #1a237e !important; }
  .text-indigo.text-darken-4.icon-outline {
    border-color: #1a237e !important; }

.bg-indigo.accent-1 {
  background-color: #8c9eff !important; }

.text-indigo.text-accent-1 {
  color: #8c9eff !important; }
  .text-indigo.text-accent-1.icon-outline {
    border-color: #8c9eff !important; }

.bg-indigo.accent-2 {
  background-color: #536dfe !important; }

.text-indigo.text-accent-2 {
  color: #536dfe !important; }
  .text-indigo.text-accent-2.icon-outline {
    border-color: #536dfe !important; }

.bg-indigo.accent-3 {
  background-color: #3d5afe !important; }

.text-indigo.text-accent-3 {
  color: #3d5afe !important; }
  .text-indigo.text-accent-3.icon-outline {
    border-color: #3d5afe !important; }

.bg-indigo.accent-4 {
  background-color: #304ffe !important; }

.text-indigo.text-accent-4 {
  color: #304ffe !important; }
  .text-indigo.text-accent-4.icon-outline {
    border-color: #304ffe !important; }

.bg-blue {
  background-color: #2196F3 !important; }

.text-blue {
  color: #2196F3 !important; }
  .text-blue.icon-outline {
    border-color: #2196F3 !important; }

.bg-blue.lighten-5 {
  background-color: #E3F2FD !important; }

.text-blue.text-lighten-5 {
  color: #E3F2FD !important; }
  .text-blue.text-lighten-5.icon-outline {
    border-color: #E3F2FD !important; }

.bg-blue.lighten-4 {
  background-color: #BBDEFB !important; }

.text-blue.text-lighten-4 {
  color: #BBDEFB !important; }
  .text-blue.text-lighten-4.icon-outline {
    border-color: #BBDEFB !important; }

.bg-blue.lighten-3 {
  background-color: #90CAF9 !important; }

.text-blue.text-lighten-3 {
  color: #90CAF9 !important; }
  .text-blue.text-lighten-3.icon-outline {
    border-color: #90CAF9 !important; }

.bg-blue.lighten-2 {
  background-color: #64B5F6 !important; }

.text-blue.text-lighten-2 {
  color: #64B5F6 !important; }
  .text-blue.text-lighten-2.icon-outline {
    border-color: #64B5F6 !important; }

.bg-blue.lighten-1 {
  background-color: #42A5F5 !important; }

.text-blue.text-lighten-1 {
  color: #42A5F5 !important; }
  .text-blue.text-lighten-1.icon-outline {
    border-color: #42A5F5 !important; }

.bg-blue.darken-1 {
  background-color: #1E88E5 !important; }

.text-blue.text-darken-1 {
  color: #1E88E5 !important; }
  .text-blue.text-darken-1.icon-outline {
    border-color: #1E88E5 !important; }

.bg-blue.darken-2 {
  background-color: #1976D2 !important; }

.text-blue.text-darken-2 {
  color: #1976D2 !important; }
  .text-blue.text-darken-2.icon-outline {
    border-color: #1976D2 !important; }

.bg-blue.darken-3 {
  background-color: #1565C0 !important; }

.text-blue.text-darken-3 {
  color: #1565C0 !important; }
  .text-blue.text-darken-3.icon-outline {
    border-color: #1565C0 !important; }

.bg-blue.darken-4 {
  background-color: #0D47A1 !important; }

.text-blue.text-darken-4 {
  color: #0D47A1 !important; }
  .text-blue.text-darken-4.icon-outline {
    border-color: #0D47A1 !important; }

.bg-blue.accent-1 {
  background-color: #82B1FF !important; }

.text-blue.text-accent-1 {
  color: #82B1FF !important; }
  .text-blue.text-accent-1.icon-outline {
    border-color: #82B1FF !important; }

.bg-blue.accent-2 {
  background-color: #448AFF !important; }

.text-blue.text-accent-2 {
  color: #448AFF !important; }
  .text-blue.text-accent-2.icon-outline {
    border-color: #448AFF !important; }

.bg-blue.accent-3 {
  background-color: #2979FF !important; }

.text-blue.text-accent-3 {
  color: #2979FF !important; }
  .text-blue.text-accent-3.icon-outline {
    border-color: #2979FF !important; }

.bg-blue.accent-4 {
  background-color: #2962FF !important; }

.text-blue.text-accent-4 {
  color: #2962FF !important; }
  .text-blue.text-accent-4.icon-outline {
    border-color: #2962FF !important; }

.bg-light-blue {
  background-color: #03a9f4 !important; }

.text-light-blue {
  color: #03a9f4 !important; }
  .text-light-blue.icon-outline {
    border-color: #03a9f4 !important; }

.bg-light-blue.lighten-5 {
  background-color: #e1f5fe !important; }

.text-light-blue.text-lighten-5 {
  color: #e1f5fe !important; }
  .text-light-blue.text-lighten-5.icon-outline {
    border-color: #e1f5fe !important; }

.bg-light-blue.lighten-4 {
  background-color: #b3e5fc !important; }

.text-light-blue.text-lighten-4 {
  color: #b3e5fc !important; }
  .text-light-blue.text-lighten-4.icon-outline {
    border-color: #b3e5fc !important; }

.bg-light-blue.lighten-3 {
  background-color: #81d4fa !important; }

.text-light-blue.text-lighten-3 {
  color: #81d4fa !important; }
  .text-light-blue.text-lighten-3.icon-outline {
    border-color: #81d4fa !important; }

.bg-light-blue.lighten-2 {
  background-color: #4fc3f7 !important; }

.text-light-blue.text-lighten-2 {
  color: #4fc3f7 !important; }
  .text-light-blue.text-lighten-2.icon-outline {
    border-color: #4fc3f7 !important; }

.bg-light-blue.lighten-1 {
  background-color: #29b6f6 !important; }

.text-light-blue.text-lighten-1 {
  color: #29b6f6 !important; }
  .text-light-blue.text-lighten-1.icon-outline {
    border-color: #29b6f6 !important; }

.bg-light-blue.darken-1 {
  background-color: #039be5 !important; }

.text-light-blue.text-darken-1 {
  color: #039be5 !important; }
  .text-light-blue.text-darken-1.icon-outline {
    border-color: #039be5 !important; }

.bg-light-blue.darken-2 {
  background-color: #0288d1 !important; }

.text-light-blue.text-darken-2 {
  color: #0288d1 !important; }
  .text-light-blue.text-darken-2.icon-outline {
    border-color: #0288d1 !important; }

.bg-light-blue.darken-3 {
  background-color: #0277bd !important; }

.text-light-blue.text-darken-3 {
  color: #0277bd !important; }
  .text-light-blue.text-darken-3.icon-outline {
    border-color: #0277bd !important; }

.bg-light-blue.darken-4 {
  background-color: #01579b !important; }

.text-light-blue.text-darken-4 {
  color: #01579b !important; }
  .text-light-blue.text-darken-4.icon-outline {
    border-color: #01579b !important; }

.bg-light-blue.accent-1 {
  background-color: #80d8ff !important; }

.text-light-blue.text-accent-1 {
  color: #80d8ff !important; }
  .text-light-blue.text-accent-1.icon-outline {
    border-color: #80d8ff !important; }

.bg-light-blue.accent-2 {
  background-color: #40c4ff !important; }

.text-light-blue.text-accent-2 {
  color: #40c4ff !important; }
  .text-light-blue.text-accent-2.icon-outline {
    border-color: #40c4ff !important; }

.bg-light-blue.accent-3 {
  background-color: #00b0ff !important; }

.text-light-blue.text-accent-3 {
  color: #00b0ff !important; }
  .text-light-blue.text-accent-3.icon-outline {
    border-color: #00b0ff !important; }

.bg-light-blue.accent-4 {
  background-color: #0091ea !important; }

.text-light-blue.text-accent-4 {
  color: #0091ea !important; }
  .text-light-blue.text-accent-4.icon-outline {
    border-color: #0091ea !important; }

.bg-cyan {
  background-color: #00bcd4 !important; }

.text-cyan {
  color: #00bcd4 !important; }
  .text-cyan.icon-outline {
    border-color: #00bcd4 !important; }

.bg-cyan.lighten-5 {
  background-color: #e0f7fa !important; }

.text-cyan.text-lighten-5 {
  color: #e0f7fa !important; }
  .text-cyan.text-lighten-5.icon-outline {
    border-color: #e0f7fa !important; }

.bg-cyan.lighten-4 {
  background-color: #b2ebf2 !important; }

.text-cyan.text-lighten-4 {
  color: #b2ebf2 !important; }
  .text-cyan.text-lighten-4.icon-outline {
    border-color: #b2ebf2 !important; }

.bg-cyan.lighten-3 {
  background-color: #80deea !important; }

.text-cyan.text-lighten-3 {
  color: #80deea !important; }
  .text-cyan.text-lighten-3.icon-outline {
    border-color: #80deea !important; }

.bg-cyan.lighten-2 {
  background-color: #4dd0e1 !important; }

.text-cyan.text-lighten-2 {
  color: #4dd0e1 !important; }
  .text-cyan.text-lighten-2.icon-outline {
    border-color: #4dd0e1 !important; }

.bg-cyan.lighten-1 {
  background-color: #26c6da !important; }

.text-cyan.text-lighten-1 {
  color: #26c6da !important; }
  .text-cyan.text-lighten-1.icon-outline {
    border-color: #26c6da !important; }

.bg-cyan.darken-1 {
  background-color: #00acc1 !important; }

.text-cyan.text-darken-1 {
  color: #00acc1 !important; }
  .text-cyan.text-darken-1.icon-outline {
    border-color: #00acc1 !important; }

.bg-cyan.darken-2 {
  background-color: #0097a7 !important; }

.text-cyan.text-darken-2 {
  color: #0097a7 !important; }
  .text-cyan.text-darken-2.icon-outline {
    border-color: #0097a7 !important; }

.bg-cyan.darken-3 {
  background-color: #00838f !important; }

.text-cyan.text-darken-3 {
  color: #00838f !important; }
  .text-cyan.text-darken-3.icon-outline {
    border-color: #00838f !important; }

.bg-cyan.darken-4 {
  background-color: #006064 !important; }

.text-cyan.text-darken-4 {
  color: #006064 !important; }
  .text-cyan.text-darken-4.icon-outline {
    border-color: #006064 !important; }

.bg-cyan.accent-1 {
  background-color: #84ffff !important; }

.text-cyan.text-accent-1 {
  color: #84ffff !important; }
  .text-cyan.text-accent-1.icon-outline {
    border-color: #84ffff !important; }

.bg-cyan.accent-2 {
  background-color: #18ffff !important; }

.text-cyan.text-accent-2 {
  color: #18ffff !important; }
  .text-cyan.text-accent-2.icon-outline {
    border-color: #18ffff !important; }

.bg-cyan.accent-3 {
  background-color: #00e5ff !important; }

.text-cyan.text-accent-3 {
  color: #00e5ff !important; }
  .text-cyan.text-accent-3.icon-outline {
    border-color: #00e5ff !important; }

.bg-cyan.accent-4 {
  background-color: #00b8d4 !important; }

.text-cyan.text-accent-4 {
  color: #00b8d4 !important; }
  .text-cyan.text-accent-4.icon-outline {
    border-color: #00b8d4 !important; }

.bg-teal {
  background-color: #009688 !important; }

.text-teal {
  color: #009688 !important; }
  .text-teal.icon-outline {
    border-color: #009688 !important; }

.bg-teal.lighten-5 {
  background-color: #e0f2f1 !important; }

.text-teal.text-lighten-5 {
  color: #e0f2f1 !important; }
  .text-teal.text-lighten-5.icon-outline {
    border-color: #e0f2f1 !important; }

.bg-teal.lighten-4 {
  background-color: #b2dfdb !important; }

.text-teal.text-lighten-4 {
  color: #b2dfdb !important; }
  .text-teal.text-lighten-4.icon-outline {
    border-color: #b2dfdb !important; }

.bg-teal.lighten-3 {
  background-color: #80cbc4 !important; }

.text-teal.text-lighten-3 {
  color: #80cbc4 !important; }
  .text-teal.text-lighten-3.icon-outline {
    border-color: #80cbc4 !important; }

.bg-teal.lighten-2 {
  background-color: #4db6ac !important; }

.text-teal.text-lighten-2 {
  color: #4db6ac !important; }
  .text-teal.text-lighten-2.icon-outline {
    border-color: #4db6ac !important; }

.bg-teal.lighten-1 {
  background-color: #26a69a !important; }

.text-teal.text-lighten-1 {
  color: #26a69a !important; }
  .text-teal.text-lighten-1.icon-outline {
    border-color: #26a69a !important; }

.bg-teal.darken-1 {
  background-color: #00897b !important; }

.text-teal.text-darken-1 {
  color: #00897b !important; }
  .text-teal.text-darken-1.icon-outline {
    border-color: #00897b !important; }

.bg-teal.darken-2 {
  background-color: #00796b !important; }

.text-teal.text-darken-2 {
  color: #00796b !important; }
  .text-teal.text-darken-2.icon-outline {
    border-color: #00796b !important; }

.bg-teal.darken-3 {
  background-color: #00695c !important; }

.text-teal.text-darken-3 {
  color: #00695c !important; }
  .text-teal.text-darken-3.icon-outline {
    border-color: #00695c !important; }

.bg-teal.darken-4 {
  background-color: #004d40 !important; }

.text-teal.text-darken-4 {
  color: #004d40 !important; }
  .text-teal.text-darken-4.icon-outline {
    border-color: #004d40 !important; }

.bg-teal.accent-1 {
  background-color: #a7ffeb !important; }

.text-teal.text-accent-1 {
  color: #a7ffeb !important; }
  .text-teal.text-accent-1.icon-outline {
    border-color: #a7ffeb !important; }

.bg-teal.accent-2 {
  background-color: #64ffda !important; }

.text-teal.text-accent-2 {
  color: #64ffda !important; }
  .text-teal.text-accent-2.icon-outline {
    border-color: #64ffda !important; }

.bg-teal.accent-3 {
  background-color: #1de9b6 !important; }

.text-teal.text-accent-3 {
  color: #1de9b6 !important; }
  .text-teal.text-accent-3.icon-outline {
    border-color: #1de9b6 !important; }

.bg-teal.accent-4 {
  background-color: #00bfa5 !important; }

.text-teal.text-accent-4 {
  color: #00bfa5 !important; }
  .text-teal.text-accent-4.icon-outline {
    border-color: #00bfa5 !important; }

.bg-green {
  background-color: #E8F5E9 !important; }

.text-green {
  color: #E8F5E9 !important; }
  .text-green.icon-outline {
    border-color: #E8F5E9 !important; }

.bg-green.lighten-5 {
  background-color: #E8F5E9 !important; }

.text-green.text-lighten-5 {
  color: #E8F5E9 !important; }
  .text-green.text-lighten-5.icon-outline {
    border-color: #E8F5E9 !important; }

.bg-green.lighten-4 {
  background-color: #C8E6C9 !important; }

.text-green.text-lighten-4 {
  color: #C8E6C9 !important; }
  .text-green.text-lighten-4.icon-outline {
    border-color: #C8E6C9 !important; }

.bg-green.lighten-3 {
  background-color: #A5D6A7 !important; }

.text-green.text-lighten-3 {
  color: #A5D6A7 !important; }
  .text-green.text-lighten-3.icon-outline {
    border-color: #A5D6A7 !important; }

.bg-green.lighten-2 {
  background-color: #81C784 !important; }

.text-green.text-lighten-2 {
  color: #81C784 !important; }
  .text-green.text-lighten-2.icon-outline {
    border-color: #81C784 !important; }

.bg-green.lighten-1 {
  background-color: #66BB6A !important; }

.text-green.text-lighten-1 {
  color: #66BB6A !important; }
  .text-green.text-lighten-1.icon-outline {
    border-color: #66BB6A !important; }

.bg-green.darken-1 {
  background-color: #43A047 !important; }

.text-green.text-darken-1 {
  color: #43A047 !important; }
  .text-green.text-darken-1.icon-outline {
    border-color: #43A047 !important; }

.bg-green.darken-2 {
  background-color: #388E3C !important; }

.text-green.text-darken-2 {
  color: #388E3C !important; }
  .text-green.text-darken-2.icon-outline {
    border-color: #388E3C !important; }

.bg-green.darken-3 {
  background-color: #2E7D32 !important; }

.text-green.text-darken-3 {
  color: #2E7D32 !important; }
  .text-green.text-darken-3.icon-outline {
    border-color: #2E7D32 !important; }

.bg-green.darken-4 {
  background-color: #1B5E20 !important; }

.text-green.text-darken-4 {
  color: #1B5E20 !important; }
  .text-green.text-darken-4.icon-outline {
    border-color: #1B5E20 !important; }

.bg-green.accent-1 {
  background-color: #B9F6CA !important; }

.text-green.text-accent-1 {
  color: #B9F6CA !important; }
  .text-green.text-accent-1.icon-outline {
    border-color: #B9F6CA !important; }

.bg-green.accent-2 {
  background-color: #69F0AE !important; }

.text-green.text-accent-2 {
  color: #69F0AE !important; }
  .text-green.text-accent-2.icon-outline {
    border-color: #69F0AE !important; }

.bg-green.accent-3 {
  background-color: #00E676 !important; }

.text-green.text-accent-3 {
  color: #00E676 !important; }
  .text-green.text-accent-3.icon-outline {
    border-color: #00E676 !important; }

.bg-green.accent-4 {
  background-color: #00C853 !important; }

.text-green.text-accent-4 {
  color: #00C853 !important; }
  .text-green.text-accent-4.icon-outline {
    border-color: #00C853 !important; }

.bg-light-green {
  background-color: #8bc34a !important; }

.text-light-green {
  color: #8bc34a !important; }
  .text-light-green.icon-outline {
    border-color: #8bc34a !important; }

.bg-light-green.lighten-5 {
  background-color: #f1f8e9 !important; }

.text-light-green.text-lighten-5 {
  color: #f1f8e9 !important; }
  .text-light-green.text-lighten-5.icon-outline {
    border-color: #f1f8e9 !important; }

.bg-light-green.lighten-4 {
  background-color: #dcedc8 !important; }

.text-light-green.text-lighten-4 {
  color: #dcedc8 !important; }
  .text-light-green.text-lighten-4.icon-outline {
    border-color: #dcedc8 !important; }

.bg-light-green.lighten-3 {
  background-color: #c5e1a5 !important; }

.text-light-green.text-lighten-3 {
  color: #c5e1a5 !important; }
  .text-light-green.text-lighten-3.icon-outline {
    border-color: #c5e1a5 !important; }

.bg-light-green.lighten-2 {
  background-color: #aed581 !important; }

.text-light-green.text-lighten-2 {
  color: #aed581 !important; }
  .text-light-green.text-lighten-2.icon-outline {
    border-color: #aed581 !important; }

.bg-light-green.lighten-1 {
  background-color: #9ccc65 !important; }

.text-light-green.text-lighten-1 {
  color: #9ccc65 !important; }
  .text-light-green.text-lighten-1.icon-outline {
    border-color: #9ccc65 !important; }

.bg-light-green.darken-1 {
  background-color: #7cb342 !important; }

.text-light-green.text-darken-1 {
  color: #7cb342 !important; }
  .text-light-green.text-darken-1.icon-outline {
    border-color: #7cb342 !important; }

.bg-light-green.darken-2 {
  background-color: #689f38 !important; }

.text-light-green.text-darken-2 {
  color: #689f38 !important; }
  .text-light-green.text-darken-2.icon-outline {
    border-color: #689f38 !important; }

.bg-light-green.darken-3 {
  background-color: #558b2f !important; }

.text-light-green.text-darken-3 {
  color: #558b2f !important; }
  .text-light-green.text-darken-3.icon-outline {
    border-color: #558b2f !important; }

.bg-light-green.darken-4 {
  background-color: #33691e !important; }

.text-light-green.text-darken-4 {
  color: #33691e !important; }
  .text-light-green.text-darken-4.icon-outline {
    border-color: #33691e !important; }

.bg-light-green.accent-1 {
  background-color: #ccff90 !important; }

.text-light-green.text-accent-1 {
  color: #ccff90 !important; }
  .text-light-green.text-accent-1.icon-outline {
    border-color: #ccff90 !important; }

.bg-light-green.accent-2 {
  background-color: #b2ff59 !important; }

.text-light-green.text-accent-2 {
  color: #b2ff59 !important; }
  .text-light-green.text-accent-2.icon-outline {
    border-color: #b2ff59 !important; }

.bg-light-green.accent-3 {
  background-color: #76ff03 !important; }

.text-light-green.text-accent-3 {
  color: #76ff03 !important; }
  .text-light-green.text-accent-3.icon-outline {
    border-color: #76ff03 !important; }

.bg-light-green.accent-4 {
  background-color: #64dd17 !important; }

.text-light-green.text-accent-4 {
  color: #64dd17 !important; }
  .text-light-green.text-accent-4.icon-outline {
    border-color: #64dd17 !important; }

.bg-lime {
  background-color: #cddc39 !important; }

.text-lime {
  color: #cddc39 !important; }
  .text-lime.icon-outline {
    border-color: #cddc39 !important; }

.bg-lime.lighten-5 {
  background-color: #f9fbe7 !important; }

.text-lime.text-lighten-5 {
  color: #f9fbe7 !important; }
  .text-lime.text-lighten-5.icon-outline {
    border-color: #f9fbe7 !important; }

.bg-lime.lighten-4 {
  background-color: #f0f4c3 !important; }

.text-lime.text-lighten-4 {
  color: #f0f4c3 !important; }
  .text-lime.text-lighten-4.icon-outline {
    border-color: #f0f4c3 !important; }

.bg-lime.lighten-3 {
  background-color: #e6ee9c !important; }

.text-lime.text-lighten-3 {
  color: #e6ee9c !important; }
  .text-lime.text-lighten-3.icon-outline {
    border-color: #e6ee9c !important; }

.bg-lime.lighten-2 {
  background-color: #dce775 !important; }

.text-lime.text-lighten-2 {
  color: #dce775 !important; }
  .text-lime.text-lighten-2.icon-outline {
    border-color: #dce775 !important; }

.bg-lime.lighten-1 {
  background-color: #d4e157 !important; }

.text-lime.text-lighten-1 {
  color: #d4e157 !important; }
  .text-lime.text-lighten-1.icon-outline {
    border-color: #d4e157 !important; }

.bg-lime.darken-1 {
  background-color: #c0ca33 !important; }

.text-lime.text-darken-1 {
  color: #c0ca33 !important; }
  .text-lime.text-darken-1.icon-outline {
    border-color: #c0ca33 !important; }

.bg-lime.darken-2 {
  background-color: #afb42b !important; }

.text-lime.text-darken-2 {
  color: #afb42b !important; }
  .text-lime.text-darken-2.icon-outline {
    border-color: #afb42b !important; }

.bg-lime.darken-3 {
  background-color: #9e9d24 !important; }

.text-lime.text-darken-3 {
  color: #9e9d24 !important; }
  .text-lime.text-darken-3.icon-outline {
    border-color: #9e9d24 !important; }

.bg-lime.darken-4 {
  background-color: #827717 !important; }

.text-lime.text-darken-4 {
  color: #827717 !important; }
  .text-lime.text-darken-4.icon-outline {
    border-color: #827717 !important; }

.bg-lime.accent-1 {
  background-color: #f4ff81 !important; }

.text-lime.text-accent-1 {
  color: #f4ff81 !important; }
  .text-lime.text-accent-1.icon-outline {
    border-color: #f4ff81 !important; }

.bg-lime.accent-2 {
  background-color: #eeff41 !important; }

.text-lime.text-accent-2 {
  color: #eeff41 !important; }
  .text-lime.text-accent-2.icon-outline {
    border-color: #eeff41 !important; }

.bg-lime.accent-3 {
  background-color: #c6ff00 !important; }

.text-lime.text-accent-3 {
  color: #c6ff00 !important; }
  .text-lime.text-accent-3.icon-outline {
    border-color: #c6ff00 !important; }

.bg-lime.accent-4 {
  background-color: #aeea00 !important; }

.text-lime.text-accent-4 {
  color: #aeea00 !important; }
  .text-lime.text-accent-4.icon-outline {
    border-color: #aeea00 !important; }

.bg-yellow {
  background-color: #ffeb3b !important; }

.text-yellow {
  color: #ffeb3b !important; }
  .text-yellow.icon-outline {
    border-color: #ffeb3b !important; }

.bg-yellow.lighten-5 {
  background-color: #fffde7 !important; }

.text-yellow.text-lighten-5 {
  color: #fffde7 !important; }
  .text-yellow.text-lighten-5.icon-outline {
    border-color: #fffde7 !important; }

.bg-yellow.lighten-4 {
  background-color: #fff9c4 !important; }

.text-yellow.text-lighten-4 {
  color: #fff9c4 !important; }
  .text-yellow.text-lighten-4.icon-outline {
    border-color: #fff9c4 !important; }

.bg-yellow.lighten-3 {
  background-color: #fff59d !important; }

.text-yellow.text-lighten-3 {
  color: #fff59d !important; }
  .text-yellow.text-lighten-3.icon-outline {
    border-color: #fff59d !important; }

.bg-yellow.lighten-2 {
  background-color: #fff176 !important; }

.text-yellow.text-lighten-2 {
  color: #fff176 !important; }
  .text-yellow.text-lighten-2.icon-outline {
    border-color: #fff176 !important; }

.bg-yellow.lighten-1 {
  background-color: #ffee58 !important; }

.text-yellow.text-lighten-1 {
  color: #ffee58 !important; }
  .text-yellow.text-lighten-1.icon-outline {
    border-color: #ffee58 !important; }

.bg-yellow.darken-1 {
  background-color: #fdd835 !important; }

.text-yellow.text-darken-1 {
  color: #fdd835 !important; }
  .text-yellow.text-darken-1.icon-outline {
    border-color: #fdd835 !important; }

.bg-yellow.darken-2 {
  background-color: #fbc02d !important; }

.text-yellow.text-darken-2 {
  color: #fbc02d !important; }
  .text-yellow.text-darken-2.icon-outline {
    border-color: #fbc02d !important; }

.bg-yellow.darken-3 {
  background-color: #f9a825 !important; }

.text-yellow.text-darken-3 {
  color: #f9a825 !important; }
  .text-yellow.text-darken-3.icon-outline {
    border-color: #f9a825 !important; }

.bg-yellow.darken-4 {
  background-color: #f57f17 !important; }

.text-yellow.text-darken-4 {
  color: #f57f17 !important; }
  .text-yellow.text-darken-4.icon-outline {
    border-color: #f57f17 !important; }

.bg-yellow.accent-1 {
  background-color: #ffff8d !important; }

.text-yellow.text-accent-1 {
  color: #ffff8d !important; }
  .text-yellow.text-accent-1.icon-outline {
    border-color: #ffff8d !important; }

.bg-yellow.accent-2 {
  background-color: #ffff00 !important; }

.text-yellow.text-accent-2 {
  color: #ffff00 !important; }
  .text-yellow.text-accent-2.icon-outline {
    border-color: #ffff00 !important; }

.bg-yellow.accent-3 {
  background-color: #ffea00 !important; }

.text-yellow.text-accent-3 {
  color: #ffea00 !important; }
  .text-yellow.text-accent-3.icon-outline {
    border-color: #ffea00 !important; }

.bg-yellow.accent-4 {
  background-color: #ffd600 !important; }

.text-yellow.text-accent-4 {
  color: #ffd600 !important; }
  .text-yellow.text-accent-4.icon-outline {
    border-color: #ffd600 !important; }

.bg-amber {
  background-color: #ffc107 !important; }

.text-amber {
  color: #ffc107 !important; }
  .text-amber.icon-outline {
    border-color: #ffc107 !important; }

.bg-amber.lighten-5 {
  background-color: #fff8e1 !important; }

.text-amber.text-lighten-5 {
  color: #fff8e1 !important; }
  .text-amber.text-lighten-5.icon-outline {
    border-color: #fff8e1 !important; }

.bg-amber.lighten-4 {
  background-color: #ffecb3 !important; }

.text-amber.text-lighten-4 {
  color: #ffecb3 !important; }
  .text-amber.text-lighten-4.icon-outline {
    border-color: #ffecb3 !important; }

.bg-amber.lighten-3 {
  background-color: #ffe082 !important; }

.text-amber.text-lighten-3 {
  color: #ffe082 !important; }
  .text-amber.text-lighten-3.icon-outline {
    border-color: #ffe082 !important; }

.bg-amber.lighten-2 {
  background-color: #ffd54f !important; }

.text-amber.text-lighten-2 {
  color: #ffd54f !important; }
  .text-amber.text-lighten-2.icon-outline {
    border-color: #ffd54f !important; }

.bg-amber.lighten-1 {
  background-color: #ffca28 !important; }

.text-amber.text-lighten-1 {
  color: #ffca28 !important; }
  .text-amber.text-lighten-1.icon-outline {
    border-color: #ffca28 !important; }

.bg-amber.darken-1 {
  background-color: #ffb300 !important; }

.text-amber.text-darken-1 {
  color: #ffb300 !important; }
  .text-amber.text-darken-1.icon-outline {
    border-color: #ffb300 !important; }

.bg-amber.darken-2 {
  background-color: #ffa000 !important; }

.text-amber.text-darken-2 {
  color: #ffa000 !important; }
  .text-amber.text-darken-2.icon-outline {
    border-color: #ffa000 !important; }

.bg-amber.darken-3 {
  background-color: #ff8f00 !important; }

.text-amber.text-darken-3 {
  color: #ff8f00 !important; }
  .text-amber.text-darken-3.icon-outline {
    border-color: #ff8f00 !important; }

.bg-amber.darken-4 {
  background-color: #ff6f00 !important; }

.text-amber.text-darken-4 {
  color: #ff6f00 !important; }
  .text-amber.text-darken-4.icon-outline {
    border-color: #ff6f00 !important; }

.bg-amber.accent-1 {
  background-color: #ffe57f !important; }

.text-amber.text-accent-1 {
  color: #ffe57f !important; }
  .text-amber.text-accent-1.icon-outline {
    border-color: #ffe57f !important; }

.bg-amber.accent-2 {
  background-color: #ffd740 !important; }

.text-amber.text-accent-2 {
  color: #ffd740 !important; }
  .text-amber.text-accent-2.icon-outline {
    border-color: #ffd740 !important; }

.bg-amber.accent-3 {
  background-color: #ffc400 !important; }

.text-amber.text-accent-3 {
  color: #ffc400 !important; }
  .text-amber.text-accent-3.icon-outline {
    border-color: #ffc400 !important; }

.bg-amber.accent-4 {
  background-color: #ffab00 !important; }

.text-amber.text-accent-4 {
  color: #ffab00 !important; }
  .text-amber.text-accent-4.icon-outline {
    border-color: #ffab00 !important; }

.bg-orange {
  background-color: #ff5722 !important; }

.text-orange {
  color: #ff5722 !important; }
  .text-orange.icon-outline {
    border-color: #ff5722 !important; }

.bg-orange.lighten-5 {
  background-color: #fff3e0 !important; }

.text-orange.text-lighten-5 {
  color: #fff3e0 !important; }
  .text-orange.text-lighten-5.icon-outline {
    border-color: #fff3e0 !important; }

.bg-orange.lighten-4 {
  background-color: #ffe0b2 !important; }

.text-orange.text-lighten-4 {
  color: #ffe0b2 !important; }
  .text-orange.text-lighten-4.icon-outline {
    border-color: #ffe0b2 !important; }

.bg-orange.lighten-3 {
  background-color: #ffcc80 !important; }

.text-orange.text-lighten-3 {
  color: #ffcc80 !important; }
  .text-orange.text-lighten-3.icon-outline {
    border-color: #ffcc80 !important; }

.bg-orange.lighten-2 {
  background-color: #ffb74d !important; }

.text-orange.text-lighten-2 {
  color: #ffb74d !important; }
  .text-orange.text-lighten-2.icon-outline {
    border-color: #ffb74d !important; }

.bg-orange.lighten-1 {
  background-color: #ffa726 !important; }

.text-orange.text-lighten-1 {
  color: #ffa726 !important; }
  .text-orange.text-lighten-1.icon-outline {
    border-color: #ffa726 !important; }

.bg-orange.darken-1 {
  background-color: #fb8c00 !important; }

.text-orange.text-darken-1 {
  color: #fb8c00 !important; }
  .text-orange.text-darken-1.icon-outline {
    border-color: #fb8c00 !important; }

.bg-orange.darken-2 {
  background-color: #f57c00 !important; }

.text-orange.text-darken-2 {
  color: #f57c00 !important; }
  .text-orange.text-darken-2.icon-outline {
    border-color: #f57c00 !important; }

.bg-orange.darken-3 {
  background-color: #ef6c00 !important; }

.text-orange.text-darken-3 {
  color: #ef6c00 !important; }
  .text-orange.text-darken-3.icon-outline {
    border-color: #ef6c00 !important; }

.bg-orange.darken-4 {
  background-color: #e65100 !important; }

.text-orange.text-darken-4 {
  color: #e65100 !important; }
  .text-orange.text-darken-4.icon-outline {
    border-color: #e65100 !important; }

.bg-orange.accent-1 {
  background-color: #ffd180 !important; }

.text-orange.text-accent-1 {
  color: #ffd180 !important; }
  .text-orange.text-accent-1.icon-outline {
    border-color: #ffd180 !important; }

.bg-orange.accent-2 {
  background-color: #ffab40 !important; }

.text-orange.text-accent-2 {
  color: #ffab40 !important; }
  .text-orange.text-accent-2.icon-outline {
    border-color: #ffab40 !important; }

.bg-orange.accent-3 {
  background-color: #ff9100 !important; }

.text-orange.text-accent-3 {
  color: #ff9100 !important; }
  .text-orange.text-accent-3.icon-outline {
    border-color: #ff9100 !important; }

.bg-orange.accent-4 {
  background-color: #ff6d00 !important; }

.text-orange.text-accent-4 {
  color: #ff6d00 !important; }
  .text-orange.text-accent-4.icon-outline {
    border-color: #ff6d00 !important; }

.bg-deep-orange {
  background-color: #ff5722 !important; }

.text-deep-orange {
  color: #ff5722 !important; }
  .text-deep-orange.icon-outline {
    border-color: #ff5722 !important; }

.bg-deep-orange.lighten-5 {
  background-color: #fbe9e7 !important; }

.text-deep-orange.text-lighten-5 {
  color: #fbe9e7 !important; }
  .text-deep-orange.text-lighten-5.icon-outline {
    border-color: #fbe9e7 !important; }

.bg-deep-orange.lighten-4 {
  background-color: #ffccbc !important; }

.text-deep-orange.text-lighten-4 {
  color: #ffccbc !important; }
  .text-deep-orange.text-lighten-4.icon-outline {
    border-color: #ffccbc !important; }

.bg-deep-orange.lighten-3 {
  background-color: #ffab91 !important; }

.text-deep-orange.text-lighten-3 {
  color: #ffab91 !important; }
  .text-deep-orange.text-lighten-3.icon-outline {
    border-color: #ffab91 !important; }

.bg-deep-orange.lighten-2 {
  background-color: #ff8a65 !important; }

.text-deep-orange.text-lighten-2 {
  color: #ff8a65 !important; }
  .text-deep-orange.text-lighten-2.icon-outline {
    border-color: #ff8a65 !important; }

.bg-deep-orange.lighten-1 {
  background-color: #ff7043 !important; }

.text-deep-orange.text-lighten-1 {
  color: #ff7043 !important; }
  .text-deep-orange.text-lighten-1.icon-outline {
    border-color: #ff7043 !important; }

.bg-deep-orange.darken-1 {
  background-color: #f4511e !important; }

.text-deep-orange.text-darken-1 {
  color: #f4511e !important; }
  .text-deep-orange.text-darken-1.icon-outline {
    border-color: #f4511e !important; }

.bg-deep-orange.darken-2 {
  background-color: #e64a19 !important; }

.text-deep-orange.text-darken-2 {
  color: #e64a19 !important; }
  .text-deep-orange.text-darken-2.icon-outline {
    border-color: #e64a19 !important; }

.bg-deep-orange.darken-3 {
  background-color: #d84315 !important; }

.text-deep-orange.text-darken-3 {
  color: #d84315 !important; }
  .text-deep-orange.text-darken-3.icon-outline {
    border-color: #d84315 !important; }

.bg-deep-orange.darken-4 {
  background-color: #bf360c !important; }

.text-deep-orange.text-darken-4 {
  color: #bf360c !important; }
  .text-deep-orange.text-darken-4.icon-outline {
    border-color: #bf360c !important; }

.bg-deep-orange.accent-1 {
  background-color: #ff9e80 !important; }

.text-deep-orange.text-accent-1 {
  color: #ff9e80 !important; }
  .text-deep-orange.text-accent-1.icon-outline {
    border-color: #ff9e80 !important; }

.bg-deep-orange.accent-2 {
  background-color: #ff6e40 !important; }

.text-deep-orange.text-accent-2 {
  color: #ff6e40 !important; }
  .text-deep-orange.text-accent-2.icon-outline {
    border-color: #ff6e40 !important; }

.bg-deep-orange.accent-3 {
  background-color: #ff3d00 !important; }

.text-deep-orange.text-accent-3 {
  color: #ff3d00 !important; }
  .text-deep-orange.text-accent-3.icon-outline {
    border-color: #ff3d00 !important; }

.bg-deep-orange.accent-4 {
  background-color: #dd2c00 !important; }

.text-deep-orange.text-accent-4 {
  color: #dd2c00 !important; }
  .text-deep-orange.text-accent-4.icon-outline {
    border-color: #dd2c00 !important; }

.bg-brown {
  background-color: #795548 !important; }

.text-brown {
  color: #795548 !important; }
  .text-brown.icon-outline {
    border-color: #795548 !important; }

.bg-brown.lighten-5 {
  background-color: #efebe9 !important; }

.text-brown.text-lighten-5 {
  color: #efebe9 !important; }
  .text-brown.text-lighten-5.icon-outline {
    border-color: #efebe9 !important; }

.bg-brown.lighten-4 {
  background-color: #d7ccc8 !important; }

.text-brown.text-lighten-4 {
  color: #d7ccc8 !important; }
  .text-brown.text-lighten-4.icon-outline {
    border-color: #d7ccc8 !important; }

.bg-brown.lighten-3 {
  background-color: #bcaaa4 !important; }

.text-brown.text-lighten-3 {
  color: #bcaaa4 !important; }
  .text-brown.text-lighten-3.icon-outline {
    border-color: #bcaaa4 !important; }

.bg-brown.lighten-2 {
  background-color: #a1887f !important; }

.text-brown.text-lighten-2 {
  color: #a1887f !important; }
  .text-brown.text-lighten-2.icon-outline {
    border-color: #a1887f !important; }

.bg-brown.lighten-1 {
  background-color: #8d6e63 !important; }

.text-brown.text-lighten-1 {
  color: #8d6e63 !important; }
  .text-brown.text-lighten-1.icon-outline {
    border-color: #8d6e63 !important; }

.bg-brown.darken-1 {
  background-color: #6d4c41 !important; }

.text-brown.text-darken-1 {
  color: #6d4c41 !important; }
  .text-brown.text-darken-1.icon-outline {
    border-color: #6d4c41 !important; }

.bg-brown.darken-2 {
  background-color: #5d4037 !important; }

.text-brown.text-darken-2 {
  color: #5d4037 !important; }
  .text-brown.text-darken-2.icon-outline {
    border-color: #5d4037 !important; }

.bg-brown.darken-3 {
  background-color: #4e342e !important; }

.text-brown.text-darken-3 {
  color: #4e342e !important; }
  .text-brown.text-darken-3.icon-outline {
    border-color: #4e342e !important; }

.bg-brown.darken-4 {
  background-color: #3e2723 !important; }

.text-brown.text-darken-4 {
  color: #3e2723 !important; }
  .text-brown.text-darken-4.icon-outline {
    border-color: #3e2723 !important; }

.bg-blue-grey {
  background-color: #607d8b !important; }

.text-blue-grey {
  color: #607d8b !important; }
  .text-blue-grey.icon-outline {
    border-color: #607d8b !important; }

.bg-blue-grey.lighten-5 {
  background-color: #eceff1 !important; }

.text-blue-grey.text-lighten-5 {
  color: #eceff1 !important; }
  .text-blue-grey.text-lighten-5.icon-outline {
    border-color: #eceff1 !important; }

.bg-blue-grey.lighten-4 {
  background-color: #cfd8dc !important; }

.text-blue-grey.text-lighten-4 {
  color: #cfd8dc !important; }
  .text-blue-grey.text-lighten-4.icon-outline {
    border-color: #cfd8dc !important; }

.bg-blue-grey.lighten-3 {
  background-color: #b0bec5 !important; }

.text-blue-grey.text-lighten-3 {
  color: #b0bec5 !important; }
  .text-blue-grey.text-lighten-3.icon-outline {
    border-color: #b0bec5 !important; }

.bg-blue-grey.lighten-2 {
  background-color: #90a4ae !important; }

.text-blue-grey.text-lighten-2 {
  color: #90a4ae !important; }
  .text-blue-grey.text-lighten-2.icon-outline {
    border-color: #90a4ae !important; }

.bg-blue-grey.lighten-1 {
  background-color: #78909c !important; }

.text-blue-grey.text-lighten-1 {
  color: #78909c !important; }
  .text-blue-grey.text-lighten-1.icon-outline {
    border-color: #78909c !important; }

.bg-blue-grey.darken-1 {
  background-color: #546e7a !important; }

.text-blue-grey.text-darken-1 {
  color: #546e7a !important; }
  .text-blue-grey.text-darken-1.icon-outline {
    border-color: #546e7a !important; }

.bg-blue-grey.darken-2 {
  background-color: #455a64 !important; }

.text-blue-grey.text-darken-2 {
  color: #455a64 !important; }
  .text-blue-grey.text-darken-2.icon-outline {
    border-color: #455a64 !important; }

.bg-blue-grey.darken-3 {
  background-color: #37474f !important; }

.text-blue-grey.text-darken-3 {
  color: #37474f !important; }
  .text-blue-grey.text-darken-3.icon-outline {
    border-color: #37474f !important; }

.bg-blue-grey.darken-4 {
  background-color: #263238 !important; }

.text-blue-grey.text-darken-4 {
  color: #263238 !important; }
  .text-blue-grey.text-darken-4.icon-outline {
    border-color: #263238 !important; }

.bg-grey {
  background-color: #9e9e9e !important; }

.text-grey {
  color: #9e9e9e !important; }
  .text-grey.icon-outline {
    border-color: #9e9e9e !important; }

.bg-grey.lighten-5 {
  background-color: #fafafa !important; }

.text-grey.text-lighten-5 {
  color: #fafafa !important; }
  .text-grey.text-lighten-5.icon-outline {
    border-color: #fafafa !important; }

.bg-grey.lighten-4 {
  background-color: #f5f5f5 !important; }

.text-grey.text-lighten-4 {
  color: #f5f5f5 !important; }
  .text-grey.text-lighten-4.icon-outline {
    border-color: #f5f5f5 !important; }

.bg-grey.lighten-3 {
  background-color: #eeeeee !important; }

.text-grey.text-lighten-3 {
  color: #eeeeee !important; }
  .text-grey.text-lighten-3.icon-outline {
    border-color: #eeeeee !important; }

.bg-grey.lighten-2 {
  background-color: #e0e0e0 !important; }

.text-grey.text-lighten-2 {
  color: #e0e0e0 !important; }
  .text-grey.text-lighten-2.icon-outline {
    border-color: #e0e0e0 !important; }

.bg-grey.lighten-1 {
  background-color: #bdbdbd !important; }

.text-grey.text-lighten-1 {
  color: #bdbdbd !important; }
  .text-grey.text-lighten-1.icon-outline {
    border-color: #bdbdbd !important; }

.bg-grey.darken-1 {
  background-color: #757575 !important; }

.text-grey.text-darken-1 {
  color: #757575 !important; }
  .text-grey.text-darken-1.icon-outline {
    border-color: #757575 !important; }

.bg-grey.darken-2 {
  background-color: #616161 !important; }

.text-grey.text-darken-2 {
  color: #616161 !important; }
  .text-grey.text-darken-2.icon-outline {
    border-color: #616161 !important; }

.bg-grey.darken-3 {
  background-color: #424242 !important; }

.text-grey.text-darken-3 {
  color: #424242 !important; }
  .text-grey.text-darken-3.icon-outline {
    border-color: #424242 !important; }

.bg-grey.darken-4 {
  background-color: #212121 !important; }

.text-grey.text-darken-4 {
  color: #212121 !important; }
  .text-grey.text-darken-4.icon-outline {
    border-color: #212121 !important; }

.bg-black {
  background-color: #000000 !important; }

.text-black {
  color: #000000 !important; }
  .text-black.icon-outline {
    border-color: #000000 !important; }

.bg-white {
  background-color: #FFFFFF !important; }

.text-white {
  color: #FFFFFF !important; }
  .text-white.icon-outline {
    border-color: #FFFFFF !important; }

.bg-transparent {
  background-color: transparent !important; }

.text-transparent {
  color: transparent !important; }
  .text-transparent.icon-outline {
    border-color: transparent !important; }

/*Custom Animation*/
@keyframes flipCenter {
  0% {
    animation-timing-function: ease-in;
    opacity: 0;
    transform: perspective(100000px) rotate3d(1, 0, 0, 90deg); }
  40% {
    animation-timing-function: ease-in;
    transform: perspective(100000px) rotate3d(1, 0, 0, 0deg); }
  60% {
    opacity: 1;
    transform: perspective(100000px) rotate3d(1, 0, 0, 0deg); }
  80% {
    transform: perspective(100000px) rotate3d(1, 0, 0, 0deg); }
  100% {
    transform: perspective(100000px); } }

@-webkit-keyframes flipCenter {
  0% {
    animation-timing-function: ease-in;
    opacity: 0;
    transform: perspective(100000px) rotate3d(1, 0, 0, 90deg); }
  40% {
    animation-timing-function: ease-in;
    transform: perspective(100000px) rotate3d(1, 0, 0, 0deg); }
  60% {
    opacity: 1;
    transform: perspective(100000px) rotate3d(1, 0, 0, 0deg); }
  80% {
    transform: perspective(100000px) rotate3d(1, 0, 0, 0deg); }
  100% {
    transform: perspective(100000px); } }

.flipCenter {
  animation-name: flipCenter;
  backface-visibility: visible; }

@keyframes desployDown {
  0% {
    transform: scaleY(0);
    transform-origin: center top 0; }
  100% {
    opacity: 1;
    transform: scaleY(1);
    transform-origin: center top 0; } }

@-webkit-keyframes desployDown {
  0% {
    transform: scaleY(0);
    transform-origin: center top 0; }
  100% {
    opacity: 1;
    transform: scaleY(1);
    transform-origin: center top 0; } }

.desployDown {
  animation-name: desployDown;
  backface-visibility: visible; }

@keyframes fadeInRightTiny {
  0% {
    opacity: 0;
    transform: translate3d(30%, 0px, 0px); }
  100% {
    opacity: 1;
    transform: none; } }

@-webkit-keyframes fadeInRightTiny {
  0% {
    opacity: 0;
    transform: translate3d(30%, 0px, 0px); }
  100% {
    opacity: 1;
    transform: none; } }

.fadeInRightTiny {
  animation-name: fadeInRightTiny; }

@keyframes fadeInLeftTiny {
  0% {
    opacity: 0;
    transform: translate3d(-30%, 0px, 0px); }
  100% {
    opacity: 1;
    transform: none; } }

@-webkit-keyframes fadeInLeftTiny {
  0% {
    opacity: 0;
    transform: translate3d(-30%, 0px, 0px); }
  100% {
    opacity: 1;
    transform: none; } }

.fadeInLeftTiny {
  animation-name: fadeInLeftTiny; }

@keyframes slideInRightTiny {
  0% {
    transform: translate3d(30%, 0px, 0px); }
  100% {
    transform: none; } }

@-webkit-keyframes slideInRightTiny {
  0% {
    transform: translate3d(30%, 0px, 0px); }
  100% {
    transform: none; } }

.slideInRightTiny {
  animation-name: slideInRightTiny; }

@keyframes slideInLeftTiny {
  0% {
    transform: translate3d(-30%, 0px, 0px); }
  100% {
    transform: none; } }

@-webkit-keyframes slideInLeftTiny {
  0% {
    transform: translate3d(-30%, 0px, 0px); }
  100% {
    transform: none; } }

.slideInLeftTiny {
  animation-name: slideInLeftTiny; }

@keyframes slideInUpTiny {
  0% {
    transform: translate3d(0px, 5%, 0px); }
  100% {
    transform: none; } }

@-webkit-keyframes slideInUpTiny {
  0% {
    transform: translate3d(0px, 5%, 0px); }
  100% {
    transform: none; } }

.slideInUpTiny {
  animation-name: slideInUpTiny; }

/*Animation Durations*/
.animation-duration-1 {
  animation-duration: 0.1s; }

.animation-duration-2 {
  animation-duration: 0.2s; }

.animation-duration-3 {
  animation-duration: 0.3s; }

.animation-duration-4 {
  animation-duration: 0.4s; }

.animation-duration-5 {
  animation-duration: 0.5s; }

.animation-duration-6 {
  animation-duration: 0.6s; }

.animation-duration-7 {
  animation-duration: 0.7s; }

.animation-duration-8 {
  animation-duration: 0.8s; }

.animation-duration-9 {
  animation-duration: 0.9s; }

.animation-duration-10 {
  animation-duration: 1s; }

.animation-duration-11 {
  animation-duration: 1.1s; }

.animation-duration-12 {
  animation-duration: 1.2s; }

.animation-duration-13 {
  animation-duration: 1.3s; }

.animation-duration-14 {
  animation-duration: 1.4s; }

.animation-duration-15 {
  animation-duration: 1.5s; }

.animation-duration-16 {
  animation-duration: 1.6s; }

.animation-duration-17 {
  animation-duration: 1.7s; }

.animation-duration-18 {
  animation-duration: 1.8s; }

.animation-duration-19 {
  animation-duration: 1.9s; }

.animation-duration-20 {
  animation-duration: 2s; }

.animation-duration-21 {
  animation-duration: 2.1s; }

.animation-duration-22 {
  animation-duration: 2.2s; }

.animation-duration-23 {
  animation-duration: 2.3s; }

.animation-duration-24 {
  animation-duration: 2.4s; }

.animation-duration-25 {
  animation-duration: 2.5s; }

.animation-duration-26 {
  animation-duration: 2.6s; }

.animation-duration-27 {
  animation-duration: 2.7s; }

.animation-duration-28 {
  animation-duration: 2.8s; }

.animation-duration-29 {
  animation-duration: 2.9s; }

.animation-duration-30 {
  animation-duration: 3s; }

.animation-duration-31 {
  animation-duration: 3.1s; }

.animation-duration-32 {
  animation-duration: 3.2s; }

.animation-duration-33 {
  animation-duration: 3.3s; }

.animation-duration-34 {
  animation-duration: 3.4s; }

.animation-duration-35 {
  animation-duration: 3.5s; }

.animation-duration-36 {
  animation-duration: 3.6s; }

.animation-duration-37 {
  animation-duration: 3.7s; }

.animation-duration-38 {
  animation-duration: 3.8s; }

.animation-duration-39 {
  animation-duration: 3.9s; }

.animation-duration-40 {
  animation-duration: 4s; }

.animation-delay-1 {
  animation-delay: 0.1s; }

.animation-delay-2 {
  animation-delay: 0.2s; }

.animation-delay-3 {
  animation-delay: 0.3s; }

.animation-delay-4 {
  animation-delay: 0.4s; }

.animation-delay-5 {
  animation-delay: 0.5s; }

.animation-delay-6 {
  animation-delay: 0.6s; }

.animation-delay-7 {
  animation-delay: 0.7s; }

.animation-delay-8 {
  animation-delay: 0.8s; }

.animation-delay-9 {
  animation-delay: 0.9s; }

.animation-delay-10 {
  animation-delay: 1s; }

.animation-delay-11 {
  animation-delay: 1.1s; }

.animation-delay-12 {
  animation-delay: 1.2s; }

.animation-delay-13 {
  animation-delay: 1.3s; }

.animation-delay-14 {
  animation-delay: 1.4s; }

.animation-delay-15 {
  animation-delay: 1.5s; }

.animation-delay-16 {
  animation-delay: 1.6s; }

.animation-delay-17 {
  animation-delay: 1.7s; }

.animation-delay-18 {
  animation-delay: 1.8s; }

.animation-delay-19 {
  animation-delay: 1.9s; }

.animation-delay-20 {
  animation-delay: 2s; }

.animation-delay-21 {
  animation-delay: 2.1s; }

.animation-delay-22 {
  animation-delay: 2.2s; }

.animation-delay-23 {
  animation-delay: 2.3s; }

.animation-delay-24 {
  animation-delay: 2.4s; }

.animation-delay-25 {
  animation-delay: 2.5s; }

.animation-delay-26 {
  animation-delay: 2.6s; }

.animation-delay-27 {
  animation-delay: 2.6s; }

.animation-delay-28 {
  animation-delay: 2.8s; }

.animation-delay-29 {
  animation-delay: 2.9s; }

.animation-delay-30 {
  animation-delay: 3s; }

.animation-delay-31 {
  animation-delay: 3.1s; }

.animation-delay-32 {
  animation-delay: 3.2s; }

.animation-delay-33 {
  animation-delay: 3.3s; }

.animation-delay-34 {
  animation-delay: 3.4s; }

.animation-delay-35 {
  animation-delay: 3.5s; }

.animation-delay-36 {
  animation-delay: 3.6s; }

.animation-delay-37 {
  animation-delay: 3.7s; }

.animation-delay-38 {
  animation-delay: 3.8s; }

.animation-delay-39 {
  animation-delay: 3.9s; }

.animation-delay-40 {
  animation-delay: 4s; }

.animation-delay-41 {
  animation-delay: 4.1s; }

.animation-delay-42 {
  animation-delay: 4.2s; }

.animation-delay-43 {
  animation-delay: 4.3s; }

.animation-delay-44 {
  animation-delay: 4.4s; }

.animation-delay-45 {
  animation-delay: 4.5s; }

.animation-delay-46 {
  animation-delay: 4.6s; }

.animation-delay-47 {
  animation-delay: 4.7s; }

.animation-delay-48 {
  animation-delay: 4.8s; }

.animation-delay-49 {
  animation-delay: 4.9s; }

.animation-delay-50 {
  animation-delay: 5s; }

/* Base
   ========================================================================== */
/*Base Styles*/
* {
  -webkit-overflow-scrolling: touch; }

html {
  height: 100%; }

body {
  font-family: "Libre Franklin", sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #3C4858;
  background-color: #f8f9fa;
  height: 100%;
  overflow: auto; }

body.ios-mobile-view-height {
  height: auto !important;
  overflow: visible !important; }

#nprogress .bar {
  z-index: 5000 !important;
  background: #6c757d !important; }

#nprogress .peg {
  -webkit-box-shadow: 0 0 10px #6c757d, 0 0 5px #6c757d !important;
  -moz-box-shadow: 0 0 10px #6c757d, 0 0 5px #6c757d !important;
  -o-box-shadow: 0 0 10px #6c757d, 0 0 5px #6c757d !important;
  box-shadow: 0 0 10px #6c757d, 0 0 5px #6c757d !important; }

#nprogress .spinner {
  z-index: 5000 !important; }

#nprogress .spinner-icon {
  border-top-color: #6c757d !important;
  border-left-color: #6c757d !important; }

.text-transform-none {
  text-transform: none; }

.text-underline {
  text-decoration: underline; }

.br-break {
  word-break: break-all !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-medium {
  font-weight: 500 !important; }

.font-weight-semibold {
  font-weight: 500 !important; }

.z-index-20 {
  z-index: 20; }

.border-bottom {
  border-bottom: solid 1px #dee2e6; }

div[tabindex="-1"] {
  z-index: 1500 !important; }

div[tabindex="-2"] {
  z-index: 2000 !important; }

.col-with-divider div[class*="col-"] {
  border-left: solid 1px #dee2e6; }
  .col-with-divider div[class*="col-"]:first-child {
    border: 0 none; }

.height-100 {
  height: 100px !important; }

.height-150 {
  height: 150px !important; }

.height-200 {
  height: 200px !important; }

.max-width-100 {
  max-width: 100px !important; }

.max-height-120 {
  max-height: 120px !important; }

.right-arrow {
  display: inline-block;
  color: #00b96c;
  text-transform: capitalize; }
  .right-arrow:after {
    content: "\f30f";
    font-family: "Material-Design-Iconic-Font";
    color: #00b96c;
    margin-left: 8px;
    font-size: 20px;
    vertical-align: middle; }
  .right-arrow:hover, .right-arrow:focus {
    text-decoration: none; }

.letter-spacing-base {
  letter-spacing: 3px; }

.letter-spacing-lg {
  letter-spacing: 6px; }

.letter-spacing-xl {
  letter-spacing: 8px; }

.page-heading {
  margin-bottom: 36px;
  padding: 18px 24px; }
  .page-heading .title {
    text-transform: capitalize; }
  .page-heading .breadcrumb {
    padding: 0 !important;
    background-color: transparent !important;
    margin-bottom: 0; }
  @media screen and (max-width: 575px) {
    .page-heading {
      margin-bottom: 18px; } }

.sub-heading {
  font-size: 15px;
  color: #6c757d;
  margin-bottom: 0; }
  .sub-heading:not(:last-child) {
    margin-bottom: 16px !important; }

.manage-margin {
  margin-bottom: 0; }
  .manage-margin > * {
    margin-bottom: 6px; }
    .manage-margin > *:not(:last-child) {
      margin-right: 4px; }

.no-shadow {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  -o-box-shadow: none !important;
  box-shadow: none !important; }

.shadow-sm {
  -webkit-box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2); }

.shadow {
  -webkit-box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.2); }

.shadow-lg {
  -webkit-box-shadow: 0 1px 8px -1px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 8px -1px rgba(0, 0, 0, 0.3);
  -o-box-shadow: 0 1px 8px -1px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 8px -1px rgba(0, 0, 0, 0.3); }

.shadow-xl {
  -webkit-box-shadow: 0 0 16px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 16px 0px rgba(0, 0, 0, 0.3);
  -o-box-shadow: 0 0 16px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 16px 0px rgba(0, 0, 0, 0.3); }

.avatar-shadow {
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  -o-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5); }

.remove-table-border td, .remove-table-border th {
  border: 0 none; }

.media-list {
  margin-bottom: 10px; }
  .media-list:not(:last-child) {
    border-bottom: solid 1px #e9ecef;
    padding-bottom: 15px;
    margin-bottom: 20px;
    position: relative; }

.border-2 {
  border: 2px solid #ced4da !important; }

.border-w2 {
  border-width: 2px; }

.border-primary {
  border-color: #00b96c !important; }

.border-red {
  border-color: #F44336 !important; }

.border-pink {
  border-color: #e91e63 !important; }

.border-purple {
  border-color: #9C27B0 !important; }

.border-deep-purple {
  border-color: #673ab7 !important; }

.border-indigo {
  border-color: #3f51b5 !important; }

.border-blue {
  border-color: #2196F3 !important; }

.border-light-blue {
  border-color: #03a9f4 !important; }

.border-cyan {
  border-color: #00bcd4 !important; }

.border-teal {
  border-color: #009688 !important; }

.border-green {
  border-color: #E8F5E9 !important; }

.border-light-green {
  border-color: #8bc34a !important; }

.border-lime {
  border-color: #cddc39 !important; }

.border-yellow {
  border-color: #ffeb3b !important; }

.border-amber {
  border-color: #ffc107 !important; }

.border-orange {
  border-color: #ff5722 !important; }

.border-deep-orange {
  border-color: #ff5722 !important; }

.border-brown {
  border-color: #795548 !important; }

.border-blue-grey {
  border-color: #607d8b !important; }

.border-grey {
  border-color: #9e9e9e !important; }

.center-crop-img {
  position: relative;
  width: 100%;
  min-height: 120px;
  overflow: hidden; }
  .center-crop-img img {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 100%;
    width: auto;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  .center-crop-img img.portrait {
    width: 100%;
    height: auto; }

.pointer {
  cursor: pointer; }

/*Typography Styles*/
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  margin: 0 0 16px;
  color: #333333; }

h1, .h1 {
  font-size: 32px !important; }

h2, .h2 {
  font-size: 24px !important; }

h3, .h3 {
  font-size: 18px !important; }

h4, .h4 {
  font-size: 15px !important; }

h5, .h5 {
  font-size: 13px !important; }

h6, .h6 {
  font-size: 12px !important; }

.text-small {
  font-size: 13px !important; }

.text-normal {
  font-size: 15px !important; }

.text-large {
  font-size: 17px !important; }

.text-strikethrough {
  text-decoration: line-through !important; }

.desc-list {
  margin-bottom: 0; }
  .desc-list li {
    padding: 18px 24px;
    border-bottom: 1px solid #adb5bd;
    margin-left: -24px;
    margin-right: -24px; }
    .desc-list li:last-child {
      border-bottom: 0 none; }
    .desc-list li .card-title {
      margin-bottom: 6px; }
  .desc-list.even-odd li {
    border-bottom: 0 none; }
    .desc-list.even-odd li:nth-child(2n) {
      background-color: #dee2e6; }

/* Layouts
   ========================================================================== */
/*App Layout Styles*/
#app-site,
.app-main {
  display: flex;
  width: 100%;
  height: 100%; }

.app-container {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 100%; }

.app-sidebar {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 280px;
  min-width: 280px;
  max-width: 280px; }

.app-main-container {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 100%;
  /*overflow: hidden;*/ }

.app-header {
  min-height: 70px; }

.app-main-content-wrapper {
  flex: 1;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 100%;
  /*overflow-x: hidden;
  overflow-y: auto;*/ }

.app-container.fixed-drawer .app-main-header {
  width: auto !important;
  -webkit-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1) !important;
  -o-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1) !important;
  transition: all 0.5s cubic-bezier(0, 0, 0.2, 1) !important;
  left: 280px;
  right: 0; }
  @media screen and (max-width: 1199px) {
    .app-container.fixed-drawer .app-main-header {
      left: 0;
      right: 0; } }

.app-container.collapsible-drawer .app-main-header {
  width: auto !important;
  -webkit-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1) !important;
  -o-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1) !important;
  transition: all 0.5s cubic-bezier(0, 0, 0.2, 1) !important;
  left: 0;
  right: 0; }

.app-container.mini-drawer .app-main-header {
  width: auto !important;
  -webkit-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1) !important;
  -o-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1) !important;
  transition: all 0.5s cubic-bezier(0, 0, 0.2, 1) !important;
  left: 80px;
  right: 0; }

.app-container.mini-drawer .app-sidebar {
  width: 80px;
  min-width: 80px;
  max-width: 80px; }

/*Sidebar Styles*/
.side-nav {
  width: 280px;
  background-color: #252525 !important;
  color: #a1a1a1 !important;
  border-right: 0 none !important;
  z-index: 1250 !important; }
  .side-nav .user-profile {
    background-color: #1d1d1d;
    padding: 13px 20px !important; }
  .side-nav .user-avatar {
    margin: 0 15px 0 0 !important;
    height: 44px !important;
    width: 44px !important;
    line-height: 44px; }
  .side-nav .user-detail .user-name {
    color: #fff;
    cursor: pointer;
    font-size: 15px;
    font-weight: 400;
    white-space: nowrap; }

.user-detail .user-name {
  cursor: pointer; }

ul.nav-menu {
  position: relative;
  font-size: 15px;
  list-style: outside none none;
  margin: 0;
  padding: 0; }

ul.nav-menu li {
  position: relative;
  margin-bottom: 1px; }

ul.nav-menu li .active {
  border-left: 2px solid #FC9631; }

ul.nav-menu li.menu + .nav-header {
  margin-top: 20px; }

ul.nav-menu li.menu .sub-menu {
  display: none; }

ul.nav-menu li.menu.open > .sub-menu {
  display: block; }

ul.nav-menu li.menu.open .menu.active > .sub-menu {
  display: block; }

ul.nav-menu li a {
  color: #a1a1a1;
  display: block;
  font-size: 14px;
  min-width: inherit !important;
  padding: 16px 20px;
  font-weight: 400;
  text-transform: capitalize;
  text-decoration: none;
  border-radius: 0; }

ul.nav-menu .nav-header {
  color: #a1a1a1;
  padding: 10px 20px;
  font-size: 11px;
  text-transform: uppercase; }
  ul.nav-menu .nav-header:first-child {
    margin-top: 10px; }

ul.nav-menu > li.open > a,
ul.nav-menu > li > a:hover,
ul.nav-menu > li > a:focus {
  background-color: #1d1d1d;
  color: #fff; }

ul.nav-menu li.menu > a {
  border-left: 2px solid transparent; }

ul.nav-menu li.menu.open > a {
  border-color: #FC9631; }

ul.nav-menu li.menu > a:focus:before,
ul.nav-menu li.menu > a:hover:before {
  color: #fff; }

ul.nav-menu li i {
  margin-right: 16px;
  font-size: 15px; }

ul.nav-menu li ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 1;
  background-color: #1d1d1d;
  overflow: hidden; }

ul.nav-menu li ul > li > a {
  padding-left: 56px; }

ul.nav-menu li ul > li > ul > li > a {
  padding-left: 76px; }

ul.nav-menu li ul > li > ul > li > ul > li > a {
  padding-left: 96px; }

ul.nav-menu li ul > li > ul > li > ul > li > ul > li > a {
  padding-left: 116px; }

ul.nav-menu li.menu > a:before {
  color: #a1a1a1;
  content: "\f2fb";
  display: inline-block;
  float: right;
  font-family: 'Material-Design-Iconic-Font';
  font-size: 15px;
  margin-left: 10px;
  position: relative;
  -webkit-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  font-weight: normal; }

ul.nav-menu li.menu.no-arrow > a:before {
  display: none; }

ul.nav-menu li.menu.open > a:before,
ul.nav-menu li.menu > a:focus:before,
ul.nav-menu li.menu li > a:hover:before {
  background-color: transparent;
  color: #fff; }

ul.nav-menu li.menu.open > a:before,
ul.nav-menu li.menu.active > a:before {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg); }

ul.nav-menu li.menu.open .sub-menu li.active > a {
  background-color: #1d1d1d;
  color: #fff; }

ul.nav-menu li.menu .sub-menu li > a:hover,
ul.nav-menu li.menu .sub-menu li > a:focus,
ul.nav-menu li.menu.open .sub-menu li.active > a:before,
ul.nav-menu li.menu .sub-menu li > a:focus:before,
ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #1d1d1d;
  color: #fff; }

.app-container.mini-drawer .side-nav {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: 80px; }
  .app-container.mini-drawer .side-nav .nav-menu li span.nav-text,
  .app-container.mini-drawer .side-nav .user-profile .user-detail,
  .app-container.mini-drawer .side-nav .customizer {
    display: none; }
  .app-container.mini-drawer .side-nav .nav-menu .menu.open > .sub-menu {
    display: none !important; }
  .app-container.mini-drawer .side-nav .nav-header {
    padding: 10px; }
  .app-container.mini-drawer .side-nav .nav-menu li {
    text-align: center; }
    .app-container.mini-drawer .side-nav .nav-menu li i {
      margin-right: 0; }
  .app-container.mini-drawer .side-nav ul.nav-menu li a {
    padding: 16px 20px; }
  .app-container.mini-drawer .side-nav ul.nav-menu ul.sub-menu li a {
    padding-left: 30px; }
  .app-container.mini-drawer .side-nav ul.nav-menu > li > a:before {
    display: none; }

.app-container.mini-drawer .side-nav:hover {
  width: 280px; }
  .app-container.mini-drawer .side-nav:hover .nav-menu li span.nav-text,
  .app-container.mini-drawer .side-nav:hover .user-profile .user-detail {
    display: inline-block !important; }
  .app-container.mini-drawer .side-nav:hover .nav-menu .menu.open > .sub-menu {
    display: block !important; }
  .app-container.mini-drawer .side-nav:hover .nav-header {
    padding: 10px 20px; }
  .app-container.mini-drawer .side-nav:hover .customizer {
    display: flex !important; }
  .app-container.mini-drawer .side-nav:hover ul.nav-menu li {
    text-align: left; }
    .app-container.mini-drawer .side-nav:hover ul.nav-menu li i {
      margin-right: 16px; }
    .app-container.mini-drawer .side-nav:hover ul.nav-menu li a {
      padding: 16px 20px; }
  .app-container.mini-drawer .side-nav:hover ul.nav-menu ul.sub-menu li a {
    padding-left: 56px; }
  .app-container.mini-drawer .side-nav:hover ul.nav-menu ul.sub-menu > li > ul > li > a {
    padding-left: 76px; }
  .app-container.mini-drawer .side-nav:hover ul.nav-menu ul.sub-menu > li > ul > li > ul > li > a {
    padding-left: 96px; }
  .app-container.mini-drawer .side-nav:hover ul.nav-menu ul.sub-menu > li > ul > li > ul > li > ul > li > a {
    padding-left: 116px; }
  .app-container.mini-drawer .side-nav:hover ul.nav-menu > li > a:before {
    display: block; }

.customizer {
  padding: 20px 35px; }
  .customizer img {
    padding: 5px;
    cursor: pointer; }

.user-info ul li {
  font-size: 15px; }

/*Header Styles*/
.jr-menu-icon {
  outline: none !important;
  -webkit-justify-content: flex-start !important;
  justify-content: flex-start !important;
  padding: 0 5px !important;
  margin-right: 15px; }

.app-main-header {
  background-color: #9b51e0 !important;
  color: #fff; }
  .app-main-header .search-bar input {
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    background-color: #00d37b;
    color: #fff; }
    .app-main-header .search-bar input + .search-icon {
      top: 0; }
      .app-main-header .search-bar input + .search-icon i {
        color: rgba(255, 255, 255, 0.8); }
    .app-main-header .search-bar input:focus {
      background-color: #fff;
      color: #3C4858; }
      .app-main-header .search-bar input:focus + .search-icon i {
        color: rgba(60, 72, 88, 0.8); }

.app-logo img {
  height: 40px; }

.app-toolbar {
  min-height: 70px;
  height: 70px;
  max-height: 70px; }
  .app-toolbar .search-bar {
    margin-left: 100px;
    margin-right: auto;
    max-width: 400px;
    width: 380px; }
    @media screen and (max-width: 991px) {
      .app-toolbar .search-bar {
        width: 280px; } }
    @media screen and (max-width: 767px) {
      .app-toolbar .search-bar {
        margin-left: 40px;
        width: 200px; } }

.app-toolbar .nav-searchbox .search-bar {
  margin-left: 0;
  width: 100%; }

.app-toolbar .nav-searchbox .search-bar input {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  background-color: #fff;
  color: #3C4858; }
  .app-toolbar .nav-searchbox .search-bar input + .search-icon i {
    color: rgba(60, 72, 88, 0.8); }
  .app-toolbar .nav-searchbox .search-bar input:focus {
    background-color: transparent;
    color: #3C4858; }
    .app-toolbar .nav-searchbox .search-bar input:focus + .search-icon i {
      color: rgba(60, 72, 88, 0.8); }

.header-notifications {
  margin-bottom: 0; }
  .header-notifications li {
    vertical-align: middle; }

.quick-menu .dropdown-menu {
  padding: 5px 10px;
  width: 350px; }
  .quick-menu .dropdown-menu .jr-card-header {
    margin: -5px -10px 5px;
    padding: 10px 10px 10px 20px;
    -webkit-box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
    -o-box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
    z-index: 10; }
  .quick-menu .dropdown-menu:after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #fff;
    top: -9px;
    content: "";
    height: 9px;
    position: absolute;
    right: 8px;
    width: 10px; }
  .quick-menu .dropdown-menu:before {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #000;
    top: -10px;
    content: "";
    height: 10px;
    position: absolute;
    right: 8px;
    width: 10px; }
  @media screen and (max-width: 449px) {
    .quick-menu .dropdown-menu {
      width: 280px; } }
  @media screen and (max-width: 359px) {
    .quick-menu .dropdown-menu {
      width: 240px; } }

.quick-menu .messages-list .user-avatar {
  position: relative; }

.quick-menu .messages-list .badge {
  position: absolute;
  left: -4px;
  top: -4px;
  height: 16px;
  width: 16px;
  line-height: 16px;
  text-align: center;
  padding: 0; }

@media screen and (max-width: 575px) {
  .nav-searchbox.quick-menu .dropdown-menu {
    width: 200px; } }

@media screen and (max-width: 399px) {
  .nav-searchbox.quick-menu .dropdown-menu {
    width: 100px; } }

.messages-list.language-list ul li {
  padding: 7px 6px 6px; }

.messages-list ul li {
  position: relative;
  padding: 20px 10px 14px;
  border-bottom: 1px solid #dee2e6; }
  .messages-list ul li .user-name a {
    color: #00b96c; }
  .messages-list ul li .sub-heading {
    font-size: 11px;
    margin-bottom: 6px !important; }
    .messages-list ul li .sub-heading.mb-0 {
      margin-bottom: 0 !important; }
  .messages-list ul li:last-child {
    border-bottom: 0 none; }
  .messages-list ul li .jr-btn {
    margin-right: 0 !important; }
  .messages-list ul li .jr-btn-xs {
    font-size: 11px !important;
    padding: 5px !important; }

.quick-menu .icon-btn {
  color: #fff; }

.quick-menu .icon-alert {
  position: relative; }
  .quick-menu .icon-alert:after {
    display: block;
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    z-index: 10;
    width: 8px;
    height: 8px;
    background-color: #F55859;
    border-radius: 50%; }

.quick-menu i.animated.infinite {
  -moz-animation-iteration-count: 25;
  -webkit-animation-iteration-count: 25;
  -o-animation-iteration-count: 25;
  animation-iteration-count: 25; }

.quick-menu .dropdown-menu {
  display: block;
  visibility: hidden;
  left: auto !important;
  right: 0 !important;
  opacity: 0;
  transform: translate3d(0px, 100px, 0px) !important;
  -webkit-transition: all 200ms linear 0ms;
  -o-transition: all 200ms linear 0ms;
  transition: all 200ms linear 0ms; }

.quick-menu.show .dropdown-menu {
  opacity: 1;
  transform: translate3d(0px, 30px, 0px) !important;
  visibility: visible; }

/*Main Content Styles*/
.app-main-content {
  flex: 1;
  width: 100%; }

.app-wrapper {
  padding: 24px;
  width: 100%; }
  @media screen and (max-width: 575px) {
    .app-wrapper {
      padding: 20px; } }

/* In APP footer */
.app-footer {
  padding: 0 24px;
  -webkit-box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.15);
  -o-box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.15);
  background-color: #f8f9fa;
  color: #a1a1a1;
  line-height: 50px;
  min-height: 50px;
  height: 50px;
  max-height: 50px; }
  .app-footer a {
    color: #a1a1a1; }
    .app-footer a:hover {
      color: #fff; }
  @media screen and (max-width: 400px) {
    .app-footer {
      font-size: 12px;
      padding: 0 20px; } }

/* END In APP footer */
/* VER 1.0 LP Footer */
footer {
  background-color: white;
  border-top: 1px solid #E0E6ED;
  padding: 2rem 1rem;
  color: #3c4858; }
  footer .footer__header {
    font-size: 1.2rem;
    font-weight: 400;
    color: #828282; }
    @media screen and (max-width: 767px) {
      footer .footer__header {
        font-size: 1rem; } }
  footer .footer__info {
    font-size: 1.2rem;
    font-weight: 300;
    color: #828282; }
    @media screen and (max-width: 767px) {
      footer .footer__info {
        font-size: 1rem; } }
    footer .footer__info a {
      color: #828282; }
      footer .footer__info a:hover {
        text-decoration: none;
        color: #7e0fa9; }
  footer .footer__link {
    color: #828282;
    font-size: 1rem;
    font-weight: 300; }
    @media screen and (max-width: 767px) {
      footer .footer__link {
        font-size: 0.8rem; } }
    footer .footer__link:hover {
      text-decoration: none;
      color: #7e0fa9; }
  footer .footer__copyright {
    font-size: 1rem;
    color: #828282;
    font-weight: 300; }
    @media screen and (max-width: 767px) {
      footer .footer__copyright {
        font-size: 0.8rem; } }

/* END VER 1.0 LP Footer */
/*Right Sidebar Styles*/
.app-sidebar-content.right-sidebar {
  z-index: 1501 !important; }

.color-theme {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  max-width: 300px;
  height: 100%; }
  @media screen and (max-width: 499px) {
    .color-theme {
      max-width: 250px; } }

.color-theme-header {
  background-color: #00b96c;
  color: #fff;
  padding: 16px 25px;
  text-transform: uppercase;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between; }
  .color-theme-header .color-theme-title {
    margin-bottom: 0; }
  @media screen and (max-width: 499px) {
    .color-theme-header {
      padding: 16px 15px; } }

.color-theme-body {
  padding: 28px 25px; }
  .color-theme-body h3 {
    margin-bottom: 20px; }
  @media screen and (max-width: 499px) {
    .color-theme-body {
      padding: 25px 15px; } }

.color-option {
  list-style: none;
  padding-left: 0;
  margin: 0 -7px 10px; }
  .color-option li {
    position: relative;
    display: inline-block;
    padding: 0 7px;
    margin-bottom: 10px; }
  .color-option li a {
    height: 45px;
    width: 45px;
    line-height: 45px;
    display: inline-block;
    color: transparent !important;
    position: relative; }
    .color-option li a:before {
      content: "\f26b";
      font-family: "Material-Design-Iconic-Font";
      font-size: 20px;
      height: 45px;
      width: 45px;
      line-height: 45px;
      position: absolute;
      top: 0;
      left: 0;
      color: #fff;
      text-align: center;
      display: none;
      z-index: 2; }
  .color-option li a.active:before {
    display: block; }

.cr-op-dark-sidebar li a:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background-color: #3C4858;
  width: 8px; }

.theme-option {
  position: fixed;
  top: 30%;
  right: 0;
  z-index: 1500;
  background-color: #9C27B0;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  -webkit-box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  -o-box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.5);
  color: #fff; }

@media screen and (max-width: 499px) {
  .color-theme .jr-btn {
    padding: 10px 15px !important; } }

/* UI Components
   ========================================================================== */
/*Alert Styles*/
.alert {
  border: 0 none; }

.alert {
  padding: 20px 24px;
  border-radius: 2px;
  position: relative; }
  .alert .close {
    line-height: inherit;
    font-weight: 300; }
  .alert .alert-addon {
    width: 60px;
    background-color: rgba(0, 0, 0, 0.2);
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1; }

.alert-dismissible {
  padding-right: 60px; }

.alert-addon-card {
  padding-left: 80px; }

/*Avatar Styles*/
.user-avatar {
  margin-right: 8px; }
  .user-avatar img {
    max-width: 100%;
    height: auto; }

.user-detail .user-name {
  margin-bottom: 2px;
  font-weight: 400;
  text-transform: capitalize; }

.user-detail .user-description {
  font-size: 13px;
  margin-bottom: 0;
  color: #6c757d; }

/*Badges Styles*/
.badge {
  font-weight: 400;
  padding: 3px 8px;
  line-height: inherit;
  margin-bottom: 6px;
  border-radius: 2px; }
  .badge:not(:last-child) {
    margin-right: 15px; }
  table .badge {
    margin-bottom: 0; }

.badge-pill {
  border-radius: 20px; }

.badge-border {
  border: 2px solid !important; }

/*Breadcrumb Styles*/
.breadcrumb {
  border-radius: 2px; }

.breadcrumb-item + .breadcrumb-item::before {
  content: '\F2FB';
  font-family: 'Material-Design-Iconic-Font';
  font-size: 16px;
  line-height: 1;
  vertical-align: middle; }

/*Button Styles*/
button {
  outline: 0 none !important;
  cursor: pointer; }

.btn, .jr-btn {
  position: relative;
  min-width: 10px !important;
  min-height: 10px !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
  line-height: 1.2 !important;
  border-radius: 2px !important;
  cursor: pointer; }

.btn:focus, .btn:active {
  outline: 0 none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  -o-box-shadow: none !important;
  box-shadow: none !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.jr-btn,
.jr-fab-btn,
.btn {
  margin-bottom: 6px; }
  .jr-btn:not(:last-child),
  .jr-fab-btn:not(:last-child),
  .btn:not(:last-child) {
    margin-right: 15px; }

.jr-fab-btn {
  min-height: 10px !important; }

.jr-btn {
  padding: 12px 20px !important; }
  .jr-btn i + span,
  .jr-btn span + i {
    margin-left: 6px; }

.jr-btn-group {
  margin: 0 -10px; }
  .jr-btn-group:not(:last-child) {
    margin-bottom: 16px; }
  .jr-btn-group .jr-btn,
  .jr-btn-group .jr-fab-btn {
    margin: 0 10px 18px; }

.jr-btn-rounded {
  border-radius: 50px !important; }

.jr-btn-lg, .btn-group-lg > .jr-btn {
  padding: 16px 24px !important; }

.jr-btn-sm, .btn-group-sm > .jr-btn {
  padding: 8px 15px !important; }

.jr-btn-xs {
  padding: 6px 10px !important; }

.jr-btn-fab-lg, .jr-btn-lg, .btn-group-lg > .jr-btn {
  font-size: 17px !important; }
  .jr-btn-fab-lg i,
  .jr-btn-lg i,
  .btn-group-lg > .jr-btn i {
    font-size: 19px !important; }

.jr-btn-fab-sm, .jr-btn-sm, .btn-group-sm > .jr-btn {
  font-size: 13px !important; }
  .jr-btn-fab-sm i,
  .jr-btn-sm i,
  .btn-group-sm > .jr-btn i {
    font-size: 13px !important; }

.jr-btn-fab-xs,
.jr-btn-xs {
  font-size: 10px !important; }
  .jr-btn-fab-xs i,
  .jr-btn-xs i {
    font-size: 10px !important; }

.complex-btn-wrapper {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  min-width: 200px;
  width: 100%; }

.complex-btn {
  position: relative;
  height: 200px;
  overflow: hidden; }
  @media screen and (max-width: 767px) {
    .complex-btn {
      width: 100% !important;
      height: 100px !important; } }
  .complex-btn:hover {
    z-index: 1; }
    .complex-btn:hover .img-btn-overlay {
      opacity: 0.15; }
    .complex-btn:hover .img-marked {
      opacity: 0; }
    .complex-btn:hover .img-title {
      border: 4px solid currentColor; }
  .complex-btn .img-btn {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    color: #fff; }
  .complex-btn .img-src {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-position: center 40%; }
  .complex-btn .img-btn-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #000;
    opacity: 0.4;
    -webkit-transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    -o-transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1); }
  .complex-btn .img-title {
    position: relative;
    padding: 16px 32px 14px; }
  .complex-btn .img-marked {
    height: 3px;
    width: 18px;
    background: #fff;
    position: absolute;
    bottom: -2px;
    left: calc(50% - 9px);
    -webkit-transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    -o-transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1); }

.btn-group-mins {
  margin: 0 -5px; }
  .btn-group-mins .btn,
  .btn-group-mins .jr-btn {
    margin: 0 5px 5px; }

.jr-btn.jr-btn-label i {
  background-color: rgba(255, 255, 255, 0.2);
  width: 40px;
  height: 100%;
  line-height: 42px; }

.jr-btn.jr-btn-label.left i {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1; }

.jr-btn.jr-btn-label.right i {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1; }

.jr-btn.jr-btn-label.left i + span {
  margin-left: 30px; }

.jr-btn.jr-btn-label.right i + span {
  margin-right: 30px;
  margin-left: 0; }

.jr-btn.jr-btn-label.jr-btn-lg i, .btn-group-lg > .jr-btn.jr-btn-label i {
  line-height: 54px; }

.jr-btn.jr-btn-label.jr-btn-sm i, .btn-group-sm > .jr-btn.jr-btn-label i {
  line-height: 32px; }

.jr-btn.jr-btn-label.jr-btn-xs i {
  line-height: 24px;
  width: 30px; }

/*Button Group Styles*/
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .jr-btn,
  .btn-group-vertical > .jr-btn {
    position: relative;
    flex: 0 1 auto; }
    .btn-group > .jr-btn:hover,
    .btn-group-vertical > .jr-btn:hover {
      z-index: 2; }
    .btn-group > .jr-btn:focus, .btn-group > .jr-btn:active, .btn-group > .jr-btn.active,
    .btn-group-vertical > .jr-btn:focus,
    .btn-group-vertical > .jr-btn:active,
    .btn-group-vertical > .jr-btn.active {
      z-index: 2; }
  .btn-group .jr-btn + .jr-btn,
  .btn-group .jr-btn + .jr-btn-group,
  .btn-group .btn-group + .jr-btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .jr-btn + .jr-btn,
  .btn-group-vertical .jr-btn + .jr-btn-group,
  .btn-group-vertical .btn-group + .jr-btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .jr-btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0 !important; }

.btn-group > .jr-btn:first-child {
  margin-left: 0; }
  .btn-group > .jr-btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important; }

.btn-group > .jr-btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.btn-group > .btn-group {
  float: left; }

.btn-group > .btn-group:not(:first-child):not(:last-child) > .jr-btn {
  border-radius: 0 !important; }

.btn-group > .btn-group:first-child:not(:last-child) > .jr-btn:last-child,
.btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important; }

.btn-group > .btn-group:last-child:not(:first-child) > .jr-btn:first-child {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.jr-btn + .dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .jr-btn + .dropdown-toggle-split::after {
    margin-left: 0; }

.jr-btn-sm + .dropdown-toggle-split, .btn-group-sm > .jr-btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.jr-btn-lg + .dropdown-toggle-split, .btn-group-lg > .jr-btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group.show .dropdown-toggle {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  -moz-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  -o-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .btn-group.show .dropdown-toggle.btn-link {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .jr-btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .jr-btn + .jr-btn,
  .btn-group-vertical > .jr-btn + .btn-group,
  .btn-group-vertical > .btn-group + .jr-btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }
  .btn-group-vertical > .jr-btn:not(:first-child):not(:last-child) {
    border-radius: 0 !important; }
  .btn-group-vertical > .jr-btn:first-child:not(:last-child) {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important; }
  .btn-group-vertical > .jr-btn:last-child:not(:first-child) {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important; }
  .btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .jr-btn {
    border-radius: 0 !important; }
  .btn-group-vertical > .btn-group:first-child:not(:last-child) > .jr-btn:last-child,
  .btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important; }
  .btn-group-vertical > .btn-group:last-child:not(:first-child) > .jr-btn:first-child {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important; }

[data-toggle="buttons"] > .jr-btn input[type="radio"],
[data-toggle="buttons"] > .jr-btn input[type="checkbox"],
[data-toggle="buttons"] > .btn-group > .jr-btn input[type="radio"],
[data-toggle="buttons"] > .btn-group > .jr-btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0); }

.btn-group > .jr-btn,
.btn-group > .btn,
.btn-group-vertical > .jr-btn,
.btn-group-vertical > .btn {
  margin: 0 !important; }

/*Cards Styles*/
.profile-intro {
  background-color: #fff;
  border-radius: 2px !important;
  overflow: hidden; }
  .profile-intro:hover {
    -webkit-box-shadow: 0 1px 8px -1px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 1px 8px -1px rgba(0, 0, 0, 0.3);
    -o-box-shadow: 0 1px 8px -1px rgba(0, 0, 0, 0.3);
    box-shadow: 0 1px 8px -1px rgba(0, 0, 0, 0.3); }
    .profile-intro:hover .avatar-circle {
      -webkit-transition: border-radius 0.4s ease 0.1s;
      -o-transition: border-radius 0.4s ease 0.1s;
      transition: border-radius 0.4s ease 0.1s;
      border-radius: 25% !important; }
  .profile-intro img.avatar-circle {
    display: inline-block;
    border-radius: 50% !important;
    -webkit-box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.2);
    -o-box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.2);
    position: relative;
    margin: 0 auto;
    -webkit-transform: translateY(50px);
    -ms-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    z-index: 1; }

.profile-intro .card-image {
  position: relative;
  width: 100%;
  min-height: 100%;
  background-image: url("http://via.placeholder.com/1920x1000");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }

.profile-intro .pi-content {
  padding: 70px 40px 24px !important; }

.profile-intro .pi-footer {
  padding: 0 40px 24px !important; }

.profile-intro .icon {
  display: inline-block;
  -webkit-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  transition: all 0.2s ease 0s;
  color: #00b96c; }
  .profile-intro .icon:hover, .profile-intro .icon:focus, .profile-intro .icon:active {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2); }

.profile-intro .icon:not(:last-child) {
  margin-right: 10px; }

.layer:before {
  display: block;
  content: "";
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 0; }

.jr-card > .jr-card-body:first-child {
  margin-top: 6px; }

.jr-card.p-0 > .jr-card-body:first-child {
  margin-top: 0; }

.jr-card .jr-card-thumb {
  margin: -18px -24px 0; }
  .jr-card .jr-card-thumb .card-img {
    border-radius: 2px 2px 0 0; }

@media screen and (max-width: 575px) {
  .jr-card {
    margin-bottom: 18px; } }

.jr-card-header {
  margin-bottom: 24px; }
  .jr-card-header .card-heading {
    margin-bottom: 4px; }
  .jr-card-header .badge {
    padding: 2px 5px; }
    .jr-card-header .badge:last-child {
      margin-left: 10px; }
  @media screen and (max-width: 575px) {
    .jr-card-header {
      margin-bottom: 18px; } }

.jr-entry-header {
  margin-bottom: 24px; }
  .jr-entry-header .entry-heading {
    margin-bottom: 4px; }
  .jr-entry-header .entry-description {
    margin-bottom: 0;
    font-size: 13px;
    color: #6c757d; }
  @media screen and (max-width: 575px) {
    .jr-entry-header {
      margin-bottom: 18px; } }

.card {
  margin-bottom: 24px;
  border-radius: 2px; }
  @media screen and (max-width: 575px) {
    .card {
      margin-bottom: 18px; } }

.card-header {
  font-size: 16px; }

.card-title {
  font-size: 18px;
  text-transform: capitalize; }
  @media screen and (max-width: 991px) {
    .card-title {
      margin-bottom: 10px; } }

.product-item-vertical .card-footer {
  padding-right: 0;
  padding-left: 0; }

.card-subtitle {
  font-size: 14px;
  color: #495057; }
  .card-subtitle:not(:last-child) {
    margin-bottom: 18px; }

.card-mt-footer {
  padding: 0 10px 14px;
  margin-top: -10px; }

.jr-cart-ab {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  color: #fff;
  font-size: 18px; }
  .jr-cart-ab i {
    vertical-align: middle;
    font-size: 25px; }

.img-overlay-card {
  border-radius: 2px;
  margin-bottom: 24px; }
  @media screen and (max-width: 1199px) {
    .img-overlay-card .center-crop-img {
      min-height: 135px; } }
  @media screen and (max-width: 575px) {
    .img-overlay-card {
      margin-bottom: 18px; } }

/*Chips Styles*/
.chip-outline {
  background-color: transparent !important;
  border: 1px solid #adb5bd !important; }

/*Drawer Styles*/
.drawer {
  width: 250px; }

.full-drawer {
  width: 100%; }

/*Grids List Styles*/
.gl-image,
.gl-list,
.gl-advanced,
.gl-single-line {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  overflow: hidden; }

.gl {
  max-width: 100%;
  max-height: 360px;
  transform: translateZ(0); }

.gl-single-line .slg {
  -ms-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  max-width: 100%; }

.title-gradient {
  background: -webkit-linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0) 100%) !important;
  background: -o-linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0) 100%) !important;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0) 100%) !important; }

.title-gradient-bottom {
  background: -webkit-linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0) 100%) !important;
  background: -o-linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0) 100%) !important;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0) 100%) !important; }

/*Icons Styles*/
.icon {
  display: inline-block;
  text-align: center; }

.icons-wrapper {
  display: inline-block; }
  .icons-wrapper + .icons-wrapper {
    margin-left: 25px; }

/*Label Styles*/
label {
  margin-bottom: 0; }

.nav-link {
  cursor: pointer; }

.card-header.bg-primary .nav-link {
  color: #fff; }
  .card-header.bg-primary .nav-link.active, .card-header.bg-primary .nav-link:hover, .card-header.bg-primary .nav-link:focus {
    color: #000;
    background-color: #fff; }
  .card-header.bg-primary .nav-link.disabled {
    color: #f8f9fa; }
  .card-header.bg-primary .nav-link.disabled:hover {
    color: #212529; }

/* LP Nav bar */
.navbar {
  font-family: "Libre Franklin", sans-serif;
  height: 70px;
  background-color: white;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.09), inset 0 -1px 0 rgba(0, 0, 0, 0.1);
  justify-content: space-between; }
  @media screen and (max-width: 767px) {
    .navbar {
      justify-content: start;
      padding: 0.5rem; } }
  @media screen and (max-width: 387px) {
    .navbar {
      padding-left: 0;
      padding-right: 5px; } }

@media (max-width: 767px) {
  .navbar-toolbar {
    display: none !important; } }

@media (min-width: 768px) {
  .navbar-mobile-navmenu {
    /*display: none !important;*/ } }

/*.navbar-menubutton {
  outline: none;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0) !important;
  color: black;
  font-weight: 400 !important;
  &:hover {
    color: $link-hover-color;
    transform: translateY(-1px);
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0) !important;
    font-weight: 400 !important;
    box-shadow: none;
  }
}*/
.navbar-brand {
  margin-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 15px;
  padding-right: 40px;
  align-self: center; }
  @media screen and (max-width: 767px) {
    .navbar-brand {
      padding-left: 0;
      padding-right: 0; } }

.navbar-subtitle {
  display: inline-block;
  width: 100%;
  font-size: 15px;
  vertical-align: middle;
  padding: 0.5rem 1rem 0.8rem 1rem;
  color: #999;
  text-transform: uppercase;
  border-bottom: 1px solid #eaeaea; }

.navbar-mobile-title {
  display: inline-block;
  width: 100%;
  padding: 1rem 2rem 1rem 2rem;
  vertical-align: middle;
  font-size: 15px;
  font-weight: 500; }

.navbar-link, .navbar-toplink, .navbar-link-mobile, .navbar-menu-toplink-mobile {
  display: inline-block;
  font-size: 15px;
  color: #3C4858;
  vertical-align: middle;
  margin: 0.2rem 0.8rem;
  padding: 5px 0; }
  .navbar-link:hover, .navbar-toplink:hover, .navbar-link-mobile:hover, .navbar-menu-toplink-mobile:hover {
    color: #00864e;
    transform: translateY(-1px);
    text-decoration: none; }
  @media screen and (max-width: 935px) {
    .navbar-link, .navbar-toplink, .navbar-link-mobile, .navbar-menu-toplink-mobile {
      margin-right: 1rem; } }
  @media screen and (max-width: 855px) {
    .navbar-link, .navbar-toplink, .navbar-link-mobile, .navbar-menu-toplink-mobile {
      font-size: 15px;
      margin-right: 0.7rem; } }
  @media screen and (max-width: 767px) {
    .navbar-link, .navbar-toplink, .navbar-link-mobile, .navbar-menu-toplink-mobile {
      padding: 0.5rem 0.3rem !important; } }
  @media screen and (max-width: 387px) {
    .navbar-link, .navbar-toplink, .navbar-link-mobile, .navbar-menu-toplink-mobile {
      margin-left: 1rem !important; } }

.navbar-toplink {
  color: #3C4858;
  font-family: "Libre Franklin", sans-serif; }
  .navbar-toplink:hover {
    color: #00864e;
    text-decoration: none;
    transform: translateY(-1px);
    cursor: pointer; }

.navbar-toplink.is_active {
  color: #00b96c; }

.navbar-link-mobile, .navbar-menu-toplink-mobile {
  width: 100%;
  color: #3C4858; }

.navbar-menu-toplink-mobile {
  color: #3C4858; }

.navbar-dropdown ul {
  padding: 0; }
  .navbar-dropdown ul a:first-of-type {
    padding-top: 10px; }

.navbar-classes-menu-column, .navbar-classes-menu-column-mobile {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  width: 190px; }

.navbar-classes-menu-column-mobile {
  width: 50%;
  border-right: 1px solid #eaeaea;
  padding-bottom: 0; }

.navbar-explore-all-container, .navbar-explore-all-container-mobile {
  background-color: #eaeaea;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  padding: 0 10px; }
  .navbar-explore-all-container:focus, .navbar-explore-all-container-mobile:focus {
    outline: none; }

.navbar-explore-all-container-mobile {
  width: 100%;
  border: none; }

.navbar-classes-menu-ad, .navbar-classes-menu-ad-mobile {
  padding: 1rem;
  background-size: cover;
  text-align: center;
  color: white;
  font-size: 18px;
  font-family: 'Libre Franklin', sans-serif; }

.navbar-classes-menu-ad-mobile {
  display: flex;
  align-items: center;
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }

.navbar-link--active {
  color: #00b96c; }

/* END LP Nav bar */
.link-tag {
  color: white;
  background-color: #00b96c;
  font-size: 11px;
  height: 16px;
  padding: 0 5px;
  margin-left: 3px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  text-transform: lowercase; }

.link-tag-secondary {
  color: black;
  background-color: #ffaa33;
  font-size: 9px;
  height: 16px;
  padding: 2px 5px;
  margin-left: 3px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  text-transform: uppercase; }

.link-tag--header {
  color: white;
  background-color: #00b96c;
  font-size: 15px;
  padding: 0 8px;
  margin-left: 3px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  text-transform: lowercase;
  position: absolute;
  top: -3px;
  right: calc(50% - 220px); }
  @media screen and (max-width: 577px) {
    .link-tag--header {
      top: -4px;
      right: calc(50% - 149px); } }

/*Popover Styles*/
.popover {
  z-index: 1510; }

.popover-header {
  background-color: transparent;
  border-bottom: 0 none;
  color: #000;
  font-size: 24px;
  padding: 15px 20px 0; }

.popover-body {
  padding: 15px 20px; }

/*Tables Styles*/
.table-header {
  padding-right: 2px !important; }
  .table-header.highlight-light {
    color: #616161;
    background-color: #e0e0e0; }
  .table-header.highlight-dark {
    color: #fff;
    background-color: #616161; }

.spacer {
  -webkit-flex: 1 1 100%;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%; }

.actions {
  color: #F44336; }

.title {
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto; }

.table-hover tbody tr:hover {
  background-color: rgba(0, 185, 108, 0.075); }

.table-material {
  width: 100% !important;
  max-width: 100% !important;
  background-color: transparent !important; }
  .table-material th,
  .table-material td {
    padding: 0.75rem !important;
    vertical-align: top !important;
    border-top: 1px solid #dee2e6 !important; }
  .table-material thead th {
    vertical-align: bottom !important;
    border-bottom: 2px solid #dee2e6 !important; }
  .table-material tbody + tbody {
    border-top: 2px solid #dee2e6 !important; }
  .table-material .table-material {
    background-color: #f8f9fa !important; }

.d-flex-stretch {
  -webkit-align-items: stretch;
  align-items: stretch;
  display: -webkit-flex;
  display: -webkit-box;
  display: -moz-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.default-table {
  margin-bottom: 0; }
  .default-table thead th {
    font-weight: 400;
    font-size: 16px; }
  .default-table td {
    vertical-align: middle; }

.table-unbordered th,
.table-unbordered td {
  border: 0 none !important; }

.table-unbordered .th-border-b th {
  border-bottom: 2px solid #ced4da !important; }

.table-responsive-material {
  min-height: .01%;
  overflow-x: auto; }

@media screen and (max-width: 767px) {
  .table-responsive-material {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd; }
  .table-responsive-material > .table {
    margin-bottom: 0; }
  .table-responsive-material > .table > thead > tr > th,
  .table-responsive-material > .table > tbody > tr > th,
  .table-responsive-material > .table > tfoot > tr > th,
  .table-responsive-material > .table > thead > tr > td,
  .table-responsive-material > .table > tbody > tr > td,
  .table-responsive-material > .table > tfoot > tr > td {
    white-space: nowrap; }
  .table-responsive-material > .table-bordered {
    border: 0; }
  .table-responsive-material > .table-bordered > thead > tr > th:first-child,
  .table-responsive-material > .table-bordered > tbody > tr > th:first-child,
  .table-responsive-material > .table-bordered > tfoot > tr > th:first-child,
  .table-responsive-material > .table-bordered > thead > tr > td:first-child,
  .table-responsive-material > .table-bordered > tbody > tr > td:first-child,
  .table-responsive-material > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0; }
  .table-responsive-material > .table-bordered > thead > tr > th:last-child,
  .table-responsive-material > .table-bordered > tbody > tr > th:last-child,
  .table-responsive-material > .table-bordered > tfoot > tr > th:last-child,
  .table-responsive-material > .table-bordered > thead > tr > td:last-child,
  .table-responsive-material > .table-bordered > tbody > tr > td:last-child,
  .table-responsive-material > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0; }
  .table-responsive-material > .table-bordered > tbody > tr:last-child > th,
  .table-responsive-material > .table-bordered > tfoot > tr:last-child > th,
  .table-responsive-material > .table-bordered > tbody > tr:last-child > td,
  .table-responsive-material > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0; } }

/*Tabs Styles*/
.tab {
  min-width: 10px !important; }

.tab-icon > span {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

/*Time Lines Style*/
.timeline-left {
  float: left;
  margin-right: 20px; }

.timeline-right {
  float: right;
  margin-left: 20px; }

.timeline-circle {
  border-radius: 50%; }

.timeline-section {
  margin-bottom: 25px; }

.timeline-item {
  padding-bottom: 25px;
  padding-left: 80px;
  position: relative;
  /*&:first-child:before {
    border-left-style: dashed;
  }
  &:last-child:before {
    border-left-style: dashed;
    bottom: 25px;
  }*/ }
  .timeline-item:before {
    border-left: solid 2px #dee2e6;
    content: "";
    left: 25px;
    position: absolute;
    top: 25px;
    bottom: -20px;
    width: 2px;
    z-index: 1; }

.timeline-badge {
  background-color: #dee2e6;
  height: 50px;
  width: 50px;
  line-height: 50px;
  position: absolute;
  left: 0;
  top: 16px;
  z-index: 2;
  font-size: 16px;
  color: #fff;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%; }
  .timeline-badge.timeline-img {
    background-color: transparent; }
  .timeline-badge svg {
    vertical-align: calc(100% - 56px); }

.timeline-img > img,
.timeline-header-img > img {
  display: inline-block;
  max-width: 100%;
  height: auto; }

.timeline-panel {
  padding: 20px 30px;
  position: relative;
  background-color: #fff;
  border-radius: 6px;
  -webkit-box-shadow: 0 1px 8px -1px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 1px 8px -1px rgba(0, 0, 0, 0.3);
  -o-box-shadow: 0 1px 8px -1px rgba(0, 0, 0, 0.3);
  box-shadow: 0 1px 8px -1px rgba(0, 0, 0, 0.3); }
  .timeline-panel:before, .timeline-panel:after {
    content: "";
    position: absolute;
    border-style: solid;
    display: inline-block; }
  .timeline-panel:before {
    border-color: transparent #dee2e6;
    border-width: 15px 15px 15px 0;
    left: -15px;
    top: 27px; }
  .timeline-panel:after {
    border-color: transparent #fff;
    border-width: 14px 14px 14px 0;
    left: -14px;
    top: 27px; }

.timeline-no-padding {
  padding: 0; }

.timeline-no-shadow {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none; }
  .timeline-no-shadow:before, .timeline-no-shadow:after {
    display: none; }

.timeline-panel-header, .timeline-header-img {
  margin-bottom: 10px; }

.timeline-inverted .timeline-left {
  float: right;
  margin-right: 0;
  margin-left: 20px; }

.timeline-heading {
  overflow: hidden;
  margin-bottom: 10px; }

.timeline-body {
  clear: both; }

/*Gxtl Center Style*/
.timeline-center .timeline-item {
  clear: both;
  float: right;
  width: 50%;
  padding-left: 55px; }
  .timeline-center .timeline-item:before {
    left: 0; }

.timeline-center .timeline-badge {
  left: -25px;
  right: auto; }

.timeline-center .timeline-time {
  position: absolute;
  top: 32px;
  left: -150px;
  right: auto;
  z-index: 2; }

.timeline-center .timeline-inverted {
  float: left;
  text-align: right;
  padding-left: 0;
  padding-right: 55px; }
  .timeline-center .timeline-inverted:before {
    border-left: 0 none;
    border-right: solid 2px #dee2e6;
    left: auto;
    right: -2px; }
  .timeline-center .timeline-inverted .timeline-badge {
    left: auto;
    right: -25px; }
  .timeline-center .timeline-inverted .timeline-panel:before {
    border-width: 15px 0 15px 15px;
    left: auto;
    right: -15px; }
  .timeline-center .timeline-inverted .timeline-panel:after {
    border-width: 14px 0 14px 14px;
    left: auto;
    right: -14px; }
  .timeline-center .timeline-inverted .timeline-time {
    position: absolute;
    top: 32px;
    right: -150px;
    left: auto;
    z-index: 2; }

/*Gxtl Zigzag Style*/
.timeline-zigzag .timeline-item {
  padding-left: 150px; }
  .timeline-zigzag .timeline-item + .timeline-item {
    margin-top: -80px; }

.timeline-zigzag .timeline-img {
  width: 90px;
  height: 90px;
  left: 5px; }
  .timeline-zigzag .timeline-img > img {
    width: 100%; }

.timeline-zigzag .timeline-inverted .timeline-img {
  right: 5px !important; }

.timeline-zigzag .timeline-item:before {
  -webkit-transform: rotate(35deg);
  -ms-transform: rotate(35deg);
  -o-transform: rotate(35deg);
  transform: rotate(35deg);
  top: 20px;
  bottom: 20px; }

.timeline-zigzag .timeline-inverted {
  padding-left: 0;
  padding-right: 150px; }
  .timeline-zigzag .timeline-inverted:before {
    -webkit-transform: rotate(-35deg);
    -ms-transform: rotate(-35deg);
    -o-transform: rotate(-35deg);
    transform: rotate(-35deg); }

.timeline-zigzag .timeline-item:first-child:before {
  border-left-style: solid; }

.timeline-zigzag .timeline-item:last-child:before {
  display: none; }

.timeline-item:last-child:before {
  bottom: 50px; }

@media screen and (max-width: 991px) {
  .timeline-zigzag .timeline-item + .timeline-item {
    margin-top: -60px; }
  .timeline-zigzag .timeline-item:before {
    bottom: 0; }
  .timeline-zigzag .timeline-item {
    padding-left: 120px; }
  .timeline-zigzag .timeline-inverted {
    padding-right: 120px;
    padding-left: 0; } }

@media screen and (max-width: 767px) {
  .timeline-center .timeline-item {
    float: none;
    padding-left: 80px;
    width: auto; }
    .timeline-center .timeline-item:before {
      left: 25px; }
  .timeline-center .timeline-badge {
    left: 0; }
  .timeline-center .timeline-inverted {
    float: none;
    text-align: left;
    padding-right: 0; }
    .timeline-center .timeline-inverted:before {
      border-left: solid 2px #dee2e6;
      border-right: 0 none;
      left: 24px;
      right: auto; }
    .timeline-center .timeline-inverted .timeline-badge {
      right: auto;
      left: 0; }
    .timeline-center .timeline-inverted .timeline-panel:before {
      border-width: 15px 15px 15px 0;
      left: -15px;
      right: auto; }
    .timeline-center .timeline-inverted .timeline-panel:after {
      border-width: 14px 14px 14px 0;
      left: -14px;
      right: auto; }
  .timeline-inverted .timeline-panel-header {
    float: none; }
  .timeline-inverted .timeline-left {
    float: left;
    margin-left: 0;
    margin-right: 20px; }
  .timeline-zigzag .timeline-panel {
    max-height: none;
    min-height: 10px;
    overflow-y: visible; }
  .timeline-zigzag .timeline-item {
    padding-left: 100px; }
    .timeline-zigzag .timeline-item + .timeline-item {
      margin-top: 0; }
    .timeline-zigzag .timeline-item:before {
      transform: none;
      top: 25px;
      bottom: -25px;
      left: 45px; }
    .timeline-zigzag .timeline-item:last-child:before {
      bottom: 0; }
  .timeline-zigzag .timeline-inverted:before {
    transform: none; }
  .timeline-center .timeline-time,
  .timeline-center .timeline-inverted .timeline-time {
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    margin-bottom: 6px; }
  .timeline-center .timeline-time-item .timeline-panel::before {
    top: 10px; }
  .timeline-center .timeline-time-item .timeline-panel::after {
    top: 11px; } }

@media screen and (max-width: 399px) {
  .timeline-left {
    float: none;
    margin-right: 0; }
  .timeline-right {
    float: none;
    margin-left: 0; }
  .timeline-inverted .timeline-left {
    float: none;
    margin-right: 0; } }

/*Input Styles*/
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 12px; }

::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 12px; }

:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 12px; }

:-moz-placeholder {
  /* Firefox 18- */
  font-size: 12px; }

.module-box-header ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 14px; }

.module-box-header ::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 14px; }

.module-box-header :-ms-input-placeholder {
  /* IE 10+ */
  font-size: 14px; }

.module-box-header :-moz-placeholder {
  /* Firefox 18- */
  font-size: 14px; }

.search-bar {
  background-color: #fff;
  position: relative; }
  .search-bar .form-group {
    width: 100%;
    height: 100%;
    margin-bottom: 0; }
  .search-bar input[type="search"] {
    padding-right: 50px; }
  .search-bar .search-icon {
    background-color: transparent;
    border: 0 none;
    color: #868e96;
    height: 36px;
    width: 30px; }
  .search-bar .form-control {
    box-shadow: none; }
    .search-bar .form-control.form-control-lg + .search-icon {
      position: absolute;
      right: 14px;
      top: 6px; }
  .search-bar .search-icon {
    position: absolute;
    right: 14px;
    top: 3px; }
  .search-bar.right-side-icon input[type="search"] {
    padding-right: 16px;
    padding-left: 50px; }
  .search-bar.right-side-icon .search-icon {
    position: absolute;
    left: 14px;
    top: 2px; }
  .search-bar.right-side-icon .form-control {
    box-shadow: none; }
    .search-bar.right-side-icon .form-control.form-control-lg + .search-icon {
      position: absolute;
      left: 14px;
      top: 6px; }

.nav-searchbox .search-bar input + .search-icon i {
  color: rgba(60, 72, 88, 0.8); }

@media screen and (max-width: 767px) {
  .jr-card .app-main-header .search-bar {
    width: 150px; } }

.lightbox__img-container {
  position: relative; }

.lightbox__img-container:hover .lightbox__img-overlay {
  height: 100%;
  cursor: pointer; }

.lightbox__img-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .3s ease; }

.lightbox__img-text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center; }

/* Pages
   ========================================================================== */
/*Login Styles*/
/*Login Styles*/
.login-container {
  position: relative;
  height: 100%; }

.login-content {
  width: 420px;
  margin: auto;
  padding: 35px 35px 20px;
  background-color: #fff;
  -webkit-box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-size: 14px;
  max-width: 94%; }

.login-header {
  margin-bottom: 30px; }

.login-content .form-control {
  background-color: #f5f5f5; }
  .login-content .form-control:focus {
    box-shadow: none;
    border-color: #00b96c; }

.login-content .btn, .login-content .jr-btn {
  padding: 10px 35px !important; }

.app-login-container {
  position: relative;
  min-height: 100%;
  max-width: 480px;
  width: 94%;
  margin: 0 auto; }
  .app-login-container .loader-view {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    top: 0;
    bottom: 0;
    z-index: 2; }
  @media screen and (max-width: 575px) {
    .app-login-container {
      margin: 0;
      background-color: white;
      max-width: 100%;
      width: 100%; } }

.app-login-main-content {
  background-color: #fff;
  -webkit-box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  font-size: 14px;
  overflow: hidden;
  padding: 50px; }
  @media screen and (max-width: 575px) {
    .app-login-main-content {
      box-shadow: none;
      padding: 40px 30px; } }

.app-login-content {
  padding: 35px 35px 20px;
  width: 60%;
  order: 1; }
  @media screen and (max-width: 575px) {
    .app-login-content {
      width: 100%;
      order: 2; } }

.app-login-header {
  margin-bottom: 30px; }

.app-login-content .form-control {
  background-color: #f5f5f5; }
  .app-login-content .form-control:focus {
    box-shadow: none;
    border-color: #00b96c; }

.app-login-content .btn, .login-content .jr-btn {
  padding: 10px 35px !important; }

.app-logo-content {
  background-color: #00b96c;
  padding: 35px 35px 20px;
  width: 40%;
  order: 2; }
  @media screen and (max-width: 575px) {
    .app-logo-content {
      width: 100%;
      order: 1; } }

.app-social-block {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between; }
  .app-social-block .social-link,
  .app-social-block .social-link li {
    margin: 0; }
  .app-social-block .social-link button {
    border: solid 1px #00b96c;
    border-radius: 50%;
    color: #00b96c; }
    .app-social-block .social-link button:hover, .app-social-block .social-link button:focus {
      color: #fff;
      background-color: #00b96c; }
  .app-social-block p {
    margin-bottom: 0; }

.checkout__package-card {
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  background-color: white;
  margin: 15px 0;
  padding: 10px 15px;
  border-radius: 8px; }
  .checkout__package-card:hover {
    cursor: pointer;
    transform: translateY(-3px); }

.checkout__package-card.selected {
  border: 2px solid #00b96c;
  background-color: #ece6f5; }

.checkout__package--title {
  font-size: 1.2rem;
  font-weight: 500; }

.checkout__package--subtitle {
  font-size: 0.9rem; }

.checkout__breakdown {
  border-top: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
  font-size: 1.2rem;
  text-align: right;
  padding: 10px 15px; }

/* FOR STRIPE ELEMENT CUSTOMIZATION */
.payment__stripe .loading-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.9;
  z-index: 200; }

.payment__stripe label {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 0; }

.payment__stripe .invalid-input {
  margin-top: .25rem;
  font-size: .875rem;
  color: #dc3545; }

.payment__stripe .StripeElement {
  display: block;
  width: 100%;
  margin: 0;
  padding: .5rem .75rem;
  margin-top: .5rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: .25rem;
  background: white;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; }

.payment__stripe .StripeElement--focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: none; }

.payment__stripe .StripeElement--invalid {
  color: #dc3545;
  background-color: #fff;
  border-color: #dc3545;
  outline: none; }

.payment__stripe .StripeElement.PaymentRequestButton {
  padding: 0; }

.payment__stripe .existing-card__info {
  font-size: 1.1rem;
  color: #616161; }

.gateway-option {
  border: 1px solid #616161;
  border-radius: 4px;
  padding: 5px;
  position: relative; }
  .gateway-option:hover {
    cursor: pointer;
    background-color: 616161;
    opacity: 0.8; }

.gateway-option-selected-icon {
  color: #00b96c;
  position: absolute;
  bottom: 2px;
  right: 2px; }

/* My Class & My Class detail page related CSS */
/* ribbon for new class purchase*/
.ribbon {
  width: 110px;
  height: 110px;
  overflow: hidden;
  position: absolute; }

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #b48905; }

.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 10px 0;
  background-color: #ffc107;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 16px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center; }

/* top right*/
.ribbon-top-right {
  top: -10px;
  right: -10px; }

.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent; }

.ribbon-top-right::before {
  top: 0;
  left: 14px; }

.ribbon-top-right::after {
  bottom: 14px;
  right: 0; }

.ribbon-top-right span {
  left: -40px;
  top: 15px;
  transform: rotate(45deg); }

/* ribbon for new class purchase*/
.ribbon-soldout {
  width: 115px;
  height: 115px;
  overflow: hidden;
  position: absolute; }

.ribbon-soldout::before,
.ribbon-soldout::after {
  position: absolute;
  z-index: 10;
  content: '';
  display: block;
  border: 5px solid #d3362d; }

.ribbon-soldout span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 10px 0 10px 10px;
  background-color: #e57368;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 16px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center; }

/* top right*/
.ribbon-soldout-top-right {
  top: -10px;
  right: -10px; }

.ribbon-soldout-top-right::before,
.ribbon-soldout-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent; }

.ribbon-soldout-top-right::before {
  top: 0;
  left: 14px; }

.ribbon-soldout-top-right::after {
  bottom: 14px;
  right: 0; }

.ribbon-soldout-top-right span {
  left: -40px;
  top: 15px;
  transform: rotate(45deg); }

.lesson__description {
  max-height: 2000px;
  transition: max-height 0.5s ease; }

.lesson__description--hidden {
  max-height: 60px;
  overflow: hidden;
  position: relative; }
  .lesson__description--hidden:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(transparent 25px, white); }

.custom-card .jr-card {
  border-radius: 8px; }

.custom-card .jr-card-header {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px; }

.back-link {
  width: fit-content;
  color: #00b96c; }
  .back-link:hover {
    cursor: pointer;
    transform: translateX(-7px);
    transition: all .3s ease; }

.forward-link {
  width: fit-content;
  color: #00b96c; }
  .forward-link:hover {
    cursor: pointer;
    transform: translateX(7px);
    transition: all .3s ease; }

.referral-banner {
  background-color: #FFF3D8;
  border-radius: 20px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2); }
  .referral-banner .referral__header {
    font-size: 1.5rem; }
  @media screen and (max-width: 414px) {
    .referral-banner {
      font-size: 90%; }
      .referral-banner .referral__header {
        font-size: 1.125rem; } }

.class-details-tab section {
  display: none;
  background-color: white;
  padding: 20px;
  border: 1px solid #abc; }

.class-details-tab input {
  display: none; }

.class-details-tab label {
  display: inline-block;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  color: #abc;
  border: 1px solid transparent; }

.class-details-tab label:before {
  font-family: fontawesome;
  font-weight: normal;
  margin-right: 10px; }

.class-details-tab label[for='live-lesson']:before {
  content: '\f108'; }

.class-details-tab label[for='resource']:before {
  content: '\f0ed'; }

.class-details-tab label[for='homework']:before {
  content: '\f02d'; }

.class-details-tab label[for='recording']:before {
  content: '\f144'; }

.class-details-tab label[for='report']:before {
  content: '\f0f6'; }

.class-details-tab label:hover {
  color: #00b96c;
  cursor: pointer; }

.class-details-tab input:checked + label {
  background-color: white;
  color: #00b96c;
  border: 1px solid #abc;
  border-top: 2px solid #00b96c;
  border-bottom: 1px solid #fff; }

.class-details-tab #live-lesson:checked ~ #live-lesson,
.class-details-tab #resource:checked ~ #resource,
.class-details-tab #homework:checked ~ #homework,
.class-details-tab #recording:checked ~ #recording,
.class-details-tab #report:checked ~ #report {
  display: block; }

@media screen and (max-width: 800px) {
  .class-details-tab label {
    font-size: 0; }
  .class-details-tab label:before {
    margin: 0;
    font-size: 18px; } }

@media screen and (max-width: 500px) {
  .class-details-tab label {
    padding: 15px; } }

.class-detail-container {
  background-color: white;
  padding: 20px;
  border: 1px solid #abc;
  border-radius: 5px; }

/* Chat containers */
.chat-container {
  height: 100%;
  box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.13);
  background-color: #f1f1f1;
  padding: 10px; }

/* Clear floats */
.chat-container::after {
  content: "";
  clear: both;
  display: table; }

.chat-item {
  padding: 16px; }
  .chat-item .bubble {
    padding: 8px 12px;
    background-color: #fff;
    -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.13);
    -moz-box-shadow: 0 0 2px rgba(0, 0, 0, 0.13);
    -o-box-shadow: 0 0 2px rgba(0, 0, 0, 0.13);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.13);
    border-radius: 20px 20px 20px 0px;
    position: relative;
    max-width: 80%; }
  .chat-item .bubble.reverse {
    margin-left: auto;
    background-color: #dcf8c6;
    border-radius: 20px 20px 0 20px; }

/* Darker chat container */
.me {
  /*border-color: #ccc;*/
  background-color: #dcf8c6; }

.month-selector {
  font-size: 1.5rem;
  font-family: "Rubik", sans-serif;
  border: 1px solid #a7a8a9;
  border-radius: 30px;
  background-color: white;
  padding: 7px 20px;
  margin: 0 1rem;
  cursor: pointer;
  display: flex;
  align-items: center; }
  .month-selector i {
    font-size: 1rem; }
  @media screen and (max-width: 375px) {
    .month-selector {
      font-size: 1.2rem;
      display: block;
      text-align: center; }
      .month-selector i {
        display: block; } }

.homework-item__content {
  max-height: 150px;
  overflow: hidden;
  position: relative;
  transition: max-height 0.5s ease; }
  .homework-item__content.open {
    max-height: 2000px; }
    .homework-item__content.open .content_toggle {
      position: relative;
      margin-top: 1rem;
      bottom: 0; }
  .homework-item__content .content_toggle {
    background: #000000;
    color: #ffffff;
    opacity: 0.65;
    font-weight: 600;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 3px;
    bottom: 1rem;
    text-align: center;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: bottom 0.5s ease;
    z-index: 5; }

.resources-list .card {
  margin-bottom: 7px; }

.resources-list .card:last-child {
  margin-bottom: 0; }

.fun-math__banner {
  box-shadow: 0 3px 20px 5px rgba(0, 0, 0, 0.15); }

.fun-math__banner--slogan {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 45px;
  margin: 0 0 10px;
  color: #333333;
  text-align: center;
  padding: 0 30px; }
  @media (max-width: 576px) {
    .fun-math__banner--slogan {
      font-size: 30px;
      padding: 0 5px; } }

.fun-math_video {
  width: 70%; }
  @media (max-width: 576px) {
    .fun-math_video {
      width: 100%; } }

.fun-math__img-container {
  position: relative; }

.fun-math__img-container:hover .fun-math__img-overlay {
  height: 100%;
  cursor: pointer; }

.fun-math__img-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .3s ease; }

.fun-math__img-text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center; }

.fun-math__tutor-intro {
  line-clamp: 3; }

.fun-math__advisor-img {
  width: 170px;
  height: auto; }

.live-tutoring-img {
  width: auto;
  height: 20vh; }
  @media screen and (max-width: 1000px) {
    .live-tutoring-img {
      height: 18vh; } }
  @media screen and (max-width: 992px) {
    .live-tutoring-img {
      height: 14vh; } }

.jrmf-banner {
  background-image: url("https://s3-ap-southeast-1.amazonaws.com/tenoften-app-asset/web-assets/frontend/jrmf-banner-bg.jpg");
  background-size: cover;
  background-position: 0% 25%;
  min-height: 360px; }

#jrmf-img {
  width: 70%; }
  @media (max-width: 576px) {
    #jrmf-img {
      width: 100%; } }

#jrmf-video {
  width: 70%;
  height: calc(70vw * 9 / 16); }
  @media (max-width: 576px) {
    #jrmf-video {
      width: 100%;
      height: calc(100vw * 9 / 16); } }

/* Korean LP specific CSS*/
.korean__section-header {
  margin-bottom: 6.25rem;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: #000000;
  text-align: center; }
  @media screen and (max-width: 767px) {
    .korean__section-header {
      margin-bottom: 2.5rem; } }
  .korean__section-header .title {
    font-size: 1.8rem;
    margin-bottom: 30px; }
    @media screen and (max-width: 767px) {
      .korean__section-header .title {
        font-size: 1.65rem; } }
    @media screen and (max-width: 375px) {
      .korean__section-header .title {
        font-size: 1.5rem; } }
  .korean__section-header .subtitle {
    font-size: 1.3rem; }
    @media screen and (max-width: 767px) {
      .korean__section-header .subtitle {
        font-size: 1.2rem; } }
    @media screen and (max-width: 375px) {
      .korean__section-header .subtitle {
        font-size: 1.1rem; } }

.korean__hero-banner {
  background-image: url("https://s3-ap-southeast-1.amazonaws.com/tenoften-app-asset/web-assets/frontend/korean/banner-bg.jpg");
  background-size: cover;
  background-position: center; }

.korean__feature-card--mobile {
  border-radius: 8px;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.25); }

.korean__banner-header {
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 2px; }
  @media screen and (max-width: 767px) {
    .korean__banner-header {
      font-size: 35px; } }

.korean__step-container {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto; }
  @media (max-width: 992px) {
    .korean__step-container {
      max-width: 960px; } }
  @media (max-width: 1200px) {
    .korean__step-container {
      max-width: 1140px; } }

.korean__step-arrow {
  height: 80%; }
  @media (max-width: 767px) {
    .korean__step-arrow {
      transform: rotate(90deg);
      margin: 10px 0; } }

.korean__triangle-down {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid white; }

.korean__insta-container {
  position: relative; }

.korean__insta-container:hover .korean__insta-overlay {
  height: 100%;
  cursor: pointer; }

.korean__insta-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .3s ease; }

.korean__insta-text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center; }

.korean__testi .carousel .control-arrow:before, .korean__testi .carousel.carousel-slider .control-arrow:before {
  margin: 0 35px 0 5px;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent; }

.korean__testi .carousel .control-prev.control-arrow:before {
  border-right: 20px solid #000000; }

.korean__testi .carousel .control-next.control-arrow:before {
  border-left: 20px solid #000000; }

.korean__testi .carousel.carousel-slider .control-arrow:hover {
  background: transparent; }

.korean__video-container {
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.35), 0 5px 15px 0 rgba(0, 0, 0, 0.18); }

.korean__class-card {
  max-width: 100%; }
  @media (min-width: 768px) {
    .korean__class-card {
      max-width: 320px; } }

/* USA LP specific CSS*/
.USA__section-border--primary {
  border: 1px solid #00b96c;
  border-radius: 1rem; }

.USA__section-divider--primary {
  border-right: 1px solid #00b96c;
  border-bottom: 0; }
  @media screen and (max-width: 767px) {
    .USA__section-divider--primary {
      border-bottom: 1px solid #00b96c;
      border-right: 0; } }

.USA__feature-item {
  text-align: center; }
  .USA__feature-item.space--vertical {
    margin-top: 60px;
    margin-bottom: 60px; }
  .USA__feature-item .image {
    padding: 0 50px; }
    @media screen and (max-width: 767px) {
      .USA__feature-item .image {
        padding: 0;
        width: 35%;
        min-width: 100px;
        margin-bottom: 30px; } }
  .USA__feature-item .text__header {
    height: 4.5rem; }
    @media screen and (max-width: 767px) {
      .USA__feature-item .text__header {
        height: auto;
        margin-bottom: 1.5rem; } }
  .USA__feature-item .text__body {
    margin-bottom: 3rem; }
    @media screen and (max-width: 767px) {
      .USA__feature-item .text__body {
        margin-bottom: 1.5rem; } }
  @media screen and (max-width: 767px) {
    .USA__feature-item {
      text-align: center;
      margin-bottom: 90px; } }

.USA__divider--limited {
  display: block;
  width: 20%;
  border-bottom: #dddddd solid 2px;
  margin: 50px 0; }

.get-diagnostic-test {
  border-radius: 20px;
  font-size: 1.1rem;
  padding: 7px 15px;
  margin: 0 10px 15px 10px;
  background-color: #FC9631;
  color: white;
  cursor: pointer; }

.file-download-card {
  text-decoration: none !important; }
  .file-download-card .title {
    font-size: 24px; }
  .file-download-card .blue {
    background-color: #3f51b5;
    color: white;
    border: 1px solid white; }
    .file-download-card .blue:hover {
      background-color: white;
      color: #3f51b5;
      border: 1px solid #3f51b5; }
  .file-download-card .pink {
    background-color: #ff4081;
    color: white;
    border: 1px solid white; }
    .file-download-card .pink:hover {
      background-color: white;
      color: #ff4081;
      border: 1px solid #ff4081; }

.hero-banner__download {
  background-image: url("https://s3-ap-southeast-1.amazonaws.com/tenoften-app-asset/web-assets/frontend/download-banner-bg.jpg");
  display: flex;
  background-position: center center;
  background-size: cover;
  color: #ffffff;
  position: relative; }

.download {
  width: 200px;
  height: 75px;
  background: black;
  float: left;
  border-radius: 5px;
  position: relative;
  color: #fff;
  cursor: pointer;
  border: 1px solid #fff;
  margin-bottom: 1rem; }

.download > .fa {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%); }

.df,
.dfn {
  position: absolute;
  left: 70px; }

.df {
  top: 20px;
  font-size: .68em; }

.dfn {
  top: 33px;
  font-size: 1.08em; }

.download:hover {
  -webkit-filter: invert(100%);
  filter: invert(100%); }

.hero-banner__about-us {
  background-image: url("https://s3-ap-southeast-1.amazonaws.com/tenoften-app-asset/web-assets/about/teamgroup.jpg");
  padding: 128px 0;
  display: flex;
  background-position: center top;
  background-size: cover;
  color: #ffffff;
  position: relative; }
  .hero-banner__about-us .mask {
    position: absolute;
    background-color: rgba(50, 50, 50, 0.7);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0; }
  .hero-banner__about-us .title {
    font-size: 2rem; }
  .hero-banner__about-us .subtitle {
    font-size: 1.5rem;
    font-weight: 300; }

.team__profile-pic {
  margin-bottom: 15px;
  max-width: 480px; }
  .team__profile-pic img {
    width: 100%;
    border-radius: 50%; }

.faq__menu {
  font-size: 1.4rem;
  color: #8492A6;
  margin: 1rem 0;
  padding-left: 1rem; }
  .faq__menu:hover {
    cursor: pointer;
    color: #00864e; }
  @media screen and (max-width: 767px) {
    .faq__menu {
      border-bottom: 1px solid #E0E6ED;
      padding-bottom: 1rem;
      margin-bottom: 0; } }

.faq__menu.active {
  color: #00b96c; }

.faq__extra-info {
  border-top: 1px solid #E0E6ED;
  font-size: 1rem;
  color: #3C4858;
  margin-top: 1.5rem;
  padding-top: 0.5rem;
  padding-left: 1rem; }

.faq__title {
  font-size: 1.7rem;
  color: #00b96c;
  border-bottom: 1px solid #E0E6ED;
  padding-left: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 0.5rem; }

.faq__section {
  border-bottom: 1px solid #E0E6ED;
  margin-bottom: 0.2rem; }

.faq__question {
  font-weight: 500;
  font-size: 17px;
  color: #333333;
  padding: 1rem; }
  .faq__question:hover {
    cursor: pointer; }
  @media screen and (max-width: 767px) {
    .faq__question {
      background-color: white; } }

.faq__answer {
  padding: 0 1rem 1rem;
  color: #3C4858; }
  @media screen and (max-width: 767px) {
    .faq__answer {
      padding: 1rem; } }

.accordion {
  font-weight: 400;
  transform: rotate(0deg);
  transition: transform 0.3s linear; }

.accordion.open {
  font-weight: 400;
  transform: rotate(180deg);
  transition: transform 0.25s linear; }

/* Class catalogue & detail page CSS */
.filter__label {
  color: #aaaaaa;
  font-size: 1rem;
  padding: 5px 15px; }

.filter__option {
  font-size: 1rem;
  margin: 0 5px;
  padding: 5px 15px 5px 15px; }

.filter__option--inactive {
  color: #333333; }
  .filter__option--inactive:hover {
    color: white;
    background-color: #00864e;
    border-radius: 10px;
    cursor: pointer; }

.filter__option--active {
  color: white;
  background-color: #00b96c;
  border-radius: 10px; }

.carousel .slide {
  background-color: transparent; }

.tutor-profile .carousel .control-arrow:before, .tutor-profile .carousel.carousel-slider .control-arrow:before {
  margin: 0px;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent; }

.tutor-profile .carousel .control-prev.control-arrow:before {
  border-right: 20px solid #00b96c; }

.tutor-profile .carousel .control-next.control-arrow:before {
  border-left: 20px solid #00b96c; }

.tutor-profile .carousel.carousel-slider .control-arrow:hover {
  background: transparent; }

.tutor-profile .carousel .control-dots {
  bottom: -10px;
  padding: 0; }

.tutor-profile .carousel .control-dots .dot {
  background-color: #00b96c;
  margin-top: 25px; }

.tutor-intro .carousel.carousel-slider .control-arrow:hover {
  background: rgba(0, 185, 108, 0.5); }

.tutor-intro .carousel .control-dots {
  bottom: -10px;
  padding: 0; }

.tutor-intro .carousel .control-dots .dot {
  background-color: #00b96c;
  margin-top: 25px; }

/* Purchase dialog class intro */
@media screen and (max-width: 425px) {
  .intro__title {
    font-size: 1.1rem; } }

.intro__image {
  margin-bottom: 1rem; }

@media screen and (max-width: 425px) {
  .intro__text .intro__description {
    font-size: 90%; } }

/* END Purchase dialog class intro */
/*NEW Class detail page design */
.cc-detail__banner {
  background: radial-gradient(#485563, #29323c);
  border-bottom: 3px solid #333;
  border-top: 3px solid #333; }

.cc-detail__no-video {
  min-height: 480px; }

.cc-detail__right-col {
  background-color: white;
  position: absolute;
  width: calc(1140px / 12 * 4); }
  @media screen and (max-width: 1199px) {
    .cc-detail__right-col {
      width: calc(960px / 12 * 4); } }
  @media screen and (max-width: 991px) {
    .cc-detail__right-col {
      width: calc(720px / 12 * 4); } }
  @media screen and (max-width: 767px) {
    .cc-detail__right-col {
      position: relative;
      background-color: transparent;
      width: 100%; } }

.cc-detail__right-col.fixed--top {
  position: fixed;
  top: 80px; }
  @media screen and (max-width: 767px) {
    .cc-detail__right-col.fixed--top {
      position: relative;
      top: 0; } }

.cc-detail__right-col.fixed--bottom {
  position: fixed;
  bottom: 330px; }
  @media screen and (max-width: 991px) {
    .cc-detail__right-col.fixed--bottom {
      bottom: 410px; } }
  @media screen and (max-width: 767px) {
    .cc-detail__right-col.fixed--bottom {
      position: relative; } }

.cc-detail__right-col--content {
  box-shadow: 0 0 1px 1px rgba(20, 23, 28, 0.1), 0 3px 1px 0 rgba(20, 23, 28, 0.1);
  border-radius: 4px;
  color: #505763;
  padding: 3px;
  background-color: white; }

.cc-detail__video--container {
  position: relative;
  width: 100%;
  max-width: 400px; }

.cc-detail__video--overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 120px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  opacity: 0.35;
  transition: .3s ease;
  background: linear-gradient(#f6f6f6, #a1a3a7, #29323c); }

.cc-detail__video--icon {
  color: white;
  position: absolute;
  opacity: 0.7;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  transition: transform .2s; }

.cc-detail__video--icon:hover {
  cursor: pointer; }

.cc-detail__price {
  font-size: 32px;
  color: #00b96c;
  font-weight: 500; }

.cc-detail__desc {
  font-size: 1rem; }

.cc-detail__contact {
  background-color: rgba(0, 0, 0, 0.4);
  padding: 20px;
  border-radius: 8px; }

/* END NEW Class detail page design */
.landing {
  font-family: "Libre Franklin", sans-serif;
  font-size: 17px;
  background-color: white; }

section {
  padding: 75px 1rem; }
  @media screen and (max-width: 525px) {
    section {
      padding: 50px 1rem; } }

.text__header {
  font-family: "Rubik", sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 2rem;
  color: #3C4858; }
  @media screen and (max-width: 425px) {
    .text__header {
      font-size: 1.2rem; } }

.text__body {
  font-size: 1rem;
  color: #5D6F88; }

.section__header {
  margin-bottom: 6.25rem;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  color: #000000;
  text-align: center; }
  @media screen and (max-width: 767px) {
    .section__header {
      margin-bottom: 2.5rem; } }
  .section__header .title {
    font-size: 2rem;
    margin-bottom: 30px; }
    @media screen and (max-width: 767px) {
      .section__header .title {
        font-size: 1.75rem; } }
    @media screen and (max-width: 375px) {
      .section__header .title {
        font-size: 1.5rem; } }
  .section__header .subtitle {
    font-size: 1.5rem; }
    @media screen and (max-width: 767px) {
      .section__header .subtitle {
        font-size: 1.3rem; } }
    @media screen and (max-width: 375px) {
      .section__header .subtitle {
        font-size: 1.1rem; } }

.section__text {
  font-size: 1rem;
  padding: 0 1rem;
  margin-bottom: 2rem; }

.hero-banner {
  position: relative;
  min-height: 70vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #f9fafc;
  display: flex;
  flex-direction: column; }
  @media screen and (max-width: 767px) {
    .hero-banner {
      min-height: 200px; } }

.hero-banner__slogan {
  width: 100%;
  max-width: 550px;
  margin: 0 auto; }
  .hero-banner__slogan .slogan-container {
    margin-bottom: 2em; }
  .hero-banner__slogan .title {
    font-size: 3rem;
    font-weight: 600;
    font-family: "Rubik", sans-serif; }
    @media screen and (max-width: 1024px) {
      .hero-banner__slogan .title {
        font-size: 2.5rem; } }
    @media screen and (max-width: 768px) {
      .hero-banner__slogan .title {
        font-size: 2rem; } }
    @media screen and (max-width: 375px) {
      .hero-banner__slogan .title {
        font-size: 1.5rem; } }
  .hero-banner__slogan .subtitle {
    font-size: 2rem;
    font-weight: 300; }
    @media screen and (max-width: 1024px) {
      .hero-banner__slogan .subtitle {
        font-size: 1.5rem; } }
    @media screen and (max-width: 375px) {
      .hero-banner__slogan .subtitle {
        font-size: 1.2rem; } }

.shadow-box {
  border-radius: 8px;
  box-shadow: 0 0 16px -3px #888888; }
  @media screen and (max-width: 767px) {
    .shadow-box {
      border-radius: 0; } }

.tnp__button {
  height: 40px;
  padding: 8px 4rem;
  font-family: "Rubik", sans-serif;
  font-size: 17px;
  font-weight: 500;
  text-align: center;
  text-transform: capitalize;
  background-color: #00b96c;
  color: white;
  border: none;
  border-radius: 16px;
  box-shadow: 0 4px 16px 0 rgba(0, 185, 108, 0.5);
  transition: transform ease 0.2s, box-shadow ease 0.2s;
  white-space: nowrap; }
  .tnp__button.outline {
    border: 2px solid #00b96c;
    padding: 6px 4rem;
    background-color: transparent;
    color: #00b96c !important;
    box-shadow: none; }
  .tnp__button:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 25px 0 rgba(0, 185, 108, 0.5);
    text-decoration: none;
    color: white; }
  @media screen and (max-width: 767px) {
    .tnp__button {
      padding: 6px 4rem;
      white-space: normal;
      height: auto; } }

.tnp__button.lg {
  height: 50px;
  font-size: 20px; }
  @media screen and (max-width: 767px) {
    .tnp__button.lg {
      height: auto; } }

.stat .tag-icon {
  color: white;
  font-size: 40px;
  margin-left: 0px; }
  @media screen and (max-width: 768px) {
    .stat .tag-icon {
      font-size: 60px;
      margin-left: 10px; } }

.stat .divider {
  margin: 10px auto 10px auto;
  width: 25px;
  border-bottom: 2px solid #fff; }
  @media screen and (max-width: 768px) {
    .stat .divider {
      margin: 10px auto 20px 0; } }

.stat .tagline {
  font-family: 'Rubik', sans-serif;
  font-size: 20px; }

.feature-icon {
  width: 50%; }
  @media screen and (max-width: 768px) {
    .feature-icon {
      width: 55%; } }

.numbers {
  font-size: 40px;
  line-height: 1em;
  text-align: center;
  margin: 10px auto;
  overflow: hidden; }
  .numbers__window {
    display: inline-block;
    overflow: hidden;
    width: 0.7em;
    height: 1em; }
    .numbers__window__digit {
      font: inherit;
      word-break: break-all;
      display: block;
      width: 0;
      padding: 0 0.72em 0 0;
      margin: 0 auto;
      overflow: inherit;
      animation: counting 0.4s steps(10) forwards infinite; }
      .numbers__window__digit::before {
        content: attr(data-fake);
        display: inline-block;
        width: 100%;
        height: auto; }
      .numbers__window__digit--1 {
        animation-iteration-count: 3; }
      .numbers__window__digit--2 {
        animation-iteration-count: 6; }
      .numbers__window__digit--3 {
        animation-iteration-count: 9; }
      .numbers__window__digit--4 {
        animation-iteration-count: 12; }
      .numbers__window__digit--5 {
        animation-iteration-count: 15; }

@keyframes counting {
  100% {
    transform: translate3d(0, -10em, 0); } }

.filter__container {
  border-top: solid 1px #e6e6e6;
  border-bottom: solid 1px #e6e6e6;
  padding: 1rem 0; }
  .filter__container .filter__option {
    display: inline-block;
    width: 130px;
    text-align: center;
    padding: 4px 0;
    margin: 0 6px 7px 0;
    border: solid 1px #b3b3b3;
    border-radius: 5px;
    color: #b3b3b3;
    cursor: pointer;
    user-select: none;
    transition: all 0.1s ease; }
    .filter__container .filter__option:hover {
      opacity: 0.7; }
    .filter__container .filter__option.selected {
      color: #00b96c;
      border-color: #00b96c; }

.promo__item {
  padding: 0.5rem 1rem;
  margin: 0 -1rem 0.5rem;
  background: white; }

.promo__button {
  background-color: #45aaf2;
  text-align: center;
  user-select: none;
  padding: 0.5rem 1rem;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  text-transform: capitalize;
  color: white;
  border: none;
  border-radius: 50px;
  transition: transform ease 0.2s, box-shadow ease 0.2s;
  white-space: nowrap; }
  .promo__button:disabled {
    cursor: auto;
    opacity: 0.5; }

.filter__container {
  border-top: solid 1px #e6e6e6;
  border-bottom: solid 1px #e6e6e6;
  padding: 1rem 0; }
  .filter__container .filter__option {
    display: inline-block;
    width: 130px;
    text-align: center;
    padding: 4px 0;
    margin: 0 6px 7px 0;
    border: solid 1px #b3b3b3;
    border-radius: 5px;
    color: #b3b3b3;
    cursor: pointer;
    user-select: none;
    transition: all 0.1s ease; }
    .filter__container .filter__option:hover {
      opacity: 0.7; }
    .filter__container .filter__option.selected {
      color: #00b96c;
      border-color: #00b96c; }

.promo__item {
  padding: 0.5rem 1rem;
  margin: 0 -1rem 0.5rem;
  background: white; }

.promo__button {
  background-color: #45aaf2;
  text-align: center;
  user-select: none;
  padding: 0.5rem 1rem;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  text-transform: capitalize;
  color: white;
  border: none;
  border-radius: 50px;
  transition: transform ease 0.2s, box-shadow ease 0.2s;
  white-space: nowrap; }
  .promo__button:disabled {
    cursor: auto;
    opacity: 0.5; }

.psle_promo__banner {
  background: radial-gradient(#485563, #29323c);
  border-bottom: 3px solid #333;
  border-top: 3px solid #333; }

.psle_promo__no-video {
  min-height: 480px; }

.psle_promo__right-col {
  background-color: white;
  position: absolute;
  width: 100%; }
  @media screen and (max-width: 767px) {
    .psle_promo__right-col {
      position: relative;
      background-color: transparent; } }

.psle_promo__right-col--content {
  box-shadow: 0 0 1px 1px rgba(20, 23, 28, 0.1), 0 3px 1px 0 rgba(20, 23, 28, 0.1);
  border-radius: 4px;
  color: #505763;
  padding: 3px;
  background-color: white; }

.psle_promo__video--container {
  position: relative;
  width: 100%;
  max-width: 400px; }

.psle_promo__video--overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 120px;
  height: 100%;
  width: 100%;
  overflow: hidden;
  opacity: 0.35;
  transition: .3s ease;
  background: linear-gradient(#f6f6f6, #a1a3a7, #29323c); }

.psle_promo__video--icon {
  color: white;
  position: absolute;
  opacity: 0.7;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  transition: transform .2s; }

.psle_promo__video--icon:hover {
  cursor: pointer; }

.psle_promo__price {
  font-size: 32px;
  color: #00b96c;
  font-weight: 500; }

.plse_promo__desc {
  font-size: 1rem; }

.psle_promo__contact {
  background-color: rgba(0, 0, 0, 0.4);
  padding: 20px;
  border-radius: 8px; }

.promo__CTA--sticky {
  position: fixed;
  top: 75px;
  right: 0px;
  background-color: white;
  box-shadow: 0 0 1px 1px rgba(20, 23, 28, 0.1), 0 3px 1px 0 rgba(20, 23, 28, 0.1);
  z-index: 1000; }

/* CONVERSION PAGE VARIANT A */
.variant-A {
  position: relative;
  background: #ffffff;
  padding: 50px 0 30px 0; }
  @media (min-width: 768px) {
    .variant-A {
      padding: 50px 0 120px 0; }
      .variant-A::before {
        position: absolute;
        content: "";
        width: 66.66666%;
        height: 100%;
        left: 0;
        top: 0;
        background: #ffffff; }
      .variant-A::after {
        position: absolute;
        content: "";
        width: 33.33333%;
        height: 100%;
        right: 0;
        top: 0;
        bottom: 0;
        background: #8e51f0;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNmZmZmZmYiPjxwYXRoIGQ9Ik0wIDB2MTQwaDEyODBMMCAweiIgZmlsbC1vcGFjaXR5PSIuNSIvPjxwYXRoIGQ9Ik0wIDQydjk4aDEyODBMMCA0MnoiLz48L2c+PC9zdmc+);
        background-size: 100% 100px;
        z-index: 1;
        background-repeat: no-repeat;
        background-position: 10% 100%;
        transform: rotate(360deg); } }
  @media (max-width: 767px) {
    .variant-A .mobile-view {
      min-height: 120px;
      padding: 30px 0 80px 20px;
      background: #8e51f0;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNmZmZmZmYiPjxwYXRoIGQ9Ik0wIDB2MTQwaDEyODBMMCAweiIgZmlsbC1vcGFjaXR5PSIuNSIvPjxwYXRoIGQ9Ik0wIDQydjk4aDEyODBMMCA0MnoiLz48L2c+PC9zdmc+);
      background-size: 100% 100px;
      z-index: 1;
      background-repeat: no-repeat;
      background-position: 10% 100%; } }

/* END CONVERSION PAGE VARIANT A */
.trial__banner-bg--main {
  background-image: url("https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/frontend/bg-1.png");
  background-size: cover;
  background-position: center center;
  height: 700px;
  width: 100%;
  position: relative; }
  @media screen and (max-width: 768px) {
    .trial__banner-bg--main {
      height: 100%;
      padding: 20px 10px; } }

.trial__banner-bg--side {
  background-image: url("https://s3-ap-southeast-1.amazonaws.com/tenoften-app-asset/web-assets/student/main_background.jpg");
  background-position: 75% center;
  background-size: cover;
  position: absolute;
  z-index: 1;
  width: 50%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden; }
  .trial__banner-bg--side::after {
    position: absolute;
    content: '';
    width: 150%;
    height: 180%;
    top: -15%;
    left: -132%;
    background-color: white;
    z-index: -1;
    transform: rotate(-15deg); }

.trial__banner-bg--mobile {
  background-image: url("https://s3-ap-southeast-1.amazonaws.com/tenoften-app-asset/web-assets/student/main_background.jpg");
  background-position: center center;
  background-size: cover;
  height: auto;
  width: 100%; }

.trial__banner-bg--main-vertical {
  background-image: url("https://tenoften-app-asset.s3-ap-southeast-1.amazonaws.com/web-assets/frontend/bg-1.png");
  background-size: cover;
  background-position: center center;
  height: 60vh;
  width: 100%;
  position: relative; }

.trial__banner-bg--side-vertical {
  background-image: url("https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/amazing_ash_3_sep.jpeg");
  background-size: cover;
  position: absolute;
  z-index: 1;
  width: 47%;
  height: 100%;
  top: 0;
  right: 0; }
  @media screen and (max-width: 1440px) and (min-width: 769px) {
    .trial__banner-bg--side-vertical {
      width: 50%; } }

.trial__banner-bg--mobile-vertical {
  background-image: url("https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/pages/amazing_ash_3_sep.jpeg");
  background-position: center center;
  background-size: cover;
  height: auto;
  width: 100%; }

.trial__banner-title {
  font-family: 'Rubik', sans-serif;
  font-size: 2.3rem;
  font-weight: 400;
  margin-bottom: 1rem;
  line-height: 1.2; }

.trial__banner-slogan {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-size: 1.2rem;
  margin-bottom: 20px; }

.trial__banner-play {
  position: relative;
  z-index: 1;
  box-shadow: 0 6px 50px 8px rgba(21, 131, 233, 0.15);
  width: 100px;
  height: 100px;
  display: inline-block;
  background-color: #ffffff;
  border-radius: 50%;
  font-size: 2rem;
  line-height: 100px; }

/* Prevent scrollbars to appear when waves go out of bound */
.sonar-wrapper {
  position: relative;
  z-index: 0;
  overflow: hidden; }

.sonar-play {
  color: white;
  font-size: 80px;
  position: absolute;
  top: calc(50% - 40px);
  right: calc(50% - 41px);
  z-index: 1;
  cursor: pointer; }
  @media screen and (max-width: 767px) {
    .sonar-play {
      font-size: 60px;
      top: calc(50% - 30px);
      right: calc(50% - 31px); } }

.sonar-play--sm {
  color: white;
  font-size: 60px;
  position: absolute;
  top: calc(50% - 30px);
  right: calc(50% - 31px);
  z-index: 1;
  cursor: pointer; }

/* The circle */
.sonar-emitter {
  position: relative;
  margin: 32px auto;
  width: 90px;
  height: 90px;
  border-radius: 999px;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 0; }
  @media screen and (max-width: 767px) {
    .sonar-emitter {
      margin: 22px auto;
      width: 70px;
      height: 70px; } }

.sonar-emitter--sm {
  position: relative;
  margin: 32px auto;
  width: 70px;
  height: 70px;
  border-radius: 999px;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 0; }

/* the 'wave', same shape and size as its parent */
.sonar-wave {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 999px;
  background-color: rgba(255, 255, 255, 0.7);
  opacity: 0;
  z-index: -1;
  pointer-events: none; }

/*
  Animate!
  NOTE: add browser prefixes where needed.
*/
.sonar-wave {
  animation: sonarWave 2s linear infinite; }

@keyframes sonarWave {
  from {
    opacity: 0.4; }
  to {
    transform: scale(1.5);
    opacity: 0; } }

.trial__wrapper {
  text-align: center;
  margin: 30px auto; }
  @media screen and (max-width: 525px) {
    .trial__wrapper {
      margin: 30px 0; } }

.trial__tabs {
  font-size: 17px;
  padding: 0px;
  list-style: none;
  background: #fff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  display: inline-block;
  border-radius: 50px;
  position: relative; }
  @media screen and (max-width: 767px) {
    .trial__tabs {
      border-radius: 25px; } }

.trial__tabs span {
  text-decoration: none;
  color: #777;
  padding: 10px 20px;
  display: inline-block;
  position: relative;
  z-index: 1;
  transition-duration: 0.6s;
  cursor: pointer; }
  @media screen and (max-width: 525px) {
    .trial__tabs span {
      padding: 10px 10px; } }

.trial__tabs span.active {
  color: #fff; }
  @media screen and (max-width: 445px) {
    .trial__tabs span.active {
      border-radius: 50px;
      background: #05abe0;
      background: -moz-linear-gradient(45deg, #05abe0 0%, #8200f4 100%);
      background: -webkit-linear-gradient(45deg, #05abe0 0%, #8200f4 100%);
      background: linear-gradient(45deg, #05abe0 0%, #8200f4 100%); } }

.trial__tabs .trial__selector {
  height: 100%;
  display: inline-block;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1;
  border-radius: 50px;
  transition-duration: 0.6s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  @media screen and (min-width: 446px) {
    .trial__tabs .trial__selector {
      background: #05abe0;
      background: -moz-linear-gradient(45deg, #05abe0 0%, #8200f4 100%);
      background: -webkit-linear-gradient(45deg, #05abe0 0%, #8200f4 100%);
      background: linear-gradient(45deg, #05abe0 0%, #8200f4 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#05abe0', endColorstr='#8200f4',GradientType=1 ); } }

/* Modules
   ========================================================================== */
/* App Module Style */
.app-wrapper-module {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-align-items: stretch;
  align-items: stretch;
  height: 100%; }

.app-module {
  position: relative;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin: -24px; }

.module-side {
  position: relative;
  z-index: 2;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-flex: 0 1 230px;
  -ms-flex: 0 1 230px;
  flex: 0 1 230px;
  max-width: 230px;
  min-width: 230px; }

.module-side-header {
  border-bottom: solid 1px #e9ecef;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap; }
  .module-side-header .user-detail {
    padding: 15px 10px;
    text-align: center; }

.module-logo {
  padding: 10px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  min-height: 78px;
  font-size: 20px;
  background-color: #f9fafb;
  color: #2f353a; }
  .module-logo .zmdi {
    font-size: 28px; }

.module-user-info, .module-side-content {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap; }

.module-user-detail {
  font-size: 12px; }

.module-add-task {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 24px; }

.module-nav {
  list-style: none;
  padding-left: 0;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  justify-content: center; }
  .module-nav li {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .module-nav li a {
    padding: 12px 29px;
    color: #bfc8d4;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-items: center;
    align-items: center;
    text-decoration: none; }
    .module-nav li a:hover, .module-nav li a:focus {
      color: #3C4858; }
    .module-nav li a.active {
      color: #00b96c; }
  .module-nav li i {
    margin-right: 16px;
    font-size: 16px; }
  .module-nav li span {
    display: inline-block;
    vertical-align: middle; }
  .module-nav li.module-nav-label {
    padding: 12px 29px; }

.module-box {
  position: relative;
  z-index: 2;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  max-width: calc(100% - 231px); }

.module-box-header {
  padding: 10px 26px;
  min-height: 79px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  justify-content: center;
  background-color: #fff;
  border-bottom: solid 1px #e9ecef; }
  .module-box-header .search-bar.right-side-icon {
    min-width: 350px; }
    .module-box-header .search-bar.right-side-icon .form-control {
      border-radius: 0;
      height: 46px;
      padding: 10px 18px 10px 40px; }
      .module-box-header .search-bar.right-side-icon .form-control:focus {
        background-color: #f8f9fa; }
    .module-box-header .search-bar.right-side-icon .search-icon {
      width: 25px;
      height: 46px;
      font-size: 16px;
      left: 10px;
      top: 0;
      padding: 0; }

.module-box-header-inner {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between; }

.module-box-content {
  background-color: #fff;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap; }

.module-box-topbar {
  padding: 8px 12px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-bottom: solid 1px #e9ecef; }
  .module-box-topbar button + button {
    border-radius: 0; }

.module-box-topbar-todo {
  padding-left: 64px; }
  @media screen and (max-width: 575px) {
    .module-box-topbar-todo {
      padding-left: 27px; } }

.module-list-icon {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  padding-right: 10px; }

.bar-icon {
  margin-right: 15px; }

.toolbar-separator {
  border-left: solid 1px #ced4da;
  height: 100%;
  width: 1px;
  margin: 0 12px; }

.toolbar-left {
  margin-left: auto; }

.module-list {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  justify-content: center; }

.module-list-item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  padding: 15px 28px;
  border-bottom: solid 1px #e9ecef;
  z-index: 5;
  position: relative;
  cursor: pointer; }
  .module-list-item > .check-label {
    margin-left: 0;
    margin-bottom: 0; }
  .module-list-item.mail-cell {
    padding-left: 12px;
    -webkit-align-items: start;
    align-items: start; }

.module-detail-header {
  margin-bottom: 10px; }
  .module-detail-header > .check-label {
    margin-right: auto; }

.module-list-info {
  -webkit-flex: 1 1 0;
  -ms-flex: 1 1 0;
  flex: 1 1 0;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  max-width: calc(100% - 169px); }

.module-todo-content {
  position: relative; }
  .module-todo-content .subject {
    margin-bottom: 5px; }

.module-list-actions {
  text-align: right; }

.app-module-sidenav {
  background-color: #fff;
  border-right: solid 1px #e9ecef;
  position: relative;
  z-index: 9; }
  .app-module-sidenav .chat-sidenav-main {
    max-width: 300px;
    min-width: 300px;
    width: 90%; }

.module-date {
  max-width: 160px; }
  .module-date > div:before {
    display: none; }

.module-detail-item {
  padding: 10px 28px; }

.task-title {
  font-size: 18px; }

.chat-todo-avatar {
  margin-right: 15px; }

.modal-title {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-align-items: center;
  align-items: center;
  width: 100%;
  font-size: 18px; }

.loader-view-block, .loader-view {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center; }
  .loader-view-block .slimScrollBar, .loader-view .slimScrollBar {
    display: none !important; }

.module-date > div {
  -webkit-align-items: center;
  align-items: center; }

@media screen and (max-width: 1199px) {
  .module-side {
    display: -webkit-block;
    display: -moz-block;
    display: -ms-block;
    display: -o-block;
    display: block;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    max-width: 280px;
    min-width: 280px; }
  .module-box-header {
    position: relative;
    min-height: 58px;
    padding: 5px 26px 5px 75px; }
    .module-box-header .drawer-btn {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      border-right: solid 1px #e9ecef;
      height: 58px;
      width: 56px;
      border-radius: 0;
      font-size: 22px; }
    .module-box-header .search-bar.right-side-icon .form-control {
      height: 38px;
      padding: 5px 18px 5px 40px; }
    .module-box-header .search-bar.right-side-icon .search-icon {
      height: 38px; }
  .chat-loader-view {
    height: calc(100vh - 120px); }
  .module-box {
    max-width: 100%; } }

@media screen and (min-width: 991px) {
  .modal-dialog {
    max-width: 750px; } }

@media screen and (max-width: 991px) {
  .app-module {
    padding-top: 0; } }

@media screen and (max-width: 599px) {
  .module-box-header .search-bar {
    margin-left: 0;
    max-width: none; }
    .module-box-header .search-bar.right-side-icon {
      min-width: 250px; } }

@media screen and (max-width: 575px) {
  .module-list-item {
    padding: 10px 12px; }
  .module-list-info {
    max-width: 100%; }
  .module-box-header .nav-searchbox.quick-menu .dropdown-menu {
    right: auto !important;
    left: 0 !important; }
  .module-box-header .search-bar.right-side-icon {
    min-width: 200px; }
    .module-box-header .search-bar.right-side-icon .form-control {
      border-radius: 0.25rem; }
  .module-box-header .nav-searchbox.quick-menu .dropdown-menu:after {
    right: auto;
    left: 9px; }
  .module-box-header .nav-searchbox.quick-menu .dropdown-menu:before {
    right: auto;
    left: 9px; }
  .module-box-header .search-bar {
    padding-right: 2px; } }

@media screen and (max-width: 399px) {
  .module-box-header .search-bar.right-side-icon {
    min-width: 100px; } }

@media screen and (min-width: 399px) {
  .d-xs-flex {
    display: flex !important; } }

.custom-pagination__wrapper {
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 17%, white 83%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, white 17%, white 83%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 17%, white 83%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#00ffffff',GradientType=1 );
  height: 50px;
  text-align: center;
  width: 700px; }
  .custom-pagination__wrapper:before {
    top: -1px; }
  .custom-pagination__wrapper:after {
    bottom: -1px; }

@keyframes hoverAnimation {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.custom-pagination {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0; }
  .custom-pagination .clear:after, .custom-pagination .clear:before {
    content: "";
    display: table; }
  .custom-pagination .clear:after {
    clear: both; }
  .custom-pagination li {
    display: block;
    float: left;
    padding: 5px; }
    .custom-pagination li:first-child {
      border: none; }
  .custom-pagination button,
  .custom-pagination span {
    background: none;
    border: none;
    border-radius: 50%;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.6);
    display: block;
    font-size: 16px;
    height: 40px;
    line-height: 40px;
    min-width: 40px;
    padding: 0; }
  .custom-pagination button {
    outline: none;
    position: relative;
    transition: all 170ms linear; }
    .custom-pagination button:before {
      background: rgba(0, 0, 0, 0.2);
      border-radius: 50%;
      content: "";
      cursor: pointer;
      height: 0;
      left: 50%;
      opacity: 0;
      position: absolute;
      transform: translate(-50%, -50%);
      transition: all 170ms linear;
      top: 50%;
      width: 0; }
    .custom-pagination button:hover:not(.active) {
      color: black; }
      .custom-pagination button:hover:not(.active):before {
        animation: hoverAnimation 510ms linear forwards;
        height: 40px;
        width: 40px; }
    .custom-pagination button.active {
      background: rgba(0, 0, 0, 0.1);
      color: black; }
  .custom-pagination .prev,
  .custom-pagination .next {
    font-size: 14px; }

.tab__component section {
  display: none;
  background-color: white;
  padding: 20px;
  border: 1px solid #abc; }

.tab__component input {
  display: none; }

.tab__component label {
  display: inline-block;
  margin: 0 0 -1px;
  padding: 15px 25px;
  font-weight: 600;
  text-align: center;
  color: #abc;
  border: 1px solid transparent; }
  .tab__component label i {
    margin-right: 0.8rem; }
    @media screen and (max-width: 800px) {
      .tab__component label i {
        margin-right: 0; } }

.tab__component label:hover {
  color: #00b96c;
  cursor: pointer; }

.tab__component input:checked + label {
  background-color: white;
  color: #00b96c;
  border: 1px solid #abc;
  border-top: 2px solid #00b96c;
  border-bottom: 1px solid #fff; }

.tab__component .tab__input:checked ~ .tab--active {
  display: block; }

@media screen and (max-width: 800px) {
  .tab__component label .text {
    font-size: 0; } }

@media screen and (max-width: 500px) {
  .tab__component label {
    padding: 15px; } }

.class__item {
  box-shadow: 0 3px 10px 1px rgba(0, 0, 0, 0.2);
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  cursor: pointer; }
  .class__item.English {
    border-top: 2px solid #2196F3; }
  .class__item.Mathematics {
    border-top: 2px solid #F44336; }
  .class__item.Science {
    border-top: 2px solid #00b96c; }
  .class__item .class__content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto; }
  .class__item .subject-tag {
    padding: 0.5rem 1rem 0;
    color: #ffffff; }
  .class__item .English {
    color: #2196F3; }
  .class__item .Mathematics {
    color: #F44336; }
  .class__item .Science {
    color: #00b96c; }
  .class__item .class__title {
    padding: 0 1rem;
    margin-bottom: 1rem; }

.carousel-container {
  width: 100%;
  overflow: hidden;
  position: relative; }

.carousel-slides {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch; }

.carousel-slides::-webkit-scrollbar {
  display: none; }

.carousel-slides > div {
  scroll-snap-align: start; }

.carousel-nav {
  position: absolute;
  top: calc(50% - 30px); }
  .carousel-nav:hover {
    cursor: pointer; }

.carousel-nav.next {
  right: -40px; }
  @media (min-width: 1250px) {
    .carousel-nav.next {
      right: -60px; } }
  @media (min-width: 1290px) {
    .carousel-nav.next {
      right: -80px; } }

.carousel-nav.prev {
  left: -40px; }
  @media (min-width: 1250px) {
    .carousel-nav.prev {
      left: -60px; } }
  @media (min-width: 1290px) {
    .carousel-nav.prev {
      left: -80px; } }

.carousel-nav-icon {
  width: 40px; }
  @media (min-width: 1290px) {
    .carousel-nav-icon {
      width: 50px; } }

.carousel-nav.next--tablet {
  right: -25px; }

.carousel-nav.prev--tablet {
  left: -25px; }

.rw-words {
  display: inline;
  white-space: nowrap; }

.rw-words span {
  position: absolute;
  right: 4px;
  top: -3px;
  opacity: 0;
  word-break: keep-all;
  overflow-y: hidden;
  -webkit-animation: rotateWord 12s linear infinite 0s;
  -ms-animation: rotateWord 12s linear infinite 0s;
  animation: rotateWord 12s linear infinite 0s; }

.rw-words span:nth-child(2) {
  -webkit-animation-delay: 3s;
  -ms-animation-delay: 3s;
  animation-delay: 3s; }

.rw-words span:nth-child(3) {
  -webkit-animation-delay: 6s;
  -ms-animation-delay: 6s;
  animation-delay: 6s; }

.rw-words span:nth-child(4) {
  -webkit-animation-delay: 9s;
  -ms-animation-delay: 9s;
  animation-delay: 9s; }

@-webkit-keyframes rotateWord {
  0% {
    opacity: 0; }
  2% {
    opacity: 0;
    -webkit-transform: translateY(-30px); }
  5% {
    opacity: 1;
    -webkit-transform: translateY(0px); }
  25% {
    opacity: 1;
    -webkit-transform: translateY(0px); }
  30% {
    opacity: 0;
    -webkit-transform: translateY(30px); }
  80% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@-ms-keyframes rotateWord {
  0% {
    opacity: 0; }
  2% {
    opacity: 0;
    -ms-transform: translateY(-30px); }
  5% {
    opacity: 1;
    -ms-transform: translateY(0px); }
  25% {
    opacity: 1;
    -ms-transform: translateY(0px); }
  30% {
    opacity: 0;
    -ms-transform: translateY(30px); }
  80% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes rotateWord {
  0% {
    opacity: 0; }
  2% {
    opacity: 0;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px); }
  5% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px); }
  25% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px); }
  30% {
    opacity: 0;
    -webkit-transform: translateY(30px);
    transform: translateY(30px); }
  80% {
    opacity: 0; }
  100% {
    opacity: 0; } }

/*!
 * Generated with CSS Flag Sprite generator (https://www.flag-sprites.com/)
 */
.flag {
  display: inline-block;
  width: 16px;
  height: 11px;
  background: url("./flags/flags.png") no-repeat; }

.flag.flag-ws {
  background-position: -112px -165px; }

.flag.flag-kr {
  background-position: -144px -77px; }

.flag.flag-tv {
  background-position: -80px -154px; }

.flag.flag-pe {
  background-position: -208px -110px; }

.flag.flag-kp {
  background-position: -128px -77px; }

.flag.flag-bj {
  background-position: -128px -11px; }

.flag.flag-mc {
  background-position: -160px -88px; }

.flag.flag-cd {
  background-position: -80px -22px; }

.flag.flag-jm {
  background-position: -240px -66px; }

.flag.flag-rw {
  background-position: -240px -121px; }

.flag.flag-tg {
  background-position: -160px -143px; }

.flag.flag-mz {
  background-position: -224px -99px; }

.flag.flag-ic {
  background-position: -48px -66px; }

.flag.flag-sn {
  background-position: -208px -132px; }

.flag.flag-an {
  background-position: -112px 0; }

.flag.flag-sh {
  background-position: -112px -132px; }

.flag.flag-sg {
  background-position: -96px -132px; }

.flag.flag-to {
  background-position: -32px -154px; }

.flag.flag-fr {
  background-position: -144px -44px; }

.flag.flag-tz {
  background-position: -112px -154px; }

.flag.flag-nr {
  background-position: -128px -110px; }

.flag.flag-nz {
  background-position: -160px -110px; }

.flag.flag-hm {
  background-position: -224px -55px; }

.flag.flag-cg {
  background-position: -112px -22px; }

.flag.flag-st {
  background-position: -32px -143px; }

.flag.flag-cl {
  background-position: -176px -22px; }

.flag.flag-iq {
  background-position: -160px -66px; }

.flag.flag-tj {
  background-position: -208px -143px; }

.flag.flag-ru {
  background-position: -224px -121px; }

.flag.flag-md {
  background-position: -176px -88px; }

.flag.flag-cw {
  background-position: -32px -33px; }

.flag.flag-gt {
  background-position: -144px -55px; }

.flag.flag-cm {
  background-position: -192px -22px; }

.flag.flag-pn {
  background-position: -64px -121px; }

.flag.flag-vi {
  background-position: -32px -165px; }

.flag.flag-ir {
  background-position: -176px -66px; }

.flag.flag-se {
  background-position: -80px -132px; }

.flag.flag-lt {
  background-position: -80px -88px; }

.flag.flag-gw {
  background-position: -176px -55px; }

.flag.flag-dm {
  background-position: -128px -33px; }

.flag.flag-bd {
  background-position: -32px -11px; }

.flag.flag-kurdistan {
  background-position: -160px -77px; }

.flag.flag-ae {
  background-position: -16px 0; }

.flag.flag-zm {
  background-position: -208px -165px; }

.flag.flag-bn {
  background-position: -160px -11px; }

.flag.flag-lb {
  background-position: -240px -77px; }

.flag.flag-zw {
  background-position: -224px -165px; }

.flag.flag-lu {
  background-position: -96px -88px; }

.flag.flag-ee {
  background-position: -192px -33px; }

.flag.flag-ad {
  background-position: 0 0; }

.flag.flag-gf {
  background-position: -224px -44px; }

.flag.flag-eg {
  background-position: -208px -33px; }

.flag.flag-ly {
  background-position: -128px -88px; }

.flag.flag-ug {
  background-position: -144px -154px; }

.flag.flag-cf {
  background-position: -96px -22px; }

.flag.flag-ag {
  background-position: -48px 0; }

.flag.flag-nl {
  background-position: -80px -110px; }

.flag.flag-gq {
  background-position: -96px -55px; }

.flag.flag-sk {
  background-position: -160px -132px; }

.flag.flag-io {
  background-position: -144px -66px; }

.flag.flag-ar {
  background-position: -144px 0; }

.flag.flag-vc {
  background-position: -240px -154px; }

.flag.flag-ne {
  background-position: -16px -110px; }

.flag.flag-pa {
  background-position: -192px -110px; }

.flag.flag-ca {
  background-position: -48px -22px; }

.flag.flag-dk {
  background-position: -112px -33px; }

.flag.flag-somaliland {
  background-position: -240px -132px; }

.flag.flag-co {
  background-position: -224px -22px; }

.flag.flag-gb {
  background-position: -176px -44px; }

.flag.flag-af {
  background-position: -32px 0; }

.flag.flag-ni {
  background-position: -64px -110px; }

.flag.flag-za {
  background-position: -176px -165px; }

.flag.flag-jp {
  background-position: -16px -77px; }

.flag.flag-mv {
  background-position: -160px -99px; }

.flag.flag-gd {
  background-position: -192px -44px; }

.flag.flag-bt {
  background-position: -224px -11px; }

.flag.flag-de {
  background-position: -80px -33px; }

.flag.flag-scotland {
  background-position: -48px -132px; }

.flag.flag-kh {
  background-position: -64px -77px; }

.flag.flag-xk {
  background-position: -128px -165px; }

.flag.flag-gh {
  background-position: 0 -55px; }

.flag.flag-lv {
  background-position: -112px -88px; }

.flag.flag-qa {
  background-position: -160px -121px; }

.flag.flag-mp {
  background-position: -64px -99px; }

.flag.flag-ss {
  background-position: -16px -143px; }

.flag.flag-ga {
  background-position: -160px -44px; }

.flag.flag-er {
  background-position: 0 -44px; }

.flag.flag-mo {
  background-position: -48px -99px; }

.flag.flag-ve {
  background-position: 0 -165px; }

.flag.flag-ua {
  background-position: -128px -154px; }

.flag.flag-ls {
  background-position: -64px -88px; }

.flag.flag-pg {
  background-position: -240px -110px; }

.flag.flag-wales {
  background-position: -80px -165px; }

.flag.flag-re {
  background-position: -176px -121px; }

.flag.flag-gu {
  background-position: -160px -55px; }

.flag.flag-gs {
  background-position: -128px -55px; }

.flag.flag-ma {
  background-position: -144px -88px; }

.flag.flag-bs {
  background-position: -208px -11px; }

.flag.flag-es {
  background-position: -16px -44px; }

.flag.flag-pw {
  background-position: -128px -121px; }

.flag.flag-bw {
  background-position: 0 -22px; }

.flag.flag-zanzibar {
  background-position: -192px -165px; }

.flag.flag-li {
  background-position: -16px -88px; }

.flag.flag-mm {
  background-position: -16px -99px; }

.flag.flag-ke {
  background-position: -32px -77px; }

.flag.flag-sa {
  background-position: 0 -132px; }

.flag.flag-fk {
  background-position: -96px -44px; }

.flag.flag-bb {
  background-position: -16px -11px; }

.flag.flag-sl {
  background-position: -176px -132px; }

.flag.flag-wf {
  background-position: -96px -165px; }

.flag.flag-ki {
  background-position: -80px -77px; }

.flag.flag-ht {
  background-position: -16px -66px; }

.flag.flag-cn {
  background-position: -208px -22px; }

.flag.flag-uz {
  background-position: -208px -154px; }

.flag.flag-me {
  background-position: -192px -88px; }

.flag.flag-lk {
  background-position: -32px -88px; }

.flag.flag-lc {
  background-position: 0 -88px; }

.flag.flag-gy {
  background-position: -192px -55px; }

.flag.flag-lr {
  background-position: -48px -88px; }

.flag.flag-fj {
  background-position: -80px -44px; }

.flag.flag-sb {
  background-position: -16px -132px; }

.flag.flag-pf {
  background-position: -224px -110px; }

.flag.flag-yt {
  background-position: -160px -165px; }

.flag.flag-tr {
  background-position: -48px -154px; }

.flag.flag-vg {
  background-position: -16px -165px; }

.flag.flag-au {
  background-position: -192px 0; }

.flag.flag-pr {
  background-position: -80px -121px; }

.flag.flag-py {
  background-position: -144px -121px; }

.flag.flag-gl {
  background-position: -32px -55px; }

.flag.flag-tm {
  background-position: 0 -154px; }

.flag.flag-sv {
  background-position: -48px -143px; }

.flag.flag-fo {
  background-position: -128px -44px; }

.flag.flag-et {
  background-position: -32px -44px; }

.flag.flag-mw {
  background-position: -176px -99px; }

.flag.flag-ie {
  background-position: -80px -66px; }

.flag.flag-do {
  background-position: -144px -33px; }

.flag.flag-mq {
  background-position: -80px -99px; }

.flag.flag-as {
  background-position: -160px 0; }

.flag.flag-ck {
  background-position: -160px -22px; }

.flag.flag-gn {
  background-position: -64px -55px; }

.flag.flag-ng {
  background-position: -48px -110px; }

.flag.flag-ge {
  background-position: -208px -44px; }

.flag.flag-vu {
  background-position: -64px -165px; }

.flag.flag-kn {
  background-position: -112px -77px; }

.flag.flag-ps {
  background-position: -96px -121px; }

.flag.flag-fi {
  background-position: -64px -44px; }

.flag.flag-ro {
  background-position: -192px -121px; }

.flag.flag-mx {
  background-position: -192px -99px; }

.flag.flag-vn {
  background-position: -48px -165px; }

.flag.flag-sz {
  background-position: -96px -143px; }

.flag.flag-ky {
  background-position: -192px -77px; }

.flag.flag-bg {
  background-position: -80px -11px; }

.flag.flag-nf {
  background-position: -32px -110px; }

.flag.flag-pm {
  background-position: -48px -121px; }

.flag.flag-th {
  background-position: -176px -143px; }

.flag.flag-tf {
  background-position: -144px -143px; }

.flag.flag-il {
  background-position: -96px -66px; }

.flag.flag-br {
  background-position: -192px -11px; }

.flag.flag-tk {
  background-position: -224px -143px; }

.flag.flag-eu {
  background-position: -48px -44px; }

.flag.flag-at {
  background-position: -176px 0; }

.flag.flag-uy {
  background-position: -192px -154px; }

.flag.flag-is {
  background-position: -192px -66px; }

.flag.flag-ai {
  background-position: -64px 0; }

.flag.flag-gp {
  background-position: -80px -55px; }

.flag.flag-ao {
  background-position: -128px 0; }

.flag.flag-nu {
  background-position: -144px -110px; }

.flag.flag-so {
  background-position: -224px -132px; }

.flag.flag-nc {
  background-position: 0 -110px; }

.flag.flag-pk {
  background-position: -16px -121px; }

.flag.flag-it {
  background-position: -208px -66px; }

.flag.flag-la {
  background-position: -224px -77px; }

.flag.flag-aw {
  background-position: -208px 0; }

.flag.flag-am {
  background-position: -96px 0; }

.flag.flag-bo {
  background-position: -176px -11px; }

.flag.flag-bi {
  background-position: -112px -11px; }

.flag.flag-sx {
  background-position: -64px -143px; }

.flag.flag-eh {
  background-position: -224px -33px; }

.flag.flag-ye {
  background-position: -144px -165px; }

.flag.flag-kw {
  background-position: -176px -77px; }

.flag.flag-by {
  background-position: -16px -22px; }

.flag.flag-va {
  background-position: -224px -154px; }

.flag.flag-cy {
  background-position: -48px -33px; }

.flag.flag-my {
  background-position: -208px -99px; }

.flag.flag-jo {
  background-position: 0 -77px; }

.flag.flag-hk {
  background-position: -208px -55px; }

.flag.flag-ba {
  background-position: 0 -11px; }

.flag.flag-be {
  background-position: -48px -11px; }

.flag.flag-hn {
  background-position: -240px -55px; }

.flag.flag-mn {
  background-position: -32px -99px; }

.flag.flag-gg {
  background-position: -240px -44px; }

.flag.flag-td {
  background-position: -128px -143px; }

.flag.flag-um {
  background-position: -160px -154px; }

.flag.flag-dz {
  background-position: -160px -33px; }

.flag.flag-bm {
  background-position: -144px -11px; }

.flag.flag-hu {
  background-position: -32px -66px; }

.flag.flag-fm {
  background-position: -112px -44px; }

.flag.flag-sj {
  background-position: -144px -132px; }

.flag.flag-pt {
  background-position: -112px -121px; }

.flag.flag-hr {
  background-position: 0 -66px; }

.flag.flag-ch {
  background-position: -128px -22px; }

.flag.flag-ci {
  background-position: -144px -22px; }

.flag.flag-kz {
  background-position: -208px -77px; }

.flag.flag-az {
  background-position: -240px 0; }

.flag.flag-kg {
  background-position: -48px -77px; }

.flag.flag-ml {
  background-position: 0 -99px; }

.flag.flag-bz {
  background-position: -32px -22px; }

.flag.flag-im {
  background-position: -112px -66px; }

.flag.flag-ph {
  background-position: 0 -121px; }

.flag.flag-bv {
  background-position: -240px -11px; }

.flag.flag-gi {
  background-position: -16px -55px; }

.flag.flag-mr {
  background-position: -96px -99px; }

.flag.flag-rs {
  background-position: -208px -121px; }

.flag.flag-in {
  background-position: -128px -66px; }

.flag.flag-sd {
  background-position: -64px -132px; }

.flag.flag-cu {
  background-position: 0 -33px; }

.flag.flag-ax {
  background-position: -224px 0; }

.flag.flag-tt {
  background-position: -64px -154px; }

.flag.flag-mh {
  background-position: -224px -88px; }

.flag.flag-gm {
  background-position: -48px -55px; }

.flag.flag-catalonia {
  background-position: -64px -22px; }

.flag.flag-ms {
  background-position: -112px -99px; }

.flag.flag-mu {
  background-position: -144px -99px; }

.flag.flag-tw {
  background-position: -96px -154px; }

.flag.flag-tl {
  background-position: -240px -143px; }

.flag.flag-sy {
  background-position: -80px -143px; }

.flag.flag-na {
  background-position: -240px -99px; }

.flag.flag-km {
  background-position: -96px -77px; }

.flag.flag-mt {
  background-position: -128px -99px; }

.flag.flag-cv {
  background-position: -16px -33px; }

.flag.flag-sm {
  background-position: -192px -132px; }

.flag.flag-om {
  background-position: -176px -110px; }

.flag.flag-id {
  background-position: -64px -66px; }

.flag.flag-tn {
  background-position: -16px -154px; }

.flag.flag-mk {
  background-position: -240px -88px; }

.flag.flag-pl {
  background-position: -32px -121px; }

.flag.flag-bf {
  background-position: -64px -11px; }

.flag.flag-al {
  background-position: -80px 0; }

.flag.flag-england {
  background-position: -240px -33px; }

.flag.flag-cz {
  background-position: -64px -33px; }

.flag.flag-np {
  background-position: -112px -110px; }

.flag.flag-cr {
  background-position: -240px -22px; }

.flag.flag-si {
  background-position: -128px -132px; }

.flag.flag-no {
  background-position: -96px -110px; }

.flag.flag-je {
  background-position: -224px -66px; }

.flag.flag-mg {
  background-position: -208px -88px; }

.flag.flag-us {
  background-position: -176px -154px; }

.flag.flag-tibet {
  background-position: -192px -143px; }

.flag.flag-ec {
  background-position: -176px -33px; }

.flag.flag-dj {
  background-position: -96px -33px; }

.flag.flag-tc {
  background-position: -112px -143px; }

.flag.flag-sr {
  background-position: 0 -143px; }

.flag.flag-gr {
  background-position: -112px -55px; }

.flag.flag-sc {
  background-position: -32px -132px; }

.flag.flag-bh {
  background-position: -96px -11px; }

.font__body {
  font-family: "Libre Franklin", sans-serif, sans-serif; }

.landing__content {
  padding-bottom: 5rem;
  overflow: hidden; }

.feature__display {
  position: relative;
  min-height: 50vh;
  max-height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0; }
  @media screen and (max-width: 767px) {
    .feature__display {
      flex-direction: column; } }

.slogan {
  margin: auto;
  padding: 1rem; }
  .slogan .slogan__header {
    font-family: "Rubik", sans-serif;
    font-size: 2.5rem;
    margin-bottom: 1.5rem; }
    @media screen and (max-width: 425px) {
      .slogan .slogan__header {
        font-size: 1.5rem; } }
  .slogan .slogan__subheader {
    font-family: "Libre Franklin", sans-serif;
    font-size: 1.5rem; }
    @media screen and (max-width: 425px) {
      .slogan .slogan__subheader {
        font-size: 1rem; } }
  .slogan .slogan__cta {
    margin-top: 1rem; }

.feature__image {
  object-fit: cover;
  max-height: 100%; }
  @media screen and (max-width: 767px) {
    .feature__image {
      max-width: 100%; } }

.split-pane {
  width: 50%;
  display: flex;
  flex: 1;
  align-items: center; }
  .split-pane:not(:last-child) {
    position: relative;
    z-index: 1;
    box-shadow: 8px 0 6px -6px #444444; }
  @media screen and (max-width: 767px) {
    .split-pane {
      width: 100%;
      display: block; }
      .split-pane:not(:last-child) {
        position: relative;
        z-index: 1;
        box-shadow: 0 2px 10px 0px #444444; } }

.bg__dotted {
  background: linear-gradient(90deg, #ffffff 20px, transparent 1%) center, linear-gradient(#ffffff 20px, transparent 1%) center, #dddddd;
  background-size: 22px 22px; }

.review__container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 1rem;
  border: 1px solid #dee2e6;
  background: #ffffff; }

.review {
  margin-bottom: 1rem; }

.review__text {
  font-size: 1.2rem;
  margin-bottom: 0.8rem; }
  @media screen and (max-width: 425px) {
    .review__text {
      font-size: 1rem; } }

.review__profile {
  font-size: 0.9rem; }

.teaching__container {
  padding: 1rem;
  border: solid 1px #dee2e6;
  background: #ffffff; }

.teaching__image-container {
  position: relative;
  margin-bottom: 1rem; }

.teaching__image-tag {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #00b96c;
  color: #efefef;
  font-size: 0.8rem;
  padding: 0.5rem;
  border-radius: 0 3px 0 0; }

.tellUs__container {
  padding: 3rem 1rem; }

.footer {
  background: #54657b;
  color: #b1b5b999;
  padding: 3rem 2rem; }
  .footer .menu__header {
    font-family: "Rubik", sans-serif;
    font-size: 1.125rem;
    margin-bottom: 0.5rem; }
  .footer .text__header, .footer h1, .footer h2, .footer h3, .footer h4, .footer h5, .footer h6, .footer a {
    color: #efefef; }
  .footer a:hover {
    text-decoration: none; }
  @media screen and (max-width: 767px) {
    .footer {
      padding: 2rem 1rem; } }

.catalogue__item {
  position: relative;
  background: #ffffff;
  box-shadow: 0 0 16px -3px #888888;
  /*padding-bottom: 80px;*/ }
  .catalogue__item .catalogue__tutor {
    position: relative;
    min-height: 35px; }
    .catalogue__item .catalogue__tutor .tutor__name {
      position: absolute;
      bottom: 0;
      left: 0;
      text-align: center;
      display: flex;
      align-items: center;
      width: 100%;
      font-size: 0.8rem;
      font-weight: 600;
      font-family: "Rubik", sans-serif;
      padding: 0.5rem 1rem;
      background: rgba(255, 255, 255, 0.7); }
  .catalogue__item .catalogue__text {
    padding: 0.8rem 1rem 0;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto; }
  .catalogue__item .catalogue__footer {
    /*position: absolute;*/
    padding: 0.5rem 0;
    /*width: 100%;
    height: 80px;
    bottom: 0;
    left: 0;*/ }
  .catalogue__item .class__title {
    font-size: 1.1rem;
    font-family: "Rubik", sans-serif; }
  .catalogue__item .class__summary {
    color: #727272; }
  .catalogue__item .class__price {
    font-size: 1.4rem; }
  @media screen and (max-width: 425px) {
    .catalogue__item {
      width: 95%; }
      .catalogue__item .class__title {
        font-size: 1.2rem;
        overflow-wrap: break-word; } }

.bg__full-inherit {
  position: relative;
  z-index: 1; }
  .bg__full-inherit:before {
    content: '';
    display: block;
    position: absolute;
    width: 300%;
    height: 100%;
    background-color: inherit;
    top: 0;
    left: 0;
    z-index: -1; }
  .bg__full-inherit:first-child:before {
    left: auto;
    right: 0; }

.bg__divider {
  background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url("https://s3-ap-southeast-1.amazonaws.com/tenoften-app-asset/web-assets/student/b_roll_image.jpg") no-repeat center center fixed;
  background-size: cover;
  max-height: 250px;
  height: 30vh; }
  @media (max-width: 425px) {
    .bg__divider {
      background-attachment: scroll; } }

.bg__divider--2 {
  background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url("https://s3-ap-southeast-1.amazonaws.com/tenoften-app-asset/web-assets/student/main_background.jpg") no-repeat center center fixed;
  background-size: cover;
  max-height: 250px;
  height: 30vh; }
  @media (max-width: 425px) {
    .bg__divider--2 {
      background-attachment: scroll; } }

.bg__divider--3 {
  background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url("https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/misc/bg-divider.jpg") no-repeat center center fixed;
  background-size: cover;
  max-height: 250px;
  height: 30vh; }
  @media (max-width: 425px) {
    .bg__divider--3 {
      background-attachment: scroll; } }

.bg__divider--4 {
  background: linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url("https://tenoften-app-asset.s3.ap-southeast-1.amazonaws.com/web-assets/Educator-Using-AI-min.webp") no-repeat center center fixed;
  background-size: cover;
  max-height: 250px;
  height: 30vh; }
  @media (max-width: 425px) {
    .bg__divider--4 {
      background-attachment: scroll; } }

.events__container {
  border: 1px solid #dee2e6;
  padding: 1rem;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto; }
  .events__container .events__header {
    font-size: 1.2rem;
    font-family: "Rubik", sans-serif;
    margin-bottom: 1rem; }
  .events__container .scroll-button {
    width: 100%;
    text-align: center;
    padding: 0.5rem 1rem;
    box-shadow: 3px 5px 15px -3px #888888;
    color: #00b96c;
    transition: box-shadow 0.5s ease, color 0.5s, ease;
    cursor: pointer; }
    .events__container .scroll-button:disabled {
      box-shadow: none;
      color: #DDDDDD; }

.event__item {
  padding: 0.5rem 0;
  font-size: 0.8rem;
  border-bottom: 1px solid #dee2e6; }
  .event__item .event__title {
    font-size: 1rem;
    margin-bottom: 0.5rem; }
  .event__item .event__link {
    display: flex;
    align-items: center; }

.calendar__icon {
  border-radius: 5px;
  text-align: center;
  font-weight: 600; }
  .calendar__icon .calendar__head {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #e61c24;
    color: #ffffff;
    border-bottom: 3px solid #ba372a;
    padding: 0.5rem; }
  .calendar__icon .calendar__body {
    background-color: #e5e8e9;
    color: #464646;
    padding: 0.5rem;
    font-size: 1.2rem;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px; }
    .calendar__icon .calendar__body .subtext {
      font-size: 0.75rem; }

.bg__seigaiha {
  background-color: #00b96c;
  background-image: radial-gradient(circle at 100% 150%, silver 24%, white 25%, white 28%, silver 29%, silver 36%, white 36%, white 40%, transparent 40%, transparent), radial-gradient(circle at 0 150%, silver 24%, white 25%, white 28%, silver 29%, silver 36%, white 36%, white 40%, transparent 40%, transparent), radial-gradient(circle at 50% 100%, white 10%, silver 11%, silver 23%, white 24%, white 30%, silver 31%, silver 43%, white 44%, white 50%, silver 51%, silver 63%, white 64%, white 71%, transparent 71%, transparent), radial-gradient(circle at 100% 50%, white 5%, silver 6%, silver 15%, white 16%, white 20%, silver 21%, silver 30%, white 31%, white 35%, silver 36%, silver 45%, white 46%, white 49%, transparent 50%, transparent), radial-gradient(circle at 0 50%, white 5%, silver 6%, silver 15%, white 16%, white 20%, silver 21%, silver 30%, white 31%, white 35%, silver 36%, silver 45%, white 46%, white 49%, transparent 50%, transparent);
  background-size: 100px 50px; }

.bg__workbook {
  background-color: #fff;
  background-image: linear-gradient(90deg, transparent 79px, #abced4 79px, #abced4 81px, transparent 81px), linear-gradient(#eee 0.1em, transparent 0.1em);
  background-size: 100% 1.2em; }

.hover--dark {
  transition: background-color 0.5s ease, color 0.5s ease; }
  .hover--dark:hover {
    background-color: #818384;
    color: #ffffff; }

.hover--green {
  transition: background-color 0.5s ease, color 0.5s ease; }
  .hover--green:hover {
    background-color: #39d591;
    color: #ffffff; }

.hover--danger {
  transition: color 0.1s ease; }
  .hover--danger:hover {
    color: #F55859 !important; }

.bg__blueprint {
  background-color: #269;
  background-image: linear-gradient(white 2px, transparent 2px), linear-gradient(90deg, white 2px, transparent 2px), linear-gradient(rgba(255, 255, 255, 0.3) 1px, transparent 1px), linear-gradient(90deg, rgba(255, 255, 255, 0.3) 1px, transparent 1px);
  background-size: 100px 100px, 100px 100px, 20px 20px, 20px 20px;
  background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px; }

.bg__striped {
  background-color: #e2f5ea;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.07) 50%, transparent 50%), linear-gradient(90deg, rgba(255, 255, 255, 0.13) 50%, transparent 50%), linear-gradient(90deg, transparent 50%, rgba(255, 255, 255, 0.17) 50%), linear-gradient(90deg, transparent 50%, rgba(255, 255, 255, 0.19) 50%);
  background-size: 13px, 29px, 37px, 53px; }
